import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useBalances } from '~/hooks/furusato/swr/balances';
import { useProduct } from '~/hooks/furusato/swr/product';
export function useBalance() {
    const [balance, setBalance] = useState(0);
    const { balances } = useBalances();
    const { productId } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const quantityId = Number(query.get('quantity_id'));
    const { product } = useProduct(Number(productId));
    const quantity = product?.quantities.find((item)=>{
        return item.id === quantityId;
    });
    useEffect(()=>{
        const pocketPointCode = quantity?.pocketPointCode;
        const pointBalance = balances?.find((item)=>{
            return item.code === pocketPointCode;
        });
        if (!pointBalance) {
            return;
        }
        setBalance(pointBalance.balance);
    }, [
        balances,
        quantity
    ]);
    return {
        balance
    };
}

import { PRODUCT_LIST_PARAM, ROUTER_PATH } from 'ec-constants/routes';
export const DEFAULT_OPTION = {
    keyword: '',
    categoryId: '',
    type: undefined,
    order: undefined,
    prefectures: [],
    lowerPrice: null,
    higherPrice: null,
    coupons: []
};
/**
 * 商品一覧ページのURLパラメータを生成する
 */ function getSearchParams({ keyword, categoryId, order, type, prefectures = [], lowerPrice, higherPrice, page, coupons = [] }) {
    const searchParams = new URLSearchParams();
    const { SEARCH_KEY } = PRODUCT_LIST_PARAM;
    if (keyword) {
        searchParams.append(SEARCH_KEY.WORDS, keyword);
    }
    if (categoryId) {
        searchParams.append(SEARCH_KEY.CATEGORY, categoryId);
    }
    if (order) {
        searchParams.append(SEARCH_KEY.SORT_ORDER, order);
    }
    if (type) {
        searchParams.append(SEARCH_KEY.TYPE, type);
    }
    if (prefectures.length > 0) {
        prefectures.forEach((prefecture)=>{
            searchParams.append(SEARCH_KEY.PREFECTURES, prefecture);
        });
    }
    if (lowerPrice) {
        searchParams.append(SEARCH_KEY.LOWER_PRICE, lowerPrice);
    }
    if (higherPrice) {
        searchParams.append(SEARCH_KEY.HIGHER_PRICE, higherPrice);
    }
    if (page && page > 1) {
        searchParams.append(SEARCH_KEY.PAGE_NO, page.toString());
    }
    coupons.forEach((coupon)=>{
        searchParams.append(SEARCH_KEY.COUPONS, coupon);
    });
    return searchParams.toString();
}
/**
 * 商品一覧のURLのパスを生成する
 * @param {Option} option 検索条件のオブジェクト
 * @returns {string} 商品一覧ページのURL e.g. /products?category_id=1&prefectures[]=tokyo&prefectures[]=osaka
 */ export const buildProductsPagePath = (option)=>{
    const searchParams = getSearchParams(option);
    const params = searchParams.length > 0 ? `?${searchParams}` : '';
    return `${ROUTER_PATH.PRODUCT_LIST}${params}`;
};

import { Deserializer } from 'jsonapi-serializer';
/**
 * JSONAPIのレスポンスをデシリアライズする
 * @param {any} data JSONAPIドキュメントデータ
 * @param {'snake_case' | 'camelCase' } keyAttribute 変換時キーのフォーマット
 * @returns {Promise<T>} デシリアライズされたJSONデータ
 */ // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeJsonApi = (// eslint-disable-next-line @typescript-eslint/no-explicit-any
data, keyAttribute = 'camelCase')=>{
    const targetedKeyAttribute = keyAttribute === 'snake_case' ? 'snake_case' : 'camelCase';
    return new Deserializer({
        keyForAttribute: targetedKeyAttribute
    }).deserialize(data);
};

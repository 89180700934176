import TagManager from 'react-gtm-module';
import { DEFAULT_DATA_LAYER_NAME } from '../constants';
/**
 * GTMを初期化
 */ const initGtm = (gtmId)=>{
    const tagManagerArgs = {
        gtmId,
        dataLayerName: DEFAULT_DATA_LAYER_NAME
    };
    TagManager.initialize(tagManagerArgs);
};
export default initGtm;

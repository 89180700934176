const furusato = {
    // 納税者一覧
    contributions: {
        title: '納税履歴',
        NoContributions: '納税履歴はありません',
        ContributionItem: {
            contributionDate: '納税日',
            contributionPrice: '納税額',
            contributionApplicationNumber: '申込番号'
        }
    },
    // 納税者詳細
    contribution: {
        ContributionDetail: {
            contributionDate: '納税日（寄付日）',
            contributionPrice: '納税額（寄付額）',
            contributionApplicationNumber: '申込番号',
            paymentMethod: '決済方法',
            creditCard: 'クレジットカード xxxx-xxxx-{{last4}}',
            rewardMessage: '返礼として{{localGovernmentName}}ポイント{{amount}}ポイント付与済',
            message: 'ポイントで購入した商品の詳細や配送状況については注文履歴のページご確認ください。',
            orders: '注文履歴'
        },
        Contributor: {
            title: '申込時納税者(寄附者)情報',
            name: '納税者名',
            nameKana: '納税者名カナ',
            address: '住所',
            phoneNumber: '電話番号',
            email: 'メールアドレス',
            oneStop: 'ワンストップ特例申請書の送付',
            yes: '希望する',
            no: '希望しない',
            birthday: '生年月日',
            gender: '性別',
            paperDestination: '書類のお届け先',
            sameDestination: '納税者と同じ',
            male: '男性',
            female: '女性'
        },
        Purpose: {
            title: '選択した納税額の使い道'
        },
        Inquiry: {
            title: '本件の納税に関するお問い合わせ',
            description: '※商品の発送状況や、到着した商品の品質など、商品に関するお問い合わせは<0>注文履歴ページ</0>から<1>生産者にお問い合わせ</1>をお願いします。'
        },
        ApplicationFormAddress: {
            title: 'ワンストップ特例申請書の送付先',
            download: 'ワンストップ特例申請書のダウンロードはこちら'
        }
    },
    // ポイント残高
    balances: {
        title: 'ポイント残高',
        NoBalances: '利用可能なポイント残高はありません。\n※ふるさと納税で返品が発生した場合のみポイント残高が生じます',
        BalanceItem: {
            pointUnit: 'ポイント',
            donationUnit: '寄付額{{value}}円相当',
            expiredAtUnit: '有効期限:{{value}}まで',
            button: 'この自治体の商品をみる'
        }
    }
};
export default furusato;

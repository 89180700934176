import * as api from '~/services/api';
function resToDeliverAddress(data) {
    const { id, main, postalCode, state, city, address1, address2, firstName, firstNamePronunciation, lastName, lastNamePronunciation, phoneNumber, isUsedFromAnySubscriptions } = data;
    return {
        id: Number(id),
        main: main === null ? false : main,
        postalCode,
        state,
        city,
        address1,
        address2: address2 === null ? '' : address2,
        firstName,
        firstNamePronunciation,
        lastName,
        lastNamePronunciation,
        phoneNumber,
        isUsedFromAnySubscriptions
    };
}
export async function fetchUserAddressByAddressId(addressId) {
    const json = await api.get(`v1/delivery_destinations/${addressId}`);
    const res = await api.deserializeJsonApi(json);
    return resToDeliverAddress(res);
}
export async function createNewAddress(newAddress) {
    const { lastName, firstName, lastNamePronunciation, firstNamePronunciation, postalCode, state, city, address1, address2, phoneNumber, main } = newAddress;
    const json = await api.post(`v1/delivery_destinations`, {
        /* eslint-disable @typescript-eslint/naming-convention */ delivery_destination: {
            last_name: lastName,
            first_name: firstName,
            last_name_pronunciation: lastNamePronunciation,
            first_name_pronunciation: firstNamePronunciation,
            postal_code: postalCode,
            state,
            city,
            address1,
            address2,
            phone_number: phoneNumber,
            main
        }
    });
    const createdAddress = await api.deserializeJsonApi(json);
    return resToDeliverAddress(createdAddress);
}
export async function deleteAddress(addressId) {
    return await api.del(`v1/delivery_destinations/${addressId}`);
}
export async function patchAddress(addressId, editedAddress) {
    const { lastName, firstName, lastNamePronunciation, firstNamePronunciation, postalCode, state, city, address1, address2, phoneNumber, main } = editedAddress;
    return await api.patch(`v1/delivery_destinations/${addressId}`, {
        /* eslint-disable @typescript-eslint/naming-convention */ delivery_destination: {
            last_name: lastName,
            first_name: firstName,
            last_name_pronunciation: lastNamePronunciation,
            first_name_pronunciation: firstNamePronunciation,
            postal_code: postalCode,
            state,
            city,
            address1,
            address2,
            phone_number: phoneNumber,
            main
        }
    });
}

const SET_PRODUCT_DATA = 'purchaseForm/SET_PRODUCT_DATA';
const setProductData = (data)=>{
    const { scheduledDeliveryKind, preparationDays, daysToShippingDeadline, quantity } = data;
    return {
        type: SET_PRODUCT_DATA,
        payload: {
            scheduledDeliveryKind,
            preparationDays,
            daysToShippingDeadline,
            quantityPriceJpy: quantity.priceWithoutTax,
            temperatureControl: quantity.temperatureControl || null
        }
    };
};
const RESET_PURCHASE_FORM = 'purchaseForm/RESET_PURCHASE_FORM';
const resetPurchaseForm = ()=>({
        type: RESET_PURCHASE_FORM
    });
export default {
    SET_PRODUCT_DATA,
    RESET_PURCHASE_FORM,
    setProductData,
    resetPurchaseForm
};

import dayjs from 'dayjs';
import { shouldShowingTag } from '~/domains/productTag';
export function getProductFlags(targetedProductType) {
    /**
   * 単品商品かどうか
   */ const isOneTimeProduct = ()=>{
        return targetedProductType === 'OneTimeProduct';
    };
    /**
   * 定期商品かどうか
   */ const isMonthlyOrWeeklySubscriptionProduct = ()=>{
        const subscriptionType = [
            'MonthlySubscriptionProduct',
            'WeeklySubscriptionProduct'
        ];
        return subscriptionType.includes(targetedProductType);
    };
    /**
   * 週次定期商品かどうか
   */ const isWeeklyProduct = ()=>{
        return targetedProductType === 'WeeklySubscriptionProduct';
    };
    /**
   * 月次定期商品かどうか
   */ const isMonthlyProduct = ()=>{
        return targetedProductType === 'MonthlySubscriptionProduct';
    };
    /**
   * 特定月配送定期商品かどうか
   */ const isSpecificMonthDeliveryProduct = (settlementPeriodicUnit)=>{
        if (!isMonthlyProduct()) {
            return false;
        }
        //月単位の定期配送のうち、配送月単位がnullになっているものは特定月配送として判断する
        return settlementPeriodicUnit === null;
    };
    /**
   * 予約商品かどうか
   */ const isReservedSubscriptionProduct = ()=>{
        return targetedProductType === 'ReservedSubscriptionProduct';
    };
    return {
        isOneTimeProduct,
        isMonthlyOrWeeklySubscriptionProduct,
        isWeeklyProduct,
        isMonthlyProduct,
        isSpecificMonthDeliveryProduct,
        isReservedSubscriptionProduct
    };
}
export function productStateJudge(productState) {
    // https://github.com/pocket-marche/pocket_marche/blob/develop/doc/state_diagrams/product_state.puml
    const isOnSale = productState === 'on_sale' // 販売中
    ;
    const isSuspended = productState === 'suspended' // 販売停止中
    ;
    const isEndOfSale = productState === 'discontinued' || productState === 'archived' // 販売終了(出品停止と削除を指す)
    ;
    return {
        isOnSale,
        isSuspended,
        isEndOfSale
    };
}
export function productOutOfStockJudge(productType, isOutOfStock) {
    const { isOneTimeProduct, isMonthlyOrWeeklySubscriptionProduct, isReservedSubscriptionProduct } = getProductFlags(productType);
    const isOneTimeProductOutOfStock = isOutOfStock && isOneTimeProduct() //一時在庫切れ
    ;
    const isSubscriptionOutOfStock = isOutOfStock && (isMonthlyOrWeeklySubscriptionProduct() || isReservedSubscriptionProduct() //在庫切れ
    );
    return {
        isOneTimeProductOutOfStock,
        isSubscriptionOutOfStock
    };
}
export function scheduledDeliveryKindJudge(scheduledDeliveryKind) {
    const isPossible = scheduledDeliveryKind === 'possible';
    const isPartiallyPossible = scheduledDeliveryKind === 'partially_possible';
    const isPriorConsulation = scheduledDeliveryKind === 'prior_consultation';
    const isImpossible = scheduledDeliveryKind === 'impossible';
    return {
        isPossible,
        isPartiallyPossible,
        isPriorConsulation,
        isImpossible
    };
}
export const productPriceRegExp = /^(\d{1,7})$/;
/**
 * タグデータをフラット化し有効なもののテキスト部分のみを返す
 * @param tags タグデータ
 * @returns {string[]} eg. ['#セット', '#タグ']
 */ export const flattenProductTags = (tags)=>{
    return Object.values(tags).flatMap((item)=>item.filter((tag)=>shouldShowingTag(tag)).map(({ name })=>name));
};
/**
 * ancestorsが付いているカテゴリーのツリー構造を親->子の順で平坦化する
 * @example
 * const category = {
 *   id: '3',
 *   name: '孫カテゴリー',
 *   ancestors: [{
 *     id: '1',
 *     name: '親カテゴリー',
 *   },{
 *     id: '2',
 *     name: '子カテゴリー',
 *   }]
 * }
 *
 * flattenProductCategoryTree(category)
 *  => [
 *    { id: '1', name: '親カテゴリー' },
 *    { id: '2', name: '子カテゴリー' },
 *    { id: '3', name: '孫カテゴリー' },
 * ]
 */ export function flattenProductCategoryTree(current) {
    if (!current) {
        return [];
    }
    const categoryTree = [];
    // 先祖のデータを追加
    if (current.ancestors && Array.isArray(current.ancestors)) {
        categoryTree.push(...current.ancestors);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { ancestors, ...rest } = current;
    // 最後に自身(末端)のデータを追加
    categoryTree.push(rest);
    return categoryTree;
}
/**
 * 最終注文受付日まであと何日かを計算する。
 * ただし、最終注文受付日が過去の場合、または最終注文受付日まで8日以上ある場合はnullを返す。
 */ export function calculateWillEndInDays(lastOrderScheduledDateString) {
    if (lastOrderScheduledDateString) {
        const lastOrder = dayjs(lastOrderScheduledDateString).startOf('day');
        const today = dayjs(new Date()).startOf('day');
        const diff = lastOrder.diff(today, 'days');
        return diff >= 0 && diff <= 7 ? diff : null;
    }
    return null;
}

import { SuffixTree } from 'ec-services/searchSuggest';
import { useCallback, useEffect, useMemo } from 'react';
import { createCategorySuggestions } from '~/domains/suggestion';
import { useSearchBar } from '~/hooks/components/searchBar';
import { useSearchHistoryWords } from '~/hooks/components/searchHistoryWords';
import { useFetchCategories } from '~/hooks/swr/categories';
const suffixTree = new SuffixTree();
const SUGGEST_LIMIT = 7;
/**
 * オートコンプリートのロジック
 */ export const useAutocomplete = ({ active, disabledSuggestions = false })=>{
    // サジェストが無効の場合はカテゴリデータを取得しない
    const { categories } = useFetchCategories({
        active: !disabledSuggestions
    });
    const { text } = useSearchBar();
    // キーワード履歴のデータ
    const { searchHistoryWords, saveSearchHistoryWord, deleteSearchHistoryWord } = useSearchHistoryWords();
    // サジェストのデータを構築
    useEffect(()=>{
        if (!categories) {
            return;
        }
        suffixTree.buildTree(createCategorySuggestions(categories.collection));
    }, [
        categories
    ]);
    // 入力履歴とサジェストデータをマージして一つのリストにする
    const autocompleteData = useMemo(()=>{
        if (!active) {
            return [];
        }
        // テキストが入力されている場合は、履歴を表示しない
        const historyWords = text.length > 0 ? [] : searchHistoryWords.map(({ word })=>({
                type: 'history',
                id: `history-${word}`,
                word
            }));
        let suggestList = [];
        if (!disabledSuggestions) {
            suggestList = suffixTree.autoComplete(text, SUGGEST_LIMIT).matches.map(({ id, word, categoryParents })=>({
                    type: 'suggest',
                    id: `suggest-${id}`,
                    word,
                    categoryParams: {
                        id,
                        categoryParents
                    }
                }));
        }
        return [
            ...historyWords,
            ...suggestList
        ];
    }, [
        active,
        disabledSuggestions,
        searchHistoryWords,
        text
    ]);
    /**
   * オートコンプリートのキーワードの削除ボタンをクリックした時の処理
   * @param idx 選択した行のインデックス
   */ const deleteSearchHistory = useCallback((idx)=>{
        const target = autocompleteData[idx];
        if (target && target.type == 'history') {
            deleteSearchHistoryWord(target.word ?? '');
        }
    }, [
        autocompleteData,
        deleteSearchHistoryWord
    ]);
    return {
        autocompleteData,
        saveSearchHistoryWord,
        deleteSearchHistory
    };
};

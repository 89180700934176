/* eslint-disable @typescript-eslint/naming-convention */ import TagManager from 'react-gtm-module';
import { DEFAULT_DATA_LAYER_NAME } from '../constants';
import { convertProductIntoItem } from '../utils';
/**
 * GTM(GA4)へbegin_checkoutイベントを送信する
 * @param product EC/ふるさと納税の商品データ
 * @param quantity  EC/ふるさと納税の選択している分量
 * @param fromFurusato ふるさとから購入するか
 */ const sendBeginCheckout = (product, quantity, fromFurusato)=>{
    const item = convertProductIntoItem(product, quantity);
    const dataLayer = {
        event: 'begin_checkout',
        currency: 'JPY',
        value: quantity ? quantity.price : 0,
        has_furusato_purchase: fromFurusato,
        items: [
            item
        ],
        _clear: true
    };
    TagManager.dataLayer({
        dataLayerName: DEFAULT_DATA_LAYER_NAME,
        dataLayer
    });
};
export default sendBeginCheckout;

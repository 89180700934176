// 3Dセキュア2.0の関連処理を定義
import { v4 } from 'uuid';
const TDS_STATE = 'tdsState';
/**
 * 3Dセキュア認証のCSRFトークンを生成、保存
 * @returns stateの文字列
 */ export const storeTdsState = ()=>{
    const state = v4();
    sessionStorage.setItem(TDS_STATE, state);
    return state;
};
/**
 * 3Dセキュア認証のCSRFトークンを取得
 * @returns stateの文字列
 */ export const getTdsState = ()=>{
    return sessionStorage.getItem(TDS_STATE) || '';
};
/**
 * 3Dセキュア認証のCSRFトークンを削除
 */ export const removeTdsState = ()=>{
    sessionStorage.removeItem(TDS_STATE);
};

import partialEqual from './helper/partialEqual';
import NestedSetNode from './node';
/**
 * NestedSetModelを構築するクラス
 * https://github.com/xmhscratch/nested-set を基にカスタムしてます
 *
 * @example
 * ```
 * // 初期化 (ltf, rgtは必須)
 * const tree = new NestedSet([
 *   { "id": "1", "name": "野菜", "lft": 1, "rgt": 394 },
 *   { "id": "11", "name": "あしたば", "lft": 2, "rgt": 3 },
 * ])
 * // ルートのノードを取得
 * console.log(tree.getRoots())
 * // ノードの総数を取得
 * console.log(tree.size())
 * // 特定のノードを検索
 * const node = tree.findSingleNode({ id: '99' })
 * ```
 */ class NestedSet {
    options = {
        LEFT_KEY: 'lft',
        RIGHT_KEY: 'rgt'
    };
    collection = [];
    indexes = new Map();
    constructor(data){
        this.load(data);
    }
    load(data) {
        // leftKeyでソート
        data.sort((a, b)=>a[this.options.LEFT_KEY] - b[this.options.LEFT_KEY]);
        // 親のノードを設定するためのキュー
        const parentQueue = [];
        // index, collectionを生成
        data.forEach((value, index)=>{
            const nodeLeft = value[this.options.LEFT_KEY];
            const nodeRight = value[this.options.RIGHT_KEY];
            let parent = null;
            while(parentQueue.length > 0){
                const [parentLeft, parentRight, parentId] = parentQueue[parentQueue.length - 1];
                if (parentLeft < nodeLeft && parentRight > nodeRight) {
                    parent = parentId;
                    break;
                }
                parentQueue.pop();
            }
            this.indexes.set(nodeRight, index);
            const node = new NestedSetNode(value, parent, this);
            parentQueue.push([
                nodeLeft,
                nodeRight,
                value.id
            ]);
            this.collection.push(node);
        });
    }
    /**
   * ノードの総数を取得
   */ getSize() {
        return this.collection.length;
    }
    /**
   * ルートのノードを取得
   * @returns ルートのノードの配列
   */ getRoots() {
        return this.collection.filter((node)=>node.isRoot());
    }
    /**
   * idやnameなどでノードを検索し、一致した最初のノードのattributesと子孫の値を配列で返す
   * @param partial 検索条件 eg. {id: '1'}, {name: '野菜'} など
   * @returns 一致したNodeもしくはnull
   */ findSingleNode(partial) {
        const node = this.collection.find((node)=>partialEqual(node.attributes, partial));
        return node ? node : null;
    }
}
export default NestedSet;

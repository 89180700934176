import useSWR from 'swr';
import { getContributionPath, swrFetcher } from '~/repositories/furusato/contribution';
import { getContributionsPath, swrLatestFetcher } from '~/repositories/furusato/contributions';
export function useContribution(id) {
    const { data, error } = useSWR(getContributionPath(id), swrFetcher);
    return {
        contribution: data,
        error
    };
}
export function useLatestContribution() {
    const { data, error } = useSWR(getContributionsPath({
        page: 1,
        perPage: 1
    }), swrLatestFetcher);
    return {
        contribution: data?.contribution,
        error,
        isLoading: !data
    };
}

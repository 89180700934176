import { RESET_TOAST, SET_TOAST_MESSAGE, SET_TOAST_STATUS } from './actions';
const initialState = {
    status: 'initial',
    message: '',
    duration: 3000
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_TOAST_STATUS:
            return {
                ...state,
                status: action.payload.status
            };
        case SET_TOAST_MESSAGE:
            if (action.payload.duration === undefined) {
                return {
                    ...state,
                    message: action.payload.message
                };
            }
            return {
                ...state,
                message: action.payload.message,
                duration: action.payload.duration
            };
        case RESET_TOAST:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

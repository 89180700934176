import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { productStateJudge } from '~/domains/product';
const metaId = 'robots-meta-tag';
function createNoindexElement() {
    const metaRobotsElement = document.getElementById(metaId);
    if (!metaRobotsElement) {
        const metaRobots = document.createElement('meta');
        metaRobots.id = metaId;
        metaRobots.name = 'robots';
        metaRobots.content = 'noindex';
        document.head.appendChild(metaRobots);
    }
}
function removeNoindexElement() {
    const metaRobotsElement = document.getElementById(metaId);
    if (metaRobotsElement) {
        metaRobotsElement.remove();
    }
}
export function useNoindex() {
    useEffect(()=>{
        createNoindexElement();
        // 別ページ遷移時はrobotsメタタグを削除する
        return ()=>{
            removeNoindexElement();
        };
    }, []);
}
/**
 * @deprecated  もう使われていないのであとで消します
 */ export function useNoindexProduct(product) {
    useEffect(()=>{
        if (product) {
            const { isEndOfSale, isSuspended } = productStateJudge(product.productState);
            if (isEndOfSale || isSuspended) {
                createNoindexElement();
            }
        }
        return ()=>{
            removeNoindexElement();
        };
    }, [
        product
    ]);
}
export function useNoindexWithEnvironment() {
    const location = useLocation();
    useEffect(()=>{
        if (process.env.ENVIRONMENT !== 'production') {
            createNoindexElement();
        }
        return ()=>{
            removeNoindexElement();
        };
    }, [
        location.pathname
    ]);
}

import { useLocation } from 'react-router';
export function useFurusatoHeaderView() {
    // Top to Top遷移でヘッダー付きで開く場合に利用する
    // https://poke-m.slack.com/archives/CTB3KEXKQ/p1629866209374700
    const query = new URLSearchParams(useLocation().search);
    const headerMenu = query.get('header_menu');
    return {
        showHeader: headerMenu === 'on'
    };
}

const message = {
    messageBox: {
        noContents: 'メッセージはありません',
        ListContents: {
            own: 'あなた：',
            deleted: 'メッセージは削除されています'
        }
    },
    messageThread: {
        messageForm: {
            textareaPlaceholder: 'メッセージを入力',
            inquiry: 'について問い合わせ',
            OrderSelect: {
                subTitle: '注文を選択(任意)',
                noOrders: '該当する注文がありません',
                ordered: '注文',
                placeholder: '注文を選択してください'
            },
            RefundOrderSelect: {
                mainLabel: '届いた商品に不備があった場合の返金申請、および返金の申請の取消しが行えます',
                placeholder: '返金手続きをする注文を選択',
                ordered: '注文',
                caption: '返金申請後、生産者が承認を行うと返金されます\n再送、一部返金をご希望の場合は<0>こちら</0>',
                refundRequestCancelCaution: 'この注文は<0>返金申請中</0>です。\n返金申請を取消すと、<0>再度返金申請はできません。</0>',
                noOrders: '返金手続きの対象の商品がありません'
            }
        },
        threadContents: {
            noData: 'メッセージがありません',
            noMoreData: 'メッセージを全て取得しました',
            confirm: '選択したメッセージを削除しますか?',
            delete: '削除する',
            cancel: 'キャンセル'
        }
    },
    messageInquiries: {
        contactToProducer: '<0>{{producerName}}</0>さんとの連絡',
        Form: {
            subTitle: '新規メッセージの送信',
            subject: 'メッセージ内容(任意)',
            content: '商品について',
            placeholder: 'メッセージを入力してください。（1,000文字以内）',
            productSubject: '以下の商品について連絡',
            inquiry: 'について問い合わせ',
            submit: '送信'
        }
    },
    messageThreadContents: {
        deleteLabel: '削除',
        alreadyDeleted: 'このメッセージは削除されています',
        SubTitle: {
            completed: '商品発送完了のお知らせ',
            delayed: '発送遅延のお知らせ',
            cancelled: '発送のキャンセルについて',
            chargeRefunded: '返金済み',
            chargeRefundedByFurusato: 'ポイントの返還完了',
            refundRequest: '返金申請済み',
            refundRequestByFurusato: 'ポイントの返還申請済み',
            refundCancel: '返金申請取り消し済み',
            refundCancelByFurusato: 'ポイントの返還申請取り消し済み'
        },
        DeliveryInfo: {
            deliveryMethod: '配送手段：',
            yamato: 'ポケマル提携のヤマト便',
            detail: '注文の詳細はこちら',
            tracingNumber: '伝票番号：'
        },
        PointRefundDescription: '<0>ポイント残高ページ</0>にて、ポイントの返還を確認し、ページ内の「この自治体の商品を見る」よりポイントを利用した代金の注文が可能でございます。<1 /><2>お問い合わせはこちら</2>',
        CancelOrDelayedReasonItem: {
            delayedReason: '発送遅延理由',
            cancelReason: '発送キャンセル理由'
        },
        DelayedShippingDate: {
            title: '発送予定'
        },
        frequentOperationTags: {
            askShippingDate: {
                label: '発送日を知りたい',
                template: 'メッセージ失礼します。こちらの商品は、いつ頃発送いただけますでしょうか？'
            },
            askScheduledDelivery: {
                label: '日時指定をしたい',
                template: 'メッセージ失礼します。こちらの商品は、いつ頃発送いただけますでしょうか？'
            },
            askInventory: {
                label: '在庫を知りたい',
                template: 'メッセージ失礼します。こちらの商品の再販予定はありますでしょうか？'
            },
            askQuantity: {
                label: '分量を追加して欲しい',
                template: 'メッセージ失礼します。こちらの商品の分量を追加していただけないでしょうか？'
            },
            refund: {
                label: '返金について',
                template: 'メッセージ失礼します。こちらの商品の返金申請になりますので、ご確認よろしくお願いします。',
                cancelTemplate: 'こちらの商品の返金申請を取り消しました。'
            },
            aboutOrder: {
                label: '注文全般について',
                template: 'メッセージ失礼します。商品を注文しましたが、○○のため、ご確認いただけないでしょうか？'
            }
        },
        refundCancelButtonLabel: '申請を取消す',
        tagChangeConfirmation: {
            dialogDescription: '入力されたメッセージが破棄されますがよろしいでしょうか？',
            confirmLabel: 'OK',
            cancelLabel: 'キャンセル'
        }
    }
};
export default message;

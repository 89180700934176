import TagManager from 'react-gtm-module';
const sendKarteFavorite = (params)=>{
    const { product, listName, isFurusatoView } = params;
    const dataLayerData = {
        /* eslint-disable @typescript-eslint/naming-convention */ event: 'karte-favorite',
        product_id: product.id,
        title: product.title,
        producer_id: product.user.id,
        is_furusato: isFurusatoView,
        // カテゴリは現段階では送らない
        category: '',
        category2: '',
        current_view: listName
    };
    // ふるさと納税の場合はタグがないので空になります
    if (product.tags) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;
        dataLayerData.tags = product.tags;
    }
    const dataLayer = {
        dataLayerName: 'PageDataLayer',
        dataLayer: dataLayerData
    };
    TagManager.dataLayer(dataLayer);
};
export default sendKarteFavorite;

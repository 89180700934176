import { flattenProductCategoryTree, getProductFlags } from '~/domains/product';
import { calcShippingDate } from '~/domains/shipping';
import * as api from '~/services/api';
/**
 * 有効なcategoriesを持つかどうかのType Guard
 * include=categoriesが付かない場合、categories:[null]になるので判定する
 */ const hasCategories = (productResponse)=>{
    return 'categories' in productResponse && productResponse.categories?.[0] !== null;
};
function resToProduct(res) {
    const { isOneTimeProduct } = getProductFlags(res.type);
    const { getDaysBeforeShipping, getShippingDate } = calcShippingDate();
    const { user } = res;
    const _user = {
        id: Number(user.id),
        name: user.fullname,
        organizationName: user.organizationName,
        isBestProducer: user.shouldShowBadge,
        address: user.stateAndCity,
        avatarImageUrl: user.avatarImageUrl,
        communityId: user.communityId,
        state: user.state
    };
    const quantities = res.productQuantities.map((quantityData)=>({
            id: Number(quantityData.id),
            description: quantityData.description,
            price: quantityData.taxPriceForTotal,
            priceWithoutTax: quantityData.priceJpy,
            point: quantityData.priceJpyWithTax + quantityData.deliveryRateConsumerShare,
            buyable: quantityData.buyable,
            disbuyableReason: quantityData.disbuyableReason,
            // FIXME: 来年度以降複数自治体対応で変更する可能性あり
            pocketPointCode: quantityData.pocketPoints[0].code,
            //FIX ME: 税率を返却してもらう
            taxRate: 0,
            deliveryMethod: {
                provider: res.deliveryProvider,
                name: res.deliveryNameByProducer,
                deliveryRates: quantityData.deliveryRates.map((deliveryRate)=>{
                    return {
                        rate: deliveryRate.rate,
                        area: deliveryRate.areaJp
                    };
                }),
                deliveryCost: quantityData.deliveryCostByProducer,
                deliveryRateConsumerShare: quantityData.deliveryRateConsumerShare,
                temperatureControl: res.temperatureControl
            },
            localGovernment: {
                id: Number(quantityData.furusatoLocalGovernments[0]?.id),
                name: quantityData.furusatoLocalGovernments[0]?.name,
                code: quantityData.furusatoLocalGovernments[0]?.code
            },
            isSelectable: quantityData.furusatoLocalGovernments[0].isSelectable,
            wayToUse: quantityData.furusatoLocalGovernments[0].wayToUse,
            purposeLists: quantityData.furusatoLocalGovernments[0].purposeLists
        }));
    let images = res.productImages.map((image)=>image.imageUrl);
    if (res.productProcessedFoodDetail.enable && res.productProcessedFoodDetail.imageUrl) {
        images = [
            ...images,
            res.productProcessedFoodDetail.imageUrl
        ];
    }
    const tags = {
        basicTags: res.basicTags.map((basicTag)=>({
                ...basicTag,
                id: Number(basicTag.id)
            })),
        featureTags: res.featureTags.map((featureTag)=>({
                ...featureTag,
                id: Number(featureTag.id)
            })),
        seasonTags: res.seasonTags.map((seasonTag)=>({
                ...seasonTag,
                id: Number(seasonTag.id)
            }))
    };
    const product = {
        id: Number(res.id),
        images,
        title: res.title,
        description: res.description,
        productProcessedFoodDetail: {
            id: Number(res.productProcessedFoodDetail.id),
            imageUrl: res.productProcessedFoodDetail.imageUrl,
            enable: res.productProcessedFoodDetail.enable,
            foodLabeling: res.productProcessedFoodDetail.foodLabeling
        },
        addedDescriptions: res.postscripts.map(({ id, text, createdAt })=>({
                id,
                text,
                createdAt
            })),
        quantities,
        stock: res.stock,
        keptPurchasesCount: res.keptPurchasesCount,
        user: _user,
        type: res.type,
        scheduledDeliveryKind: isOneTimeProduct() ? res.productScheduledDelivery?.kind || 'impossible' : 'impossible',
        nextShippingMonth: isOneTimeProduct() ? null : res.nextShippingMonth,
        settlementNumber: res.settlementNumber,
        settlementPeriodicUnit: res.settlementPeriodicUnit,
        settlementMonths: res.settlementMonths,
        cancellationDeadlineDate: isOneTimeProduct() ? null : res.cancellationDeadlineDate,
        lastOrderScheduledDate: res.lastOrderScheduledDate,
        isStandardTaxRate: res.isStandardTaxRate,
        isWillEnd: res.isWillEnd,
        preparationDays: res.preparationDays,
        daysToShippingDeadline: res.daysToShippingDeadline,
        daysBeforeShipping: getDaysBeforeShipping(res.preparationDays, res.daysToShippingDeadline),
        preservationMethod: res.preservationDescription,
        maxDeliveryDays: res.maxDeliveryDays,
        shippingDate: res.cancellationDeadlineDate !== null ? getShippingDate(res.cancellationDeadlineDate, res.preparationDays) : null,
        subcategory: res.productSubcategory ? {
            id: Number(res.productSubcategory.id),
            name: res.productSubcategory.name
        } : undefined,
        tags,
        isOutOfStock: res.isOutOfStock,
        onSale: res.onSale,
        productState: res.state,
        autoCoupon: {
            type: res.autoCouponType,
            discountValue: res.autoCouponDiscountValue
        },
        private: res.private,
        hasFurusatoQuantity: true
    };
    // includeでcategoriesを指定した場合のみ、categoriesを追加する
    if (hasCategories(res)) {
        ;
        product.categories = res.categories.map((category)=>flattenProductCategoryTree(category));
    }
    return product;
}
export function getProductPath(id, includingKeys) {
    const includes = [];
    if (includingKeys) {
        if (includingKeys.includes('categories')) {
            includes.push('categories', 'categories.ancestors');
        }
    }
    const params = includes.length ? `?${new URLSearchParams({
        include: includes.join(',')
    }).toString()}` : '';
    return `v1/furusato/products/${id}${params}`;
}
export async function swrFetcher(path) {
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    return resToProduct(res);
}

// 検索履歴を保存、取得する
import store from 'store';
// localStrageの保存キー
const LS_SEARCH_HISTORY_WORDS_KEY = 'ls_shw';
// localStrageに保存する件数
const MAX_ROW = 10;
// デフォルトの最大取得件数
const DEFAULT_MAX_LIMIT = 10;
/**
 * 検索キーワードをLocalStorageに保存
 * @param word 検索ワード
 */ export const saveSearchHistory = (word)=>{
    const items = store.get(LS_SEARCH_HISTORY_WORDS_KEY) || [];
    // 同じキーワードが存在するデータを削除し、先頭に追加
    const idx = items.findIndex(({ word: _word })=>_word === word);
    if (idx > -1) {
        items.unshift(items.splice(idx, 1)[0]);
    } else {
        items.unshift({
            word,
            c: new Date().getTime()
        });
        // 最大件数をこえないようにする
        items.splice(MAX_ROW);
    }
    store.set(LS_SEARCH_HISTORY_WORDS_KEY, items);
};
/**
 * 保存されている検索履歴を取得
 * @param limit 取得する最大件数
 */ export const getSearchHistory = (limit = DEFAULT_MAX_LIMIT)=>{
    const items = store.get(LS_SEARCH_HISTORY_WORDS_KEY) || [];
    return items.slice(0, limit);
};
/**
 * 指定の検索履歴を削除
 * idなどは持たないので、とりあえずキーワードで判定する
 * @param word 取得するキーワード
 */ export const deleteSearchHistory = (word)=>{
    if (!word) {
        return;
    }
    const items = store.get(LS_SEARCH_HISTORY_WORDS_KEY) || [];
    const idx = items.findIndex(({ word: _word })=>_word === word);
    if (idx > -1) {
        items.splice(idx, 1);
    }
    store.set(LS_SEARCH_HISTORY_WORDS_KEY, items);
};

export const SET_DELIVERY_DESTINATION = 'delivery/DELIVERY_DESTINATION';
export const setDeliveryDestination = (deliveryDestination)=>({
        type: SET_DELIVERY_DESTINATION,
        payload: {
            deliveryDestination
        }
    });
export const RESET_DELIVERY = 'delivery/RESET_DELIVERY';
export const resetDelivery = ()=>({
        type: RESET_DELIVERY
    });
export default {
    setDeliveryDestination,
    resetDelivery
};

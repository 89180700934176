const top = {
    SeasonalKeywords: {
        title: '旬のキーワード'
    },
    LatestStories: {
        title: '読みもの'
    },
    Categories: {
        title: 'カテゴリ',
        alt: '{{category}}カテゴリ'
    }
};
export default top;

import { useDispatch, useSelector } from 'react-redux';
import { paymentMethodActions, paymentMethodSelectors } from '~/state/furusato/ducks/paymentMethod';
export function usePaymentMethod() {
    const dispatch = useDispatch();
    const paymentMethod = useSelector(paymentMethodSelectors.paymentMethod);
    const setPaymentMethod = (paymentMethod)=>{
        dispatch(paymentMethodActions.setPaymentMethod(paymentMethod));
    };
    return {
        paymentMethod,
        setPaymentMethod
    };
}

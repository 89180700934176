import { useEffect, useRef } from 'react';
export function useMeta() {
    const createDocumentTitle = (documentTitle)=>{
        document.title = documentTitle;
    };
    const createMetaInfo = (descriptionContent)=>{
        const metas = document.getElementsByTagName('meta');
        const descriptionMetaTag = metas.namedItem('description');
        if (descriptionMetaTag) {
            descriptionMetaTag.setAttribute('content', descriptionContent);
        } else {
            const metaTag = document.createElement('meta');
            metaTag.name = 'description';
            metaTag.content = descriptionContent;
            document.getElementsByTagName('head')[0].appendChild(metaTag);
        }
    };
    const createOgp = (properties)=>{
        const metas = document.getElementsByTagName('meta');
        for(let index = 0; index < properties.length; index++){
            for(let i = 0; i < metas.length; i++){
                const metaTag = metas[i].getAttribute('property');
                if (metaTag && metaTag.indexOf(properties[index].property) != -1) {
                    const content = properties[index].property !== 'og:image' || properties[index].property === 'og:image' && properties[index].content?.includes('https://poke-m.com/assets') ? properties[index].content : `${properties[index].content}/c_limit,w_1000/f_jpg`;
                    metas[i].setAttribute('content', content);
                    break;
                }
            }
        }
    };
    //別ページ遷移時は前回のmeta情報を残さないように処理
    useEffect(()=>{
        return ()=>{
            document.title = '';
            const metas = document.getElementsByTagName('meta');
            const descriptionMetaTag = metas.namedItem('description');
            if (descriptionMetaTag) {
                descriptionMetaTag.setAttribute('content', '');
            }
        };
    }, []);
    return {
        createDocumentTitle,
        createMetaInfo,
        createOgp
    };
}
export function useCanonical(pathname) {
    const linkRef = useRef(document.createElement('link'));
    useEffect(()=>{
        if (!linkRef) {
            return;
        }
        const canonicalLinkElement = linkRef.current;
        canonicalLinkElement.rel = 'canonical';
        canonicalLinkElement.href = `${location.protocol}//${location.host}${pathname}`;
        document.getElementsByTagName('head')[0].appendChild(canonicalLinkElement);
        return ()=>{
            canonicalLinkElement.remove();
        };
    }, [
        pathname
    ]);
}

import dayjs from 'dayjs';
import snakecaseKeys from 'snakecase-keys';
import * as api from '~/services/api';
const addressWithPostcode = ({ postcode, state, city, address1, address2 })=>`〒${postcode} ${state}${city}${address1} ${address2 ?? ''}`;
function resToContribution(res) {
    return {
        ...res,
        id: Number(res.id),
        taxPayer: {
            ...res.taxPayer,
            address: addressWithPostcode({
                ...res.taxPayer
            })
        },
        receiver: {
            ...res.receiver,
            address: addressWithPostcode({
                ...res.receiver
            })
        }
    };
}
export function getContributionPath(id) {
    return `v1/furusato/payment_taxes/${Number(id)}`;
}
export async function swrFetcher(path) {
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    return resToContribution(res);
}
function convertContribution(data, quantity, amount) {
    const pointCode = quantity.pocketPointCode;
    const isOneStop = data.isOneStop === 'yes';
    const paymentTax = {
        amount,
        oneStop: isOneStop,
        purpose: data.purpose,
        taxPayer: {
            firstName: data.firstName,
            firstNameKana: data.firstNamePronunciation,
            lastName: data.lastName,
            lastNameKana: data.lastNamePronunciation,
            postcode: data.postalCode,
            state: data.state,
            city: data.city,
            address1: data.address1,
            address2: data.address2,
            telephoneNumber: data.phoneNumber,
            email: data.mail,
            dob: isOneStop && data.birthDate ? dayjs(data.birthDate).format() : null,
            gender: isOneStop && data.gender ? data.gender : null
        }
    };
    const contribution = {
        pointCode,
        paymentTax
    };
    return contribution;
}
function resToPostContribution(res) {
    const { id, amount, oneStop, purpose, taxPayer } = res;
    return {
        id: Number(id),
        amount,
        oneStop,
        purpose,
        taxPayer: {
            lastName: taxPayer.lastName,
            firstName: taxPayer.firstName,
            lastNamePronunciation: taxPayer.lastNameKana,
            firstNamePronunciation: taxPayer.firstNameKana,
            address1: taxPayer.address1,
            address2: taxPayer.address2 || '',
            state: taxPayer.state,
            city: taxPayer.city,
            postalCode: taxPayer.postcode,
            phoneNumber: taxPayer.telephoneNumber,
            mail: taxPayer.email,
            birthDate: taxPayer.dob ?? null,
            gender: taxPayer.gender
        }
    };
}
export async function postContribution(data, quantity, amount) {
    const contribution = convertContribution(data, quantity, amount);
    const json = await api.post(`v1/furusato/payment_taxes`, snakecaseKeys(contribution));
    const res = await api.deserializeJsonApi(json);
    return resToPostContribution(res);
}

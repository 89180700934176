import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPurchaserAddresses } from '~/repositories/purchaserAddresses';
import { purchaserAddressActions, purchaserAddressSelectors } from '~/state/ducks/purchaserAddress';
export function usePurchaserAddresses() {
    const dispatch = useDispatch();
    const selectedPurchaserAddress = useSelector(purchaserAddressSelectors.selectedPurchaserAddress);
    const addressList = useSelector(purchaserAddressSelectors.purchaserAddresses);
    const [isInitializeLoading, setIsInitializeLoading] = useState(false);
    const [error, setError] = useState(null);
    const initialize = useCallback(async ()=>{
        setError(null);
        setIsInitializeLoading(true);
        await fetchPurchaserAddresses().then((purchaserAddresses)=>{
            if (!purchaserAddresses.length) {
                dispatch(purchaserAddressActions.setAddresses([]));
            }
            dispatch(purchaserAddressActions.setAddresses(purchaserAddresses));
            if (!selectedPurchaserAddress?.id) {
                dispatch(purchaserAddressActions.setSelectedPurchaserAddress(purchaserAddresses[0]));
            }
        }).catch((e)=>{
            setError(e);
        }).finally(()=>{
            setIsInitializeLoading(false);
        });
    }, [
        dispatch,
        selectedPurchaserAddress?.id
    ]);
    return {
        selectedPurchaserAddress,
        addressList,
        isInitializeLoading,
        error,
        initialize
    };
}

import * as api from '~/services/api';
export async function fetchAccessToken(email, password) {
    return api.post('v1/sign_in', {
        user: {
            email,
            password
        }
    }, {
        hasCustomActionOnNotAuthorized: true
    });
}
export async function deleteAccessToken() {
    return api.get('v1/sign_out');
}

import { SIGN_OUT } from '../../../ducks/common/actions';
import purchaseFormActions from './actions';
const { SET_PRODUCT_DATA, RESET_PURCHASE_FORM, // eslint-disable-next-line @typescript-eslint/no-unused-vars
setProductData, // eslint-disable-next-line @typescript-eslint/no-unused-vars
resetPurchaseForm } = purchaseFormActions;
const initialState = {
    scheduledDeliveryKind: null,
    preparationDays: null,
    daysToShippingDeadline: null,
    quantityPriceJpy: null,
    temperatureControl: null
};
export default function reducer(state = initialState, action) {
    switch(action.type){
        case SET_PRODUCT_DATA:
            {
                const { scheduledDeliveryKind, daysToShippingDeadline, preparationDays, quantityPriceJpy, temperatureControl } = action.payload;
                return {
                    ...state,
                    scheduledDeliveryKind: scheduledDeliveryKind || null,
                    daysToShippingDeadline,
                    preparationDays,
                    quantityPriceJpy,
                    temperatureControl
                };
            }
        case RESET_PURCHASE_FORM:
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

import { SIGN_OUT } from '../common/actions';
import { RESET_CREDIT_CARD, SET_CREDIT_CARDS, SET_SELECTED_CREDIT_CARD } from './actions';
const initialState = {
    selectedCreditCard: null,
    creditCards: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_SELECTED_CREDIT_CARD:
            return {
                ...state,
                selectedCreditCard: action.payload.selectedCreditCard
            };
        case SET_CREDIT_CARDS:
            return {
                ...state,
                creditCards: action.payload.creditCards
            };
        case RESET_CREDIT_CARD:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

export const SET_SELECTED_PURCHASER_ADDRESS = 'purchaserAddress/SET_SELECTED_PURCHASER_ADDRESS';
export const setSelectedPurchaserAddress = (selectedPurchaserAddress)=>({
        type: SET_SELECTED_PURCHASER_ADDRESS,
        payload: {
            selectedPurchaserAddress
        }
    });
export const SET_PURCHASER_ADDRESSES = 'purchaserAddress/SET_PURCHASER_ADDRESSES';
export const setAddresses = (purchaserAddresses)=>({
        type: SET_PURCHASER_ADDRESSES,
        payload: {
            purchaserAddresses
        }
    });
export const RESET_PURCHASER_ADDRESSES = 'purchaserAddress/RESET_PURCHASER_ADDRESSES';
export const resetPurchaserAddresses = ()=>({
        type: RESET_PURCHASER_ADDRESSES
    });
export default {
    setSelectedPurchaserAddress,
    setAddresses,
    resetPurchaserAddresses
};

import { omit } from './helper';
/**
 * NestedSetのノードを表すクラス
 * 基本的にはNestedSetから呼び出されることを想定している
 */ class NestedSetNode {
    context;
    left;
    right;
    parentId;
    attributes;
    constructor(data, parentId, context){
        const leftKey = context.options.LEFT_KEY;
        const rightKey = context.options.RIGHT_KEY;
        this.context = context;
        this.parentId = parentId;
        this.left = data[leftKey] || -1;
        this.right = data[rightKey] || -1;
        this.attributes = omit(data, [
            leftKey,
            rightKey
        ]);
    }
    // 先祖のノードを取得し配列で返す
    parents() {
        const parents = [];
        for (const node of this.context.collection){
            if (this.left <= node.left) {
                continue;
            }
            if (this.right >= node.right) {
                continue;
            }
            parents.push(node);
        }
        return parents;
    }
    // 子のノードを取得し配列で返す
    children() {
        const children = [];
        let right = this.right - 1;
        while(right > this.left){
            const child = this.context.collection[this.context.indexes.get(right) || -1];
            if (!child) {
                // 歯抜けのデータがあるので、一つ飛ばして続ける
                right -= 2;
                continue;
            }
            children.push(child);
            right = child.left - 1;
        }
        return children.reverse();
    }
    // 子孫のノードを取得し配列で返す
    // 順番は現段階では保証されない
    descendants() {
        const descendants = [];
        this.context.indexes.forEach((_idx, right)=>{
            if (right < this.right && right > this.left) {
                const node = this.context.collection[this.context.indexes.get(right) || -1];
                if (node) {
                    descendants.push(node);
                }
            }
        });
        return descendants;
    }
    /**
   * 末端のノードであるかどうか
   * @deprecated 子カテゴリをアーカイブした場合、leftとrightが連続しないケースがあるため、正しい判定ができないため非推奨<br>isParentで代替する
   *
   */ isLeaf() {
        return this.right === this.left + 1;
    }
    // ルートのノードであるかどうか
    isRoot() {
        return this.depth() === 0;
    }
    // 親のノードであるかどうか(子のノードを持つかどうか)
    // 子カテゴリをアーカイブした場合、leftとrightが連続せず、子を持たないパターンが存在するため、isLeafでは判定できない
    // このノードを持たないケースは必ず末端のノードであるため、isLeafの内容は包含される
    isParent() {
        return this.children().length > 0;
    }
    // 子孫のノードであるかどうか（親のノードを持つかどうか）
    isDescendant() {
        return this.left > 0 && this.right < this.context.getSize() * 2;
    }
    // ノードの深さを取得
    depth() {
        return (this.parents() || []).length;
    }
    // 直上の親のノードを取得し返す
    get parent() {
        if (!this.parentId) {
            return null;
        }
        return this.context.findSingleNode({
            id: this.parentId
        }) || null;
    }
}
export default NestedSetNode;

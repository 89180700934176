const toast = {
    loginDone: 'ログインしました',
    signupDone: '会員登録が完了しました',
    signOutDone: 'ログアウトしました',
    addressRegisrationDone: '住所の登録が完了しました',
    addressDeleteDone: '住所の削除が完了しました',
    addressEditDone: '住所の編集が完了しました',
    leaveDone: '退会が完了しました',
    notificationSettingDone: 'メール通知設定が変更されました',
    changingPasswordDone: 'パスワードが変更されました',
    resetPasswordDone: 'パスワード再設定のためのメールを送信しました。',
    creditCardRegisrationDone: 'クレジットカードの登録が完了しました',
    creditCardDeleteDone: 'クレジットカードの削除が完了しました',
    patchUserDone: 'メールアドレスが変更されました。ご登録いただいたメールアドレスへお送りした本人確認用メールより本人確認を行なってください。',
    patchUserDoneWithoutEmail: 'プロフィールが変更されました',
    createMessageDone: 'メッセージが作成されました',
    deleteMessageDone: 'メッセージの削除が完了しました',
    cancelSubscriptionDone: 'キャンセル処理が完了しました',
    skipSubscriptionDone: 'スキップ処理が完了しました',
    subscriptionChangeDone: '{{fieldNames, list}}を更新しました',
    copyCouponDone: 'クーポンコードをコピーしました',
    forgotPasswordDone: 'パスワードが正しく変更されました',
    emailUpdateConfirmationDone: 'Eメールの変更が完了しました'
};
export default toast;

export const balanceStatus = (balance, pointOfProduct)=>{
    const isBalanceNone = balance === 0;
    const isBalancePart = !isBalanceNone && balance < pointOfProduct;
    const isBalanceFull = balance >= pointOfProduct;
    return {
        isBalanceNone,
        isBalancePart,
        isBalanceFull
    };
};
export function getPointDescription(balance, pointOfProduct) {
    const { isBalanceNone, isBalancePart, isBalanceFull } = balanceStatus(balance, pointOfProduct);
    if (isBalanceNone) {
        return 'none';
    }
    if (isBalancePart) {
        return 'part';
    }
    if (isBalanceFull) {
        return 'full';
    }
    return '';
}
export function getAmountOfTax(point) {
    return Math.round(point / 0.3);
}
export const getRequiredPoints = (balance, pointOfProduct)=>{
    return pointOfProduct > balance ? pointOfProduct - balance : 0;
};

export const FURUSATO_SET_ALLOCATED_PURCHASE_ITEM = 'purchase/FURUSATO_SET_ALLOCATED_PURCHASE_ITEM';
export const setAllocatedPurchaseItem = (allocatedPurchaseItem)=>({
        type: FURUSATO_SET_ALLOCATED_PURCHASE_ITEM,
        payload: {
            allocatedPurchaseItem
        }
    });
export const FURUSATO_RESET_PURCHASE = 'purchase/FURUSATO_RESET_PURCHASE';
export const resetPurchase = ()=>({
        type: FURUSATO_RESET_PURCHASE
    });
export const FURUSATO_SET_PURCHASE_COMPLETED = 'purchase/FURUSATO_SET_PURCHASE_COMPLETED';
export const setPurchaseCompleted = (isPurchaseCompleted)=>({
        type: FURUSATO_SET_PURCHASE_COMPLETED,
        payload: {
            isPurchaseCompleted
        }
    });
export const FURUSATO_DELETE_ALLOCATED_PURCHASE_ITEM = 'purchase/FURUSATO_DELETE_ALLOCATED_PURCHASE_ITEM';
export const deleteAllocatedPurchaseItem = ()=>({
        type: FURUSATO_DELETE_ALLOCATED_PURCHASE_ITEM
    });
export default {
    setAllocatedPurchaseItem,
    resetPurchase,
    setPurchaseCompleted,
    deleteAllocatedPurchaseItem
};

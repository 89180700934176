/**
 * 2つのオブジェクトが部分的に等しいかどうかを判定する
 * shallowでしか比較していないので注意
 *
 * @param base 元のオブジェクト
 * @param partial 比較対象のオブジェクト
 * @returns 等しい場合はtrue、そうでない場合はfalse
 * @example
 * ```
 * const base = { a: 1, b: 'str' }
 * const partial = { a: 1 }
 * partialEqual(base, partial) // true
 * ```
 */ const partialEqual = (base, partial)=>{
    for(const key in partial){
        if (partial[key] !== undefined && base[key] !== partial[key]) {
            return false;
        }
    }
    return true;
};
export default partialEqual;

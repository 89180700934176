import styled, { css } from 'styled-components';
import { color, size } from '~/styles/constants/furusato';
import { breakPoint } from '~/styles/utils';
export const ContentWrapper = styled.div`
  border: 1px solid ${color.border.GRAY};
  background-color: ${color.WHITE};
  padding: 1rem;
  margin-bottom: 30px;
  ${breakPoint.PC(css`
    border-radius: ${size.borderRadius.NORMAL};
  `)}
`;
export const DescriptionList = styled.dl``;
export const ItemWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  border-bottom: 1px solid ${color.border.GRAY};
`;
export const TotalWrapper = styled.div`
  padding: 30px 0 1rem;
  display: flex;
`;
export const DescriptionTerm = styled.dt`
  width: 100%;
  color: ${color.font.MAIN};
  font-weight: ${(props)=>props.$fontWeight || size.fontWeight.W6};
  font-size: ${size.font.S};
  ${(props)=>props.$indent && 'text-indent:  23px'};
`;
export const Description = styled.dd`
  color: ${color.font.MAIN};
  font-weight: ${size.fontWeight.W3};
  font-size: ${size.font.S};
  white-space: nowrap;
`;
export const TotalAmount = styled(DescriptionTerm)`
  color: ${color.SECONDARY};
  font-size: ${size.font.M};
`;
export const TotalAmountDescription = styled(Description)`
  color: ${color.SECONDARY};
  font-size: ${size.font.M};
`;

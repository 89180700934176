export const FURUSATO_SET_INPUT_SEARCH_TEXT = 'searchBar/FURUSATO_SET_INPUT_SEARCH_TEXT';
export const setInputSearchText = (text)=>({
        type: FURUSATO_SET_INPUT_SEARCH_TEXT,
        payload: {
            text
        }
    });
export default {
    setInputSearchText
};

import { useEffect } from 'react';
export function useOnClickOutside(ref, handler, disableTouch = false) {
    useEffect(()=>{
        const listener = (event)=>{
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler();
        };
        if (disableTouch) {
            document.addEventListener('touchstart', listener);
        }
        document.addEventListener('mousedown', listener);
        return ()=>{
            if (disableTouch) {
                document.removeEventListener('touchstart', listener);
            }
            document.removeEventListener('mousedown', listener);
        };
    }, [
        ref,
        handler,
        disableTouch
    ]);
}

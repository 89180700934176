import { useState } from 'react';
import { deleteSearchHistory, getSearchHistory, saveSearchHistory } from '~/domains/search/searchHistory';
/**
 * 検索履歴を管理するカスタムフック
 */ export const useSearchHistoryWords = ()=>{
    const [searchHistoryWords, setSearchHistoryWords] = useState(getSearchHistory());
    /**
   * 検索履歴を保存する
   */ const saveSearchHistoryWord = (word)=>{
        saveSearchHistory(word);
        setSearchHistoryWords(getSearchHistory());
    };
    /**
   * 検索履歴を削除する
   */ const deleteSearchHistoryWord = (word)=>{
        deleteSearchHistory(word);
        setSearchHistoryWords(getSearchHistory());
    };
    return {
        searchHistoryWords,
        saveSearchHistoryWord,
        deleteSearchHistoryWord
    };
};

const PRIMARY_COLOR = '#0b57d0';
/**
 * @deprecated ec-react(src/styles/contents/color.ts)の方を使用してください
 * ただし FURUSATO_* は定義されていないの移動する必要がある
 */ export const color = {
    WHITE: '#fff',
    PRIMARY: PRIMARY_COLOR,
    /** @deprecated PRIMARYを使用してください */ PRIMARY_SUB: PRIMARY_COLOR,
    SECONDARY: '#f5a623',
    WARNING: '#d0021b',
    DARK_GRAY: '#ababab',
    FURUSATO_BLUE: '#1886b9',
    font: {
        MAIN: '#4a4a4a',
        /**
     * @deprecated この色はテキストカラーとして使用しないでください。
     * テキストの場合は原則GRAYを利用してください。
     */ SUB: '#ababab',
        /** @deprecated PRIMARYを使用してください */ LINK_BLUE: PRIMARY_COLOR,
        GRAY: '#717171'
    },
    // backgroundでのみ使用されている色を定義
    background: {
        GRAY: '#e3e3e3',
        LIGHT_GRAY: '#f6f6f6',
        ICON_GRAY: '#666666',
        WARNING: '#f6e2e2',
        FURUSATO_HEADER: '#cbedf7',
        FURUSATO_HEADER_MENU: '#e6f4f7',
        /** @deprecated PRIMARYを使用してください */ DARK_BLUE: PRIMARY_COLOR
    },
    // borderでのみ使用されている色を定義
    border: {
        GRAY: '#d6d6d6'
    }
};
export const featureColor = {
    // 定期
    SUBSCRIPTION: '#ce4a18',
    // 予約
    RESERVED: '#1B8386',
    // もうすぐ終了
    WILL_END: '#D14901',
    // ふるさと納税ラベル
    FURUSATO: '#085C9E',
    // 割引クーポンなどの割引ラベル
    COUPON: '#E32924'
};

const purchaserAddress = {
    new: {
        title: '新しい住所の登録',
        submit: '登録して、この住所を使用する',
        save: '登録する'
    },
    edit: {
        title: '住所編集',
        submit: '保存して、この住所を使用する',
        save: '保存する'
    },
    addressList: {
        title: '住所の選択',
        main: 'メイン住所',
        deliverToAddress: 'この住所を選択する',
        edit: '編集',
        delete: '削除',
        address: '配送先',
        addressInfo: '配送先情報'
    },
    deleteDialog: {
        confirm: '登録した住所を削除しますか?',
        delete: '削除する',
        cancel: 'キャンセル'
    },
    form: {
        LastName: {
            title: '姓',
            placeholder: '雨風'
        },
        LastNamePronunciation: {
            title: 'セイ',
            placeholder: 'アメカゼ'
        },
        FirstName: {
            title: '名',
            placeholder: '太郎'
        },
        FirstNamePronunciation: {
            title: 'メイ',
            placeholder: 'タロウ'
        },
        PostalCode: {
            title: '郵便番号',
            placeholder: '0250092'
        },
        Prefecture: {
            title: '都道府県',
            placeholder: '選択してください'
        },
        City: {
            title: '市区町村',
            placeholder: '花巻市●通'
        },
        Address1: {
            title: '番地',
            placeholder: '1-1-1'
        },
        Address2: {
            title: '建物名・部屋番号',
            placeholder: 'ポケマルビル3F'
        },
        PhoneNumber: {
            title: '電話番号',
            placeholder: '08003030303'
        },
        Main: {
            title: 'この住所をメインとして登録する'
        }
    }
};
export default purchaserAddress;

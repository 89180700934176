import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNewAddress, deleteAddress, fetchUserAddressByAddressId, patchAddress } from '~/repositories/purchaserAddress';
import { otherAddressForSendingActions } from '~/state/ducks/otherAddressForSending';
import { purchaserAddressActions } from '~/state/ducks/purchaserAddress';
import { useOtherAddressForSending } from './otherAddressForSending';
import { usePurchaserAddresses } from './purchaserAddresses';
export function useRegisrationAddress() {
    const dispatch = useDispatch();
    const { initialize } = usePurchaserAddresses();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const setNewAddress = useCallback(async (address, deliverTo)=>{
        setApiError(null);
        setIsLoading(true);
        return await createNewAddress(address).then(async (createdAddress)=>{
            if (deliverTo === 'other') {
                dispatch(otherAddressForSendingActions.setSelectedOtherAddressForSending(createdAddress));
            }
            if (deliverTo === 'me') {
                dispatch(purchaserAddressActions.setSelectedPurchaserAddress(createdAddress));
            }
            await initialize();
            return createdAddress;
        }).catch((e)=>{
            setApiError(e);
            return null;
        }).finally(()=>{
            setIsLoading(false);
        });
    }, [
        dispatch,
        initialize
    ]);
    return {
        setNewAddress,
        isLoading,
        apiError
    };
}
export function useDeleteAddress() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const { selectedPurchaserAddress } = usePurchaserAddresses();
    const { selectedOtherAddressForSending } = useOtherAddressForSending();
    const setDeleteAddress = useCallback(async (addressId)=>{
        setApiError(null);
        setIsLoading(true);
        return await deleteAddress(addressId).then(()=>{
            if (selectedPurchaserAddress?.id === addressId) {
                dispatch(purchaserAddressActions.setSelectedPurchaserAddress(null));
            }
            if (selectedOtherAddressForSending?.id === addressId) {
                dispatch(otherAddressForSendingActions.setSelectedOtherAddressForSending(null));
            }
            return true;
        }).catch((e)=>{
            setApiError(e);
            return false;
        }).finally(()=>{
            setIsLoading(false);
        });
    }, [
        dispatch,
        selectedPurchaserAddress?.id,
        selectedOtherAddressForSending?.id
    ]);
    const setSelectedPurchaserAddress = (address)=>{
        dispatch(purchaserAddressActions.setSelectedPurchaserAddress(address));
    };
    return {
        setDeleteAddress,
        setSelectedPurchaserAddress,
        isLoading,
        apiError
    };
}
export function useEditAddress() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const setEditAddress = useCallback(async (addressId, deliveryAddress)=>{
        setIsLoading(true);
        return await patchAddress(addressId, deliveryAddress).then(()=>{
            return true;
        }).catch((e)=>{
            setApiError(e);
            return false;
        }).finally(()=>{
            setIsLoading(false);
        });
    }, []);
    return {
        setEditAddress,
        isLoading,
        apiError
    };
}
export function useAddress() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [currentAddress, setCurrentAddress] = useState(null);
    const getAddressByAddressId = useCallback(async (addressId)=>{
        setIsLoading(true);
        await fetchUserAddressByAddressId(addressId).then((address)=>{
            if (!address) {
                return;
            }
            setCurrentAddress(address);
        }).catch((e)=>{
            setApiError(e);
        }).finally(()=>{
            setIsLoading(false);
        });
    }, []);
    return {
        getAddressByAddressId,
        currentAddress,
        isLoading,
        apiError
    };
}

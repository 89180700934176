import { useDispatch, useSelector } from 'react-redux';
import { destination } from '~/domains/delivery';
import { deliveryActions, deliverySelectors } from '~/state/ducks/delivery';
import { useOtherAddressForSending } from './otherAddressForSending';
import { usePurchaserAddresses } from './purchaserAddresses';
export function useDeliveryDestination() {
    const dispatch = useDispatch();
    const { isDeliverToMe, isDeliverToOther } = destination();
    const deliveryDestination = useSelector(deliverySelectors.deliveryDestination);
    const deliverToMe = isDeliverToMe(deliveryDestination);
    const deliverToOther = isDeliverToOther(deliveryDestination);
    const { selectedPurchaserAddress } = usePurchaserAddresses();
    const { selectedOtherAddressForSending } = useOtherAddressForSending();
    const selectedDestinationAddress = isDeliverToMe(deliveryDestination) ? selectedPurchaserAddress : selectedOtherAddressForSending;
    const setDeliveryDestination = (deliveryDestination)=>{
        dispatch(deliveryActions.setDeliveryDestination(deliveryDestination));
    };
    return {
        deliveryDestination,
        deliverToMe,
        deliverToOther,
        selectedDestinationAddress,
        setDeliveryDestination
    };
}

import TagManager from 'react-gtm-module';
const sendKarteIdentify = (params)=>{
    const { me } = params;
    if (!me) {
        return;
    }
    const dataLayerData = {
        event: 'karte-sendIdentify',
        identify: {
            /* eslint-disable @typescript-eslint/naming-convention */ is_signed_in: true,
            user_id: me.id,
            name: me.name,
            prefecture: me.prefecture,
            email: me.email,
            subscription: me.acceptEmailInfo,
            subscribe_new_official_post: me.subscribeNewOfficialPost,
            photo: me.avatarImageUrl,
            type: me.type,
            registered_at: me.registeredAt,
            first_purchased_at: me.firstPurchasedAt,
            last_purchased_at: me.lastPurchasedAt,
            purchase_count: me.purchaseCount,
            sign_in_count: me.signInCount
        }
    };
    const dataLayer = {
        dataLayerName: 'PageDataLayer',
        dataLayer: dataLayerData
    };
    TagManager.dataLayer(dataLayer);
};
export default sendKarteIdentify;

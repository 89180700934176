/**
 * @file 商品のカテゴリに関する処理など
 */ /**
 * 商品に紐づく、いずれかのカテゴリが加工食品かどうかを判定する
 * NOTE: 暫定対応のため、カテゴリ名を決め打ちとしています
 */ export const isProcessedFoods = (categories)=>{
    return categories.some((category)=>{
        return category.length > 0 && category[0].name === '加工食品';
    });
};
/**
 * 商品に紐づく、いずれかのカテゴリに「お酒」が含まれるかどうかを判定する
 * NOTE: 暫定対応のため、カテゴリ名を決め打ちとしています
 */ export const isAlcoholCategory = (categories)=>{
    return categories.some((category)=>{
        return !!category && category.some(({ name })=>name === 'お酒');
    });
};

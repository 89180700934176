const coupon = {
    rateDiscount: '商品{{value}}％割引',
    amountDiscount: '商品{{value}}円割引',
    discountDeliveryCost: '送料{{value}}円割引',
    freeShipping: '送料無料',
    caution: {
        label: {
            discountDeliveryCost: '{{value}}円割引',
            freeShipping: '送料無料'
        },
        rateDiscount: '割引対象商品です。以下の金額が{{value}}％割引になります。',
        amountDiscount: '割引対象商品です。以下の金額が{{value}}円割引になります。',
        discountDeliveryCost: '以下の送料が{{value}}円割引になります。',
        freeShipping: '以下の送料が無料になります。'
    }
};
export default coupon;

import styled, { css } from 'styled-components';
import { color, size } from '~/styles/constants';
import { breakPoint } from '~/styles/utils';
export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${color.WHITE};
  margin-right: 12px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 25px;
    height: 25px;
  }
`;
export const Title = styled.p`
  flex: 1;
  font-size: ${size.font.XS};
  font-weight: ${size.fontWeight.W6};
  ${breakPoint.PC(css`
    font-size: ${size.font.S};
  `)}
`;

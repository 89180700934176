import TagManager from 'react-gtm-module';
import { getFirstCategoryName } from '../../_shared/utils';
/**
 * GTM(Karte)へkarte_buyイベントを送信する
 * @param product EC/ふるさと納税の商品データ
 * @param purchase
 * @param estimatedPriceItem
 * @param appliedCoupon  ECのみ 適用されたクーポン
 * @param hasFurusatoPurchase  ふるさと納税の購入か
 */ const sendKarteBuy = (product, price, purchase, estimatedPriceItem, appliedCoupon, hasFurusatoPurchase)=>{
    const { rootCategoryName, leafCategoryName } = getFirstCategoryName(product.categories);
    const item = {
        /* eslint-disable @typescript-eslint/naming-convention */ product_id: product.id,
        title: product.title,
        category: rootCategoryName,
        category2: leafCategoryName,
        tags: product.tags,
        price,
        url: `${window.location.origin}${hasFurusatoPurchase ? '/furusato' : ''}/products/${product.id}`,
        image_url: product.images?.[0],
        producer_id: product.user.id,
        producer_name: product.user.name,
        producer_organization_name: product.user.organizationName,
        prefecture: product.user.address,
        has_furusato_product: product.hasFurusatoQuantity
    };
    const buyItem = {
        /* eslint-disable @typescript-eslint/naming-convention */ purchase_id: purchase.id.toString(),
        items: item,
        revenue: estimatedPriceItem.totalAmount,
        tax: purchase.purchaseContract?.consumptionTax || 0,
        payment_fee: hasFurusatoPurchase ? 0 : estimatedPriceItem.paymentFee,
        delivery_cost: hasFurusatoPurchase ? 0 : estimatedPriceItem.totalDeliveryRate,
        temperature_control_rate: hasFurusatoPurchase ? 0 : estimatedPriceItem.temperatureControlRate,
        coupon_code: hasFurusatoPurchase ? null : appliedCoupon?.code,
        discount: hasFurusatoPurchase ? 0 : estimatedPriceItem.discountAmount,
        first_purchase: purchase.firstPurchase,
        has_furusato_purchase: hasFurusatoPurchase
    };
    // GTMで送る
    const dataLayer = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'karte-sendBuy',
            buy: buyItem
        }
    };
    TagManager.dataLayer(dataLayer);
};
export default sendKarteBuy;

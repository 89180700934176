import { SIGN_OUT } from '~/state/ducks/common/actions';
import { SET_MESSAGE } from './actions';
const initialState = {
    message: ''
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_MESSAGE:
            return {
                ...state,
                message: action.payload.message
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

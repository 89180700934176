import axios from 'axios';
import { PARAM_PATH } from 'ec-constants/routes';
import { StatusCodes } from 'http-status-codes';
import { Deserializer } from 'jsonapi-serializer';
import { setIsSignedIn } from '~/domains/user';
import { getSignInHref } from '~/domains/utils/url';
import { handleStandardApiError } from '~/repositories/util/errorHandling';
import { history } from '~/routes';
import { store } from '~/state';
import { commonActions } from '~/state/ducks/common';
export { multiPartFormHeaderConfig } from 'ec-services/api';
const IGNORE_REDIRECTION_ERRORS = [
    // 購入時お届け日更新エラー
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME3020'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME3025'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME3026'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME3028'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME3029'
    },
    // クレカエラー
    {
        status: StatusCodes.TOO_MANY_REQUESTS,
        code: 'PME2013'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME2004'
    },
    {
        status: StatusCodes.BAD_REQUEST,
        code: 'PME5006'
    }
];
// リダイレクトしないエラーを判定
const includeIgnoreError = (code, status)=>{
    return IGNORE_REDIRECTION_ERRORS.some((item)=>{
        return item.status === status && item.code === code;
    });
};
axios.defaults.baseURL = process.env.API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
/**
 * @deprecated 'packages/ec-services' の apiを利用するようにしてください
 * ```
 * - import * as api from '~/services/api'
 * + import { api, deserializeJsonApi } from '~/services/api'
 * ```
 */ /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ export async function get(path, config) {
    const response = await axios.get(path, config);
    return response.data;
}
/**
 * @deprecated 'packages/ec-services' の apiを利用するようにしてください
 * ```
 * - import * as api from '~/services/api'
 * + import { api, deserializeJsonApi } from '~/services/api'
 * ```
 */ /* eslint-disable @typescript-eslint/no-explicit-any */ export async function post(path, data, config) {
    const response = await axios.post(path, data, config);
    return response.data;
}
/* eslint-enable @typescript-eslint/no-explicit-any */ /**
 * @deprecated 'packages/ec-services' の apiを利用するようにしてください
 * ```
 * - import * as api from '~/services/api'
 * + import { api, deserializeJsonApi } from '~/services/api'
 * ```
 */ /* eslint-disable @typescript-eslint/no-explicit-any */ export async function put(path, data, config) {
    const response = await axios.put(path, data, config);
    return response.data;
}
/* eslint-enable @typescript-eslint/no-explicit-any */ /**
 * @deprecated 'packages/ec-services' の apiを利用するようにしてください
 * ```
 * - import * as api from '~/services/api'
 * + import { api, deserializeJsonApi } from '~/services/api'
 * ```
 */ /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ export async function del(path, config) {
    const response = await axios.delete(path, config);
    return response.data;
}
/**
 * @deprecated 'packages/ec-services' の apiを利用するようにしてください
 * ```
 * - import * as api from '~/services/api'
 * + import { api, deserializeJsonApi } from '~/services/api'
 * ```
 */ /* eslint-disable @typescript-eslint/no-explicit-any */ export async function patch(path, data, config) {
    const response = await axios.patch(path, data, config);
    return response.data;
}
/* eslint-enable @typescript-eslint/no-explicit-any */ /**
 * @deprecated 'packages/ec-services/jsonApi' の deserializeJsonApiを利用するようにしてください
 */ export function deserializeJsonApi(data, keyAttribute = 'camelCase') {
    const targetedKeyAttribute = keyAttribute === 'snake_case' ? 'snake_case' : 'camelCase';
    return new Deserializer({
        keyForAttribute: targetedKeyAttribute
    }).deserialize(data);
}
axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorCode = error.response?.data?.errors?.[0]?.code;
    // 特定のエラーの場合、リダイレクトせず、フロント側にエラーを返すようにする
    if (includeIgnoreError(errorCode, error.response?.status)) {
        return Promise.reject(handleStandardApiError(error));
    }
    if (error.response?.status === StatusCodes.UNAUTHORIZED) {
        if (!error.config?.hasCustomActionOnNotAuthorized) {
            setIsSignedIn(false);
            store.dispatch(commonActions.signOut());
            window.location.assign(getSignInHref(location.pathname));
        }
    }
    if (error.response?.status === StatusCodes.NOT_FOUND) {
        if (!error.config?.hasCustomActionOnNotFound) {
            history.push(PARAM_PATH.ERROR('PME1404'));
        }
    }
    if (error.response?.status === StatusCodes.FORBIDDEN) {
        if (!error.config?.hasCustomActionOnForbidden) {
            history.push(PARAM_PATH.ERROR('PME1403'));
        }
    }
    if (error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        if (!error.config?.hasCustomActionOnInternalServerError) {
            history.push(PARAM_PATH.ERROR('PME1500'));
        }
    }
    if (error.response?.status === StatusCodes.BAD_GATEWAY) {
        history.push(PARAM_PATH.ERROR('PME1502'));
    }
    if (error.response?.status === StatusCodes.GATEWAY_TIMEOUT) {
        history.push(PARAM_PATH.ERROR('PME1504'));
    }
    if (error.response?.status === StatusCodes.BAD_REQUEST) {
        // TODO: APIのエラーの型を定義する必要がある（ポケマル内部のAPIかそうでないAPIで変える必要がありそう）
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error.response?.data).errors[0].code) {
            history.push(// eslint-disable-next-line @typescript-eslint/no-explicit-any
            PARAM_PATH.ERROR((error.response?.data).errors[0].code));
        }
    }
    return Promise.reject(handleStandardApiError(error));
});
/**
 * 発生したエラーがStandardApiErrorであるかどうかを判定する
 * @param e エラーオブジェクト
 */ export const isStandardApiError = (e)=>{
    if (!e) {
        return false;
    }
    return 'errorCode' in e && 'statusCode' in e && 'meta' in e;
};

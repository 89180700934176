/* eslint-disable @typescript-eslint/naming-convention */ import TagManager from 'react-gtm-module';
import { DEFAULT_DATA_LAYER_NAME } from '../constants';
import { convertProductIntoItem } from '../utils';
/**
 * GTM(GA4)へpurchase_confirmationイベントを送信する
 * @param product EC/ふるさと納税の商品データ
 * @param quantity  EC/ふるさと納税の選択している分量
 * @param fromFurusato ふるさとから購入するか
 * @param coupon ECのの選択しているCoupon
 */ const sendPurchaseConfirmation = (product, quantity, fromFurusato, paymentType, coupon)=>{
    const item = convertProductIntoItem(product, quantity);
    const dataLayer = {
        event: 'purchase_confirmation',
        currency: 'JPY',
        value: quantity ? quantity.price : 0,
        has_furusato_purchase: fromFurusato,
        payment_type: paymentType,
        coupon: coupon || '',
        items: [
            item
        ],
        _clear: true
    };
    TagManager.dataLayer({
        dataLayerName: DEFAULT_DATA_LAYER_NAME,
        dataLayer
    });
};
export default sendPurchaseConfirmation;

const myPageMenu = {
    producerDashboard: '生産者管理画面に切り替える',
    message: 'メッセージ',
    followingCommunities: 'フォロー生産者',
    likedProduct: 'お気に入り',
    orderHistory: '注文履歴',
    subscriptions: '定期・予約の管理',
    introduction: 'ポケマルを友達に紹介',
    entryProducer: '生産者として登録',
    setting: '設定',
    addressEdit: 'お届け先',
    paymentMethodEdit: '支払い方法',
    notificationSetting: 'メール通知設定',
    changePassword: 'パスワード変更',
    furusato: 'ふるさと納税',
    contributions: '納税履歴',
    point: 'ポイント残高',
    logout: 'ログアウト'
};
export default myPageMenu;

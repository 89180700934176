import { ROUTER_PATH } from 'ec-constants/routes';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { creditCardBrand } from '~/domains/creditCard';
import { storeTdsState } from '~/domains/utils/tds';
import { createMaskedCreditCardInfo, createMaskedCreditCardInfoLegacy, deleteCreditCardInfo } from '~/repositories/creditCard';
import { creditCardActions } from '~/state/ducks/creditCard';
import { useCreditCards } from './creditCards';
import { useGmoPG } from './useGmoPG';
const CALLBACK_URL = `${process.env.ENVIRONMENT === 'local' ? window.location.origin : process.env.BASE_URL}${ROUTER_PATH.TDS_CREDIT_CARD_REGISTER_CALLBACK_EC_REACT}`;
export function useRegisrationCreditCard() {
    const { getToken } = useGmoPG(process.env.GMO_SHOP_ID || '');
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    /**
   * クレジットカードを登録する。
   * カード登録後に3Dセキュア認証が必要な場合は、3Dセキュア認証画面に遷移する。
   */ const createNewCreditCard = useCallback(async (creditCard)=>{
        const { holderName, cardNumber, expirationMonth, expirationYear, securityCode, main, subscriptionPaymentChecked } = creditCard;
        const { getCreditCardBrand } = creditCardBrand(cardNumber);
        setApiError(null);
        setIsLoading(true);
        try {
            // GMOPGに登録するクレカのトークンを取得をし、マスク化したクレカ情報を取得
            const gmoTokenObject = await getToken({
                holdername: holderName,
                cardno: cardNumber,
                expire: expirationYear + expirationMonth,
                securitycode: securityCode
            });
            // csrf抑止のためのstate
            const tdsState = storeTdsState();
            // クレカを登録
            const res = await createMaskedCreditCardInfo({
                gmopgCustomer: {
                    maskedCardNo: gmoTokenObject.maskedCardNo,
                    cardExpMonth: expirationMonth,
                    cardExpYear: expirationYear,
                    token: gmoTokenObject.token,
                    cvcCheck: gmoTokenObject.isSecurityCodeSet,
                    cardBrand: getCreditCardBrand()
                },
                main,
                setSubscriptionPayments: subscriptionPaymentChecked
            }, {
                returnUrl: CALLBACK_URL,
                state: tdsState
            });
            return res;
        } catch (e) {
            setIsLoading(false);
            setApiError(e);
            return null;
        }
    }, [
        getToken
    ]);
    /**
   * 旧処理（3DS認証なしで）クレジットカードを登録する。
   */ const createNewCreditCardLegacy = useCallback(async (creditCard)=>{
        const { holderName, cardNumber, expirationMonth, expirationYear, securityCode, main, subscriptionPaymentChecked } = creditCard;
        const { getCreditCardBrand } = creditCardBrand(cardNumber);
        setApiError(null);
        setIsLoading(true);
        try {
            // GMOPGに登録するクレカのトークンを取得をし、マスク化したクレカ情報を取得
            const gmoTokenObject = await getToken({
                holdername: holderName,
                cardno: cardNumber,
                expire: expirationYear + expirationMonth,
                securitycode: securityCode
            });
            // クレカを登録
            const res = await createMaskedCreditCardInfoLegacy({
                gmopgCustomer: {
                    maskedCardNo: gmoTokenObject.maskedCardNo,
                    cardExpMonth: expirationMonth,
                    cardExpYear: expirationYear,
                    token: gmoTokenObject.token,
                    cvcCheck: gmoTokenObject.isSecurityCodeSet,
                    cardBrand: getCreditCardBrand()
                },
                main,
                setSubscriptionPayments: subscriptionPaymentChecked
            });
            return res;
        } catch (e) {
            setIsLoading(false);
            setApiError(e);
            return null;
        }
    }, [
        getToken
    ]);
    return {
        createNewCreditCard,
        createNewCreditCardLegacy,
        isLoading,
        apiError
    };
}
export function useDeleteCreditCard() {
    const dispatch = useDispatch();
    const { initialize } = useCreditCards();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const setDeleteCreditCard = useCallback(async (creditCardId)=>{
        setApiError(null);
        setIsLoading(true);
        try {
            await deleteCreditCardInfo(creditCardId);
            dispatch(creditCardActions.setSelectedCreditCard(null));
            await initialize();
            return true;
        } catch (e) {
            setApiError(e);
            return false;
        } finally{
            setIsLoading(false);
        }
    }, [
        dispatch,
        initialize
    ]);
    return {
        setDeleteCreditCard,
        isLoading,
        apiError
    };
}
export function useSelectedCreditCard() {
    const dispatch = useDispatch();
    const setSelectedCreditCard = (selectedCreditCard)=>{
        dispatch(creditCardActions.setSelectedCreditCard(selectedCreditCard));
    };
    return {
        dispatch,
        setSelectedCreditCard
    };
}

import { SIGN_OUT } from '~/state/ducks/common/actions';
import { RESET_PAYMENT_MRETHOD, SET_PAYMENT_METHOD } from './actions';
const initialState = {
    paymentMethod: 'point_payment'
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload.paymentMethod
            };
        case RESET_PAYMENT_MRETHOD:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

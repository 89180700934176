import { ROUTER_PATH } from 'ec-constants/routes';
/**
 * document.referrerのデータをパースして、ドメインを除いたパスを生成する
 * 自身のサイト以外の場合は空文字を返す
 * @param referrer document.referrerの値。通常はdocument.referrerをそのまま渡す
 * @returns パス
 */ export const getReferrerPath = (referrer)=>{
    let referrerPath = '';
    if (referrer) {
        try {
            const url = new URL(referrer);
            if (url.origin === process.env.BASE_URL) {
                referrerPath = url.pathname + url.search;
            }
        } catch  {
        // パースに失敗した場合は無視
        }
    }
    return referrerPath;
};
/**
 * fromのクエリパラメータを生成する
 * @param location Locationオブジェクト or パスの文字列
 */ const generateFromQuery = (location)=>{
    const params = new URLSearchParams();
    if (typeof location === 'string') {
        params.append('from', location);
    } else if (location && location.pathname) {
        params.append('from', location.pathname + location.search);
    }
    const query = params.toString();
    return query ? '?' + query : '';
};
/**
 * ログインページのURLを生成する
 * @param location ログイン後にリダイレクトするURLの元になるLocationオブジェクト or パスの文字列
 */ export const getSignInHref = (location)=>{
    return `${ROUTER_PATH.SIGN_IN}${generateFromQuery(location)}`;
};
/**
 * サインアップページのURLを生成する
 * @param location サインアップ後にリダイレクトするURLの元になるLocationオブジェクト or パスの文字列
 */ export const getSignUpHref = (location)=>{
    return `${ROUTER_PATH.SIGN_UP}${generateFromQuery(location)}`;
};

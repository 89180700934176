const furusatoStatic = {
    guide: {
        About: {
            title: '<0>ふるさと納税って</0><1>どんな制度？</1>',
            figure1Note: '※ワンストップ特例制度を利用した場合の例',
            figure1Alt: 'ふるさと納税のしくみ',
            description1: '<0>ふるさと納税とは、自分で選んだお好きな自治体へ納税をすることができる制度です。</0><1>上記で納めた金額は、翌年の住民税から控除されるため、ふるさと納税をした金額分、税負担が増えるわけではありません。</1><2>その上で、返礼品も受け取ることができる嬉しい制度です。</2>',
            description1Note: '※税制上は選んだ自治体にふるさと納税することを「寄附」と呼びます。',
            figure2Caption: 'ふるさと納税総額',
            figure2Alt: 'ふるさと納税総額の自己負担は2000円',
            description2: '<0>そして、住民税の控除は前年のふるさと納税総額から2000円を除いた分を受けることができます。</0><1>つまり、実質年間2000円の自己負担で返礼品を受け取ることができるのが、ふるさと納税制度なのです。</1>'
        },
        MainImage: {
            alt: 'はじめてでもかんたん ふるさと納税とは？'
        },
        HappyPoints: {
            title: 'ポケマルでふるさと納税の<0>うれしいポイント</0>',
            subTitle1: '<0>返礼品</0>にポケマルの商品を選べる',
            description1: '生産者さんが直接出品するポケマルの食材が、そのままふるさと納税の返礼品に！<br/>ポケマル食材の「品質」と「バリエーション」はそのままに、ふるさと納税をお楽しみいただけます！',
            subTitle2: '<0>生産者</0>さんと直接やりとりができる',
            description2: '注文後、生産者さんと直接メッセージができるのもポケマルの魅力。届いた後に美味しい食べ方を聞くなど、思い思いのコミュニケーションをふるさと納税でもお楽しみください！',
            button: 'お礼の品を見てみる'
        },
        Flow: {
            title: '<0>ふるさと納税の</0><1>ながれを知ろう</1>',
            description: 'ふるさと納税は、下記3つのステップで簡単に始めることができます。',
            StepList: {
                Step1: {
                    title: '上限額を知ろう',
                    text: '納税した総額が翌年控除されるふるさと納税ですが、控除額には上限があります。上限額は所得や家族構成によって変わるので、シミュレーターで上限額の目安を計算してみましょう。'
                },
                Step2: {
                    title: 'お礼の品を探そう',
                    text: '食べてみたい旬の食材、好きな生産者さん、応援したい自治体など、あなたなりの切り口でお礼の品を選んでみましょう。'
                },
                Step3: {
                    title: '税金の控除・還付をうけよう',
                    text: '税金の控除を受けるためには、「ワンストップ特例制度」または「確定申告」を行いましょう。'
                }
            }
        },
        Step1: {
            title: '上限額を知ろう',
            description: '<0>年間のふるさと納税額には上限があります。</0>上限額を超えて納税をすると、その分の金額は控除されず、自己負担となってしまいます。\n上限額は年収や家族構成によって異なるので、下記シミュレーターを使い、ご自身の上限額を確認しましょう。',
            buttonSubText: 'まずはおおまかな目安を知りたい！',
            buttonText: 'かんたんシミュレーションする'
        },
        Step2: {
            title: 'お礼の品を探そう'
        },
        Step3: {
            title: '申請をして税金の控除・還付をうけよう',
            description: '最後に、ふるさと納税した総額を翌年控除してもらうには「ワンストップ特例申請」もしくは 「確定申告」を行う必要があります。',
            notice: '※確定申告では、控除と還付の組み合わせとなります',
            target: '対象',
            request: '申請',
            deduction: '控除',
            deadline: '期限',
            OneStop: {
                title: 'ワンストップ特例申請',
                text: '「ワンストップ特例申請」は、確定申告を行わなくても、ふるさと納税の控除が受けられる方法です。\n自治体から送られてくる「ワンストップ特例申請書」を納税した自治体へ送ると、納税額が翌年の住民税から控除されます。',
                targetText1: '・1年間で寄付した自治体が5か所以内',
                targetNotice1: '※1つの自治体へ複数回納税しても1カウント',
                targetText2: '・確定申告が必要な他の理由がない',
                targetNotice2: '※医療控除などの申告がある方、個人事業主の方など',
                requestText: 'ふるさと納税の都度、各自治体に申請書および本人証明書類を提出',
                deductionText: '住民税から控除（減額）',
                deadlineText: '申請書の提出期限：寄付した翌年の1月10日必着',
                buttonText: 'ワンストップ特例申請のやり方を見る'
            },
            TaxReturn: {
                title: '確定申告',
                text: '確定申告をふるさと納税をした年に行う方法です。確定申告の際には自治体より送付される「寄附金受領証明書」の添付が必要となります。\n確定申告を行うと、ふるさと納税をした年の所得税が還付金として、翌年度の住民税が減額という形で寄付金額より控除されます。',
                targetText1: '・1年間で納税した自治体が6か所以上',
                targetNotice1: '※1つの自治体へ複数回納税しても1カウント',
                targetText2: '・確定申告が必要な理由あり',
                targetNotice2: '※医療控除などの申告がある方、個人事業主の方など',
                requestText: '年に一度、税務署に寄付金受領証明書を確定申告書類と共に提出',
                deductionText: '所得税からの還付と、住民税からの控除',
                deadlineText: '確定申告の期限：寄付した翌年の3月15日',
                buttonText: '確定申告についてを見る'
            }
        },
        Question: {
            title: 'よくある質問',
            buttonText: 'よくある質問一覧ページを見る'
        }
    },
    simulation: {
        About: {
            title: '<0>ふるさと納税</0> <0>控除上限額</0> <0>シミュレーター</0>',
            description: '実質自己負担2,000円でふるさと納税を行える上限額は、年収や家族構成、お住いの地域など、人によって異なります。<br/>本シミュレーターを活用し、年間の上限額をご確認下さい。'
        },
        Calculation: {
            easy: {
                title: 'かんたんシミュレーションする',
                subTitle: 'まずはおおまかな目安を知りたい！'
            },
            detail: {
                title: '詳細シミュレーションする',
                subTitle: '条件を入力してより詳しく知りたい！'
            },
            Easy: {
                salary: '年収を\n選択してください',
                placeholder: '選択して下さい',
                thousandYen: '万円',
                family: {
                    title: '家族構成を\n選択してください',
                    single: '独身',
                    notSingle: '既婚',
                    spouseDeduction: {
                        title: '配偶者控除はうけていますか？',
                        description: '※配偶者の給与収入がない、もしくは201万円以下の場合は「配偶者控除あり」を選択します。',
                        yes: '配偶者控除あり',
                        no: '配偶者控除なし'
                    },
                    dependentFamily: {
                        title: 'お子様はいらっしゃいますか？',
                        yes: 'いる',
                        no: 'いない',
                        children: '「いる」と答えた方でお子様の年齢と人数で該当するものを選択してください',
                        description1: '※中学生以下のお子様は計算上控除額に影響がないため、選択いただく必要はありません。',
                        description2: '※お子様の組み合わせが選択肢にない場合は、詳細シミュレーションをご利用ください。',
                        child: '16〜18歳の子供が1人いる',
                        colleger: '19〜22歳の子供が1人いる',
                        childAndCollerger: '16〜18歳の子供と19〜22歳の子供が1人ずついる'
                    }
                }
            },
            Detail: {
                employee: '給与所得者',
                selfEmployed: '個人事業主',
                caution1: 'お手元に源泉徴収票をご用意ください。',
                caution2: 'シミュレーション結果はあくまで目安となります。 より正確な金額を知りたい場合は、税理士等にご確認ください。',
                yen: '円',
                required: '必須',
                selfSalary: 'あなたの給与収入額',
                selfSalaryDialog: '源泉徴収票に「支払金額」と書かれている項目の数字を入力してください。',
                income: '所得金額の合計額',
                incomeDialog: '本年の所得金額見込（収入金額から必要経費を引いた額）を入力してください\n※確定申告書では所得金額等の合計額に該当します',
                partner: {
                    title: '配偶者の有無',
                    none: 'なし',
                    lt69: '配偶者あり（69歳以下）',
                    gt70: '配偶者あり（70歳以上）'
                },
                partnerSalary: '配偶者の給与収入額',
                dependentFamily: {
                    hasChildren: '扶養家族の有無',
                    yes: 'いる',
                    no: 'いない',
                    howMany: '扶養家族の人数',
                    lt15: '15歳以下の方',
                    gt16lt18: '16歳〜18歳の方',
                    gt19lt22: '19歳〜22歳の方',
                    gt23lt69: '23歳〜69歳の方',
                    gt70Parents: '70歳の以上の方\n（同居の親・祖父母）',
                    gt70: '70歳の以上の方（上記以外）'
                },
                challengedFamilyMemberCounts: {
                    title: '本人含む障害者の人数',
                    challenged: '一般の障害者',
                    severeChallengedSelfOrLiveSeparately: '本人・別居の特別障害者の人数',
                    severeChallengedLiveIn: '同居特別障害者'
                },
                spouseLossType: {
                    title: '寡婦に該当しますか？',
                    lossHusband: '寡婦',
                    singleParent: 'ひとり親',
                    otherwise: '該当しない',
                    dialog: '寡婦控除もしくはひとり親控除を受けている方は、該当の選択肢に変更してください'
                },
                altDialog: '源泉徴収票',
                dialog: 'お手元に源泉徴収票がある場合は以上をご参考ください。',
                socialInsuranceAmount: '社会保険料等の金額',
                socialInsuranceDialog: '健康保険料、介護保険料、厚生年金保険料、雇用保険料を含みます。',
                selfSocialInsuranceDialog: '本人及び、本人が支払をしている生計を一にする親族の健康保険料・年金保険料・介護保険料等の総額を入力してください\n※確定申告書では所得から差し引かれる金額の社会保険料控除の金額に該当します',
                smallBusinessMutualAidAmount: '小規模企業共済等掛け金の金額',
                smallBusinessMutualAidDialog: '年末調整で申告済みの場合は、源泉徴収票の社会保険料等の金額に含まれている為、0を入力してください。',
                selfSmallBusinessMutualAidDialog: '小規模企業共済やiDeCoに加入している方は、年間の支払総額を入力してください\n※確定申告書では所得から差し引かれる金額の小規模企業共済等掛金控除の金額に該当します',
                lifeInsuranceDeductionAmount: '生命保険料の控除額',
                selfLifeInsuranceDeductionDialog: '年間の生命保険料支払総額を入力してください。\n※確定申告書の生命保険料控除は、控除額計算後の金額のため、そのまま代入することはできません',
                earthquakeInsuranceAmount: '地震保険料の控除額',
                selfEarthquakeInsuranceDialog: '年間の地震保険料支払総額を入力してください\n※確定申告書の地震保険料控除は、控除額計算後の金額のため、そのまま代入することはできません',
                medicalExpensesDeductionAmount: '医療費控除の金額',
                medicalExpensesDeductionDialog: 'その年に支払った医療費が一定額を超える時に受けられる控除です。\n実際にかかった医療費ではなく、控除される金額を入力してください。\n\n医療費控除の金額は、次の式で計算した金額（最高で200万円）です。\n実際に支払った医療費の合計額 - (1)の金額 - (2)の金額\n\n(1)保険金などで補てんされる金額\n保険金などで補てんされる金額は、その給付の目的となった医療費の金額を限度として差し引きますので、引ききれない金額が生じた場合であっても他の医療費からは差し引きません。\n\n(2)10万円（その年の総所得金額等が200万円未満の人は、総所得金額等の5％の金額）',
                selfMedicalExpensesDeductionDialog: 'その年に支払った医療費が一定額を超える時に受けられる控除です。\n実際にかかった医療費ではなく、控除される金額を入力してください。\n\n医療費控除の金額は、次の式で計算した金額（最高で200万円）です。\n実際に支払った医療費の合計額 - (1)の金額 - (2)の金額\n\n(1)保険金などで補てんされる金額\n保険金などで補てんされる金額は、その給付の目的となった医療費の金額を限度として差し引きますので、引ききれない金額が生じた場合であっても他の医療費からは差し引きません。\n\n(2)10万円（その年の総所得金額等が200万円未満の人は、総所得金額等の5％の金額）\n\n※確定申告書では所得から差し引かれる金額の医療費控除の金額に該当します'
            },
            yen: '円',
            result: 'あなたの今年度の控除上限額の目安は',
            description: 'この金額までなら自己負担額実質2000円でふるさと納税をすることができます',
            easyCaution: '※独身の方で、お子様やご両親など、扶養家族がいらっしゃる方は「詳細シミュレーション」をご利用ください',
            detailCaution: '※住宅ローン控除を受けている方は、正しい上限額が計算できない場合がございます。\n 上限額に対して多少余裕をもってふるさと納税をしていただくか、税理士等にご相談下さい。'
        },
        Features: {
            title: 'ポケマルで\nふるさと納税をしてみよう'
        },
        Guide: {
            title: 'ふるさと納税が\nはじめての方へ',
            button: 'ふるさと納税ってなに？'
        }
    }
};
export default furusatoStatic;

import * as api from '~/services/api';
function resToAddressFromPostalCode(res) {
    const { address1, address2, address3 } = res;
    return {
        state: address1,
        city: address2,
        address1: address3
    };
}
export async function fetchAddressFromPostalCode(postalCode) {
    const json = await api.get(`v1/postal_codes/${postalCode}/address`, {
        hasCustomActionOnNotFound: true
    });
    const res = await api.deserializeJsonApi(json);
    return resToAddressFromPostalCode(res);
}

export const SET_INPUT_SEARCH_TEXT = 'searchBar/SET_INPUT_SEARCH_TEXT';
export const setInputSearchText = (text)=>({
        type: SET_INPUT_SEARCH_TEXT,
        payload: {
            text
        }
    });
export default {
    setInputSearchText
};

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { creditCardReducers } from './ducks/creditCard';
import { deliveryReducers } from './ducks/delivery';
import { otherAddressForSendingReducers } from './ducks/otherAddressForSending';
import { purchaserAddressReducers } from './ducks/purchaserAddress';
import { searchBarReducers } from './ducks/searchBar';
import { subscriptionDestinationReducers } from './ducks/subscriptionDestination';
import { toastReducers } from './ducks/toast';
import { userReducers } from './ducks/user';
import { ageVerificationFieldReducers as furusatoAgeVerificationFieldReducers } from './furusato/ducks/ageVerification';
import { deliveryReducers as furusatoDeliveryReducers } from './furusato/ducks/delivery';
import { estimationReducers as furusatoEstimationReducers } from './furusato/ducks/estimation';
import { messageInPurchaseReducers as furusatoMessageInPurchaseReducers } from './furusato/ducks/messageInPurchase';
import { paymentMethodReducers as furusatoPaymentMethodReducers } from './furusato/ducks/paymentMethod';
import { paymentTaxReducers as furusatoPaymentTaxReducers } from './furusato/ducks/paymentTax';
import { purchaseReducers as furusatoPurchaseReducers } from './furusato/ducks/purchase';
import { purchaseFormReducers as furusatoPurchaseFormReducers } from './furusato/ducks/purchaseForm';
import { searchBarReducers as furusatoSearchBarReducers } from './furusato/ducks/searchBar';
export const reducer = combineReducers({
    user: userReducers,
    purchaserAddress: purchaserAddressReducers,
    otherAddressForSending: otherAddressForSendingReducers,
    delivery: deliveryReducers,
    creditCard: creditCardReducers,
    subscriptionDestination: subscriptionDestinationReducers,
    toast: toastReducers,
    searchBar: searchBarReducers,
    furusatoPurchase: furusatoPurchaseReducers,
    furusatoPurchaseForm: furusatoPurchaseFormReducers,
    furusatoSearchBar: furusatoSearchBarReducers,
    furusatoPaymentMethod: furusatoPaymentMethodReducers,
    furusatoEstimation: furusatoEstimationReducers,
    furusatoDelivery: furusatoDeliveryReducers,
    furusatoMessageInPurchase: furusatoMessageInPurchaseReducers,
    furusatoPaymentTax: furusatoPaymentTaxReducers,
    furusatoAgeVerificationField: furusatoAgeVerificationFieldReducers
});
export const store = configureStore({
    reducer
});

/**
 * Androidアプリ向けメッセージ機能を保有するかどうか
 * - Androidのアプリのバージョンが2.7未満かどうかを判別するために以下の条件式としている
 */ export const hasNativeBridgeAndroid = ()=>{
    return !!window.nativebridge;
};
/**
 * Andoridアプリ向けメッセージを送信
 */ const postMessageToAndroid = (data)=>{
    if (!hasNativeBridgeAndroid()) {
        return;
    }
    window.nativebridge?.postMessage(JSON.stringify(data || {}));
};
export default postMessageToAndroid;

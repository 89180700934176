import { SIGN_OUT } from '../../../ducks/common/actions';
import ageVerificationActions from './actions';
const { SET_AGE_VERIFICATION, // eslint-disable-next-line @typescript-eslint/no-unused-vars
setAgeVerification } = ageVerificationActions;
const initialState = {
    ageVerification: false
};
export default function reducer(state = initialState, action) {
    switch(action.type){
        case SET_AGE_VERIFICATION:
            return {
                ...state,
                ageVerification: action.payload.ageVerification
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

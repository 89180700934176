import i18n from '~/locale';
export const destination = ()=>{
    const isDeliverToMe = (deliverTo)=>{
        return deliverTo === 'me';
    };
    const isDeliverToOther = (deliverTo)=>{
        return deliverTo === 'other';
    };
    return {
        isDeliverToMe,
        isDeliverToOther
    };
};
export const deliveryProvider = (productQuantity)=>{
    const deliveryMethod = productQuantity?.deliveryMethod;
    const isYamatoProvider = ()=>{
        return deliveryMethod?.provider === 'yamato';
    };
    const isProducerSelfProvider = ()=>{
        return deliveryMethod?.provider === 'producer';
    };
    return {
        isYamatoProvider,
        isProducerSelfProvider
    };
};
export const deliveryProviderWithoutQuantityArgument = ()=>{
    const isYamatoProvider = (provider)=>{
        return provider === 'yamato';
    };
    const isProducerSelfProvider = (provider)=>{
        return provider === 'producer';
    };
    return {
        isYamatoProvider,
        isProducerSelfProvider
    };
};
export const convertYamatoDeliveryTimeSlots = (timeOfDay)=>{
    const i18nKey = 'constants:yamatoTime';
    if (timeOfDay === 'yamato_time_08_12') {
        return i18n.t(`${i18nKey}.am`);
    }
    if (timeOfDay === 'yamato_time_14_16') {
        return i18n.t(`${i18nKey}.from14To16`);
    }
    if (timeOfDay === 'yamato_time_16_18') {
        return i18n.t(`${i18nKey}.from16To18`);
    }
    if (timeOfDay === 'yamato_time_18_20') {
        return i18n.t(`${i18nKey}.from18To20`);
    }
    if (timeOfDay === 'yamato_time_19_21') {
        return i18n.t(`${i18nKey}.from19To21`);
    }
    return null;
};
/**
 * 週次配送の曜日テキストを取得
 * @param settlementDay 曜日の数値(0〜6)
 * @returns 曜日のテキスト
 */ export const getWeekDaysText = (settlementDay)=>{
    switch(settlementDay){
        case 0:
            return '日';
        case 1:
            return '月';
        case 2:
            return '火';
        case 3:
            return '水';
        case 4:
            return '木';
        case 5:
            return '金';
        case 6:
            return '土';
        default:
            return '';
    }
};

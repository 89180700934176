export const addressRegExp = ()=>{
    const katakanaRegExp = /^[ァ-ヴー]+$/;
    const postalCodeRegExp = /^([0-9]{7})$/;
    const phoneNumberRegExp = /^([0-9]{10,11})$/;
    return {
        katakanaRegExp,
        postalCodeRegExp,
        phoneNumberRegExp
    };
};
const getByteLengthFromChar = (chr)=>{
    if (chr >= 0x00 && chr < 0x81 || chr === 0xf8f0 || chr >= 0xff61 && chr < 0xffa0 || chr >= 0xf8f1 && chr < 0xf8f4) {
        return 1;
    }
    return 2;
};
export const getTotalByteLength = (value)=>{
    let totalByteLength = 0;
    const valueLength = value.length;
    for(let i = 0; i < valueLength; i++){
        totalByteLength += getByteLengthFromChar(value.charCodeAt(i));
    }
    return totalByteLength;
};

import { useDispatch, useSelector } from 'react-redux';
import { subscriptionDestinationActions, subscriptionDestinationSelectors } from '~/state/ducks/subscriptionDestination';
export function useSubscriptionDestination() {
    const dispatch = useDispatch();
    const selectedSubscriptionDestination = useSelector(subscriptionDestinationSelectors.selectedSubscriptionDestination);
    const setSubscriptionDestination = (address)=>{
        dispatch(subscriptionDestinationActions.setSubscriptionDestination(address));
    };
    const resetSubscriptionDestination = ()=>{
        dispatch(subscriptionDestinationActions.resetSubscriptionDestination());
    };
    return {
        selectedSubscriptionDestination,
        setSubscriptionDestination,
        resetSubscriptionDestination
    };
}

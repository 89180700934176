import camelcaseKeys from 'camelcase-keys';
import * as api from '~/services/api';
function resToContributions(res, meta) {
    const contributions = res.map((contribution)=>{
        return {
            ...contribution,
            id: Number(contribution.id),
            localGovernmentName: contribution.localGovernment?.name
        };
    });
    return {
        data: contributions,
        ...meta
    };
}
export function getContributionsPath({ page, perPage = 20 }) {
    return `v1/furusato/payment_taxes?page=${page}&per_page=${perPage}`;
}
export async function swrFetcher(path) {
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    const page = camelcaseKeys(json.meta.page);
    return resToContributions(res, page);
}
function resToLatestContribution(res) {
    if (!res[0]) {
        return {
            contribution: null
        };
    }
    const taxPayer = res[0].taxPayer;
    return {
        contribution: {
            id: Number(res[0].id),
            address1: taxPayer.address1,
            address2: taxPayer.address2,
            birthDate: taxPayer.dob ?? null,
            city: taxPayer.city,
            firstName: taxPayer.firstName,
            firstNamePronunciation: taxPayer.firstNameKana,
            lastName: taxPayer.lastName,
            lastNamePronunciation: taxPayer.lastNameKana,
            mail: taxPayer.email,
            isOneStop: res[0].oneStop ? 'yes' : 'no',
            phoneNumber: taxPayer.telephoneNumber,
            postalCode: taxPayer.postcode,
            state: taxPayer.state,
            gender: taxPayer.gender
        }
    };
}
export async function swrLatestFetcher(path) {
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    return resToLatestContribution(res);
}

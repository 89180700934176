import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaymentMethods } from '~/repositories/paymentMethods';
import { creditCardActions, creditCardSelectors } from '~/state/ducks/creditCard';
export function useCreditCards() {
    const dispatch = useDispatch();
    const [isInitializeLoading, setIsInitializeLoading] = useState(false);
    const [error, setError] = useState(null);
    const selectedCreditCard = useSelector(creditCardSelectors.selectedCreditCard);
    const creditCards = useSelector(creditCardSelectors.creditCards);
    const hasCreditCards = creditCards ? creditCards.length > 0 : false;
    //関数再生成によるuseEffect内での重複実行を避ける為、useCallbackを使用
    const initialize = useCallback(async ()=>{
        setError(null);
        setIsInitializeLoading(true);
        return await fetchPaymentMethods().then((fetchedMethods)=>{
            if (!fetchedMethods.length) {
                dispatch(creditCardActions.setCreditCards([]));
            }
            dispatch(creditCardActions.setCreditCards(fetchedMethods));
            const mainCreditCard = fetchedMethods.find((method)=>method.isMain);
            if (mainCreditCard) {
                dispatch(creditCardActions.setSelectedCreditCard(mainCreditCard));
            }
            return true;
        }).catch((e)=>{
            setError(e);
            return false;
        }).finally(()=>{
            setIsInitializeLoading(false);
        });
    }, [
        dispatch
    ]);
    return {
        initialize,
        selectedCreditCard,
        creditCards,
        hasCreditCards,
        error,
        isInitializeLoading
    };
}

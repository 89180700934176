// NOTE: `:id` などが含まれるものはNext.jsでは利用しないので不要かと思われる
export const ROUTER_PATH = {
    ERROR: '/error/:code',
    HOME: '/',
    PRODUCT_LIST: '/products',
    PRODUCT_DETAIL: '/products/:id',
    PURCHASE_ADDRESS: '/delivery_destinations',
    PURCHASE_ADDRESS_EDIT: '/delivery_destinations/:id/edit',
    PURCHASE_CREDIT_CARD: '/payment_methods',
    PRODUCER_LIST: '/producers',
    PRODUCER: '/producers/:id',
    COMMUNITY_POSTS: '/producers/:id/posts',
    POST: '/posts/:id',
    MY_PAGE: '/users/me',
    PROFILE: '/users/edit',
    NOTIFICATION_SETTING: '/users/mail_notification_setting/edit',
    PASSWORD_SETTING: '/profile/edit',
    PASSWORD_RESET_EMAIL: '/users/password/new',
    PASSWORD_RESET: '/users/password/edit',
    SIGN_UP: '/users/sign_up',
    SIGN_UP_OAUTH: '/users/sign_up/oauth',
    SIGN_UP_TEMPORARY_COMPLETE: '/users/confirm_email',
    SIGN_UP_CONFIRMATION: '/users/sign_up_confirmation',
    SIGN_UP_COMPLETE: '/users/registrations/finish',
    SIGN_IN: '/users/sign_in',
    SIGN_OUT: '/users/sign_out',
    LEAVE_MEMBER_SHIP: '/users/leave_membership',
    SHARING: '/sharing',
    STORY_LIST: '/stories',
    LIKED_PRODUCT_LIST: '/liked_products',
    SPECIFIED_COMMERCIAL_LAW: '/specified_commercial_law',
    LINE_AUTH: '/users/auth/line',
    CONFIRMATION_LINE_AUTH: '/users/confirmation_line_auth',
    CONFIRMATION_APPLE_AUTH: '/users/confirmation_apple_auth',
    /**
   * @deprecated nextjsに移行時に不要となる
   */ APPLE_AUTH: '/users/auth/apple',
    TERMS: '/terms_of_use',
    TERMS_PRODUCER: '/terms_of_use/:type',
    // 特定商取引法に関する表示
    PRODUCERS_LAW: '/producers/:id/law',
    EMAIL_UPDATE_CONFIRMATION: '/users/email_update_confirmation',
    MESSAGE_BOX: '/message/message_box',
    MESSAGE_THREAD: '/message/threads/:id',
    MESSAGE_INQUIRIES: `/message/inquiries/new`,
    // フォロー生産者
    FOLLOWING_COMMUNITIES: '/following_communities',
    // お知らせ
    ANNOUNCEMENTS: '/announcements',
    ANNOUNCEMENT_DETAIL: '/announcements/:id',
    // 注文
    ORDERS: '/orders',
    ORDER_DETAIL: '/orders/:id',
    ORDER_FEEDBACK: '/orders/:id/feedbacks',
    // 定期
    SUBSCRIPTIONS: '/subscription_purchases',
    SUBSCRIPTION_DETAIL: '/subscription_purchases/:id',
    SUBSCRIPTION_SKIP: '/subscription_skip/:id',
    // カテゴリ一覧
    CATEGORIES: '/categories',
    // 3Dセキュアのリダイレクト(仮になります)
    TDS_CREDIT_CARD_REGISTER_CALLBACK_EC: '/tds/cc/callback',
    TDS_CREDIT_CARD_REGISTER_CALLBACK_EC_REACT: '/tds2/cc/callback',
    TDS_CONTRACT_CALLBACK: '/tds/callback'
};
export const PARAM_PATH = {
    ERROR: (errorCode)=>`/error/${errorCode}`,
    PRODUCT_DETAIL: (productId)=>`/products/${productId}`,
    PRODUCER_DETAIL: (producerId)=>`/producers/${producerId}`,
    COMMUNITY_POSTS: (producerId, postsViewType)=>`/producers/${producerId}/posts${postsViewType === 'producer' ? '?type=producer' : ''}`,
    COMMUNITY_FORM_POST: (communityId, productId)=>`/communities/${communityId}/posts/new` + (productId ? `?product_id=${productId}` : ''),
    POST_DETAIL: (postId)=>`/posts/${postId}`,
    PURCHASE_ADDRESS_EDIT: (addressId)=>`/delivery_destinations/${addressId}/edit`,
    PRODUCERS_LAW: (producerId)=>`/producers/${producerId}/law`,
    // SPAのPhase1ではリリースしないが、機能としては残しておく画面が下記になる
    // 機能を追加する時はroutes/index.tsファイルにルーティングを追加すること
    POST_FORM: (producerId, productId)=>`/posts/new?producer=${producerId}${productId ? `&product=${productId}` : ''}`,
    PURCHASE: (productId, quantityId)=>`/products/${productId}/purchases/new?quantity_id=${quantityId}`,
    PURCHASE_CONFIRM: (productId, quantityId)=>`/products/${productId}/purchases/new/confirm?quantity_id=${quantityId}`,
    PURCHASE_COMPLETE: (productId)=>`/purchase_complete/${productId}`,
    ORDER_DETAIL: (orderId)=>`/orders/${orderId}`,
    ORDER_FEEDBACK: (orderId)=>`/orders/${orderId}/feedbacks/new`,
    SUBSCRIPTION_DETAIL: (subscriptionId)=>`/subscription_purchases/${subscriptionId}`,
    SUBSCRIPTION_EDIT_DELIVERY: (subscriptionId)=>`/subscription_purchases/${subscriptionId}/edit/delivery`,
    SUBSCRIPTION_EDIT_PAYMENT: (subscriptionId)=>`/subscription_purchases/${subscriptionId}/edit/payment`,
    SUBSCRIPTION_SKIP: (subscriptionId)=>`/subscription_purchases/${subscriptionId}/skip`,
    PURCHASE_CANCEL: (subscriptionId)=>`/subscription_purchases/${subscriptionId}/cancel`,
    MESSAGE_THREAD: (threadId, orderId)=>`/message/threads/${threadId}${orderId ? `?order_id=${orderId}` : ''}`,
    MESSAGE_THREAD_PRODUCT: (threadId, productId)=>`/message/threads/${threadId}?product_id=${productId}`,
    MESSAGE_INQUIRIES: (productId, orderId)=>productId ? `/message/inquiries/new?product_id=${productId}` : orderId ? `/message/inquiries/new?order_id=${orderId}` : '/message/inquiries/new',
    ANNOUNCEMENT_DETAIL: (announcementId)=>`/announcements/${announcementId}`,
    CATEGORY_DETAIL: (categoryId)=>`/categories/${categoryId}`
};
export const FURUSATO_ROUTER_PATH = {
    HOME: '/furusato',
    PRODUCT_LIST: '/furusato/products',
    CONTRIBUTION_LIST: '/furusato/contributions',
    CONTRIBUTION_DETAIL: '/furusato/contributions/:id',
    POINTS: '/furusato/points',
    SIMULATION: '/furusato/simulation',
    GUIDE: '/furusato/guide',
    ONE_STOP: '/furusato/onestop',
    PURCHASE: '/furusato/products/:productId/purchase/new',
    PURCHASE_CONTRIBUTION: '/furusato/products/:productId/purchase/new/contribution',
    PURCHASE_CONFIRM: '/furusato/products/:productId/purchase/new/confirm',
    PURCHASE_COMPLETE: '/furusato/purchase_complete/:productId'
};
export const FURUSATO_PARAM_PATH = {
    CONTRIBUTION_DETAIL: (contributionId)=>`/furusato/contributions/${contributionId}`,
    PURCHASE: (productId, quantityId)=>`/furusato/products/${productId}/purchase/new?quantity_id=${quantityId}`,
    PURCHASE_CONTRIBUTION: (productId, quantityId)=>`/furusato/products/${productId}/purchase/new/contribution?quantity_id=${quantityId}`,
    PURCHASE_CONFIRM: (productId, quantityId)=>`/furusato/products/${productId}/purchase/new/confirm?quantity_id=${quantityId}`,
    PURCHASE_COMPLETE: (productId)=>`/furusato/purchase_complete/${productId}`
};
/**
 * EC/ふるさと商品一覧のURLの共通パラメータを定義
 */ export const COMMON_SEARCH_KEY = {
    CATEGORY: 'category_id',
    SORT_ORDER: 'sort_order',
    WORDS: 'words',
    PREFECTURES: 'prefectures[]',
    LOWER_PRICE: 'lower_price',
    HIGHER_PRICE: 'higher_price',
    PAGE_NO: 'page_no',
    SCHEDULED_DELIVERIES: 'scheduled_deliveries[]'
};
/**
 * 商品一覧のURLのパラメータとして受け取る値を定義
 */ export const PRODUCT_LIST_PARAM = {
    SEARCH_KEY: {
        ...COMMON_SEARCH_KEY,
        TYPE: 'type',
        COUPONS: 'coupons[]'
    }
};
/**
 * ふるさと/ECの商品一覧の共通のAPIキー
 */ export const COMMON_API_KEY = {
    CATEGORIES: 'category_ids[]',
    TYPE: 'type',
    ORDER: 'order',
    TEXT: 'text',
    PREFECTURES: 'prefectures[]',
    SCHEDULED_DELIVERIES: 'scheduled_deliveries[]',
    PAGE: 'page',
    PER_PAGE: 'per_page',
    IGNORE_IDS: 'ignore_ids[]'
};
/**
 * 商品一覧のAPIキーとして使用する値を定義
 */ export const PRODUCT_LIST_API_KEY = {
    ...COMMON_API_KEY,
    PRICE_RANGE: 'range[]',
    COUPONS: 'coupons[]',
    USER_IDS: 'user_ids[]',
    IDS: 'ids[]',
    TAGS: 'tags[]',
    ONLY_ON_SALE: 'only_on_sale'
};
/**
 * ふるさとの商品一覧のURLのパラメータとして受け取る値を定義
 */ export const FURUSATO_PRODUCT_LIST_PARAM = {
    SEARCH_KEY: {
        ...COMMON_SEARCH_KEY,
        LOCAL_GOVERNMENTS: 'gov_ids'
    }
};
/**
 * ふるさとの商品一覧のAPIキーとして使用する値を定義
 */ export const FURUSATO_PRODUCT_LIST_API_KEY = {
    ...COMMON_API_KEY,
    LOCAL_GOVERNMENTS: 'furusato_local_government_ids',
    PRICE_RANGE: 'tax_price_for_total_range[]'
};
export const PRODUCER_DETAIL_HASH_KEY = {
    PRODUCT_LIST: 'products'
};
export const FURUSATO_GUIDE_HASH_KEY = {
    STEP1: '#step1',
    STEP2: '#step2',
    STEP3: '#step3'
};
/**
 * 生産者一覧ページのURLパラメータとして使用するキーを定義
 */ export const PRODUCER_LIST_PARAM = {
    SEARCH_KEY: {
        CATEGORY: 'category',
        WORDS: 'words',
        PREFECTURES: 'prefectures[]',
        PAGE_NO: 'page_no'
    }
};
/**
 * 生産者一覧のAPIのリクエストパラメータとして使用するキーを定義
 */ export const PRODUCER_API_KEY = {
    CATEGORY: 'category',
    WORDS: 'words',
    PREFECTURES: 'prefectures[]',
    PAGE_NO: 'page_no'
};
export { EXTERNAL_LINKS, FIXED_PAGE_PATH, RAILS_PARAM_PATH, RAILS_PATH } from './externalLink';
export { APP_LINKS } from './appLink';

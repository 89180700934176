import { FURUSATO_SET_PAYMENT_TAX } from './actions';
const initialState = {
    paymentTax: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case FURUSATO_SET_PAYMENT_TAX:
            return {
                ...state,
                paymentTax: action.payload.paymentTax
            };
        default:
            return state;
    }
}

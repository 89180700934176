import * as api from '~/services/api';
function resToPurchaserAddresses(res) {
    return res.map((data)=>{
        const { id, main, postalCode, state, city, address1, address2, firstName, firstNamePronunciation, lastName, lastNamePronunciation, phoneNumber, isUsedFromAnySubscriptions } = data;
        return {
            id: Number(id),
            main: main === null ? false : main,
            postalCode,
            state,
            city,
            address1,
            address2: address2 === null ? '' : address2,
            firstName,
            firstNamePronunciation,
            lastName,
            lastNamePronunciation,
            phoneNumber,
            isUsedFromAnySubscriptions
        };
    });
}
export async function fetchPurchaserAddresses() {
    const json = await api.get('v1/delivery_destinations');
    const res = await api.deserializeJsonApi(json);
    return resToPurchaserAddresses(res);
}

import { SIGN_OUT } from '../common/actions';
import { RESET_OTHER_ADDRESS_FOR_SENDING, SET_SELECTED_OTHER_ADDRESS_FOR_SENDING } from './actions';
const initialState = {
    selectedOtherAddressForSending: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_SELECTED_OTHER_ADDRESS_FOR_SENDING:
            return {
                ...state,
                selectedOtherAddressForSending: action.payload.selectedOtherAddressForSending
            };
        case RESET_OTHER_ADDRESS_FOR_SENDING:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

import { SIGN_OUT } from '../common/actions';
import { RESET_SUBSCRIPTION_DESTINATION, SET_SUBSCRIPTION_DESTINATION } from './actions';
const initialState = {
    selectedSubscriptionDestination: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_SUBSCRIPTION_DESTINATION:
            return {
                ...state,
                selectedSubscriptionDestination: action.payload.subscriptionDestination
            };
        case RESET_SUBSCRIPTION_DESTINATION:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

/**
 * オリジンが正しいかどうかを判定する
 * - 本番環境、ステージング環境の場合はBASE_URLと一致するかどうか
 * - 開発環境の場合はdev*.poke-m.com、またはvercel.appかどうか
 * - ローカル環境の場合はチェックしない
 * @param origin メッセージの送信元のオリジン
 * @returns オリジンが正しい場合はtrue、それ以外はfalse
 */ const validateOrigin = (origin)=>{
    if ((process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'staging') && origin === process.env.BASE_URL) {
        return true;
    }
    if (process.env.ENVIRONMENT === 'development' && /^(https:\/\/dev\d?\.poke-m\.com|https:\/\/.+vercel\.app)$/.test(origin)) {
        return true;
    }
    if (process.env.ENVIRONMENT === 'local') {
        return true;
    }
    return false;
};
/**
 * 3Dセキュアの認証処理をiframeで表示するためのクラス
 * @template MessageData 3Dセキュア認証成功時のデータ
 * @template Error 3Dセキュア認証失敗時のエラー
 */ export class TdsPortal {
    #containerId = '';
    #iframeId = '';
    #onSuccess;
    #onError;
    constructor(id){
        this.#iframeId = id;
        this.#containerId = `${this.#iframeId}-container`;
    }
    /**
   * iframeを表示するモーダルの要素を生成する
   * @param options iframeのオプション
   * @param onSuccess 3Dセキュア認証成功時のコールバック
   * @param onError 3Dセキュア認証失敗時のコールバック
   */ create(options, onSuccess, onError) {
        this.#onSuccess = onSuccess;
        this.#onError = onError;
        const { url } = options;
        this._createElement(url);
        this._onMessage = this._onMessage.bind(this);
        // iframeからのメッセージを受信するイベントリスナーを設定
        window.addEventListener('message', this._onMessage, false);
    }
    /**
   * モーダルを非表示にし、callbackを実行する
   */ _hideModal(callback) {
        const iframe = document.getElementById(this.#iframeId);
        if (iframe) {
            const handleTransitionEnd = ()=>{
                callback();
                iframe.removeEventListener('transitionend', handleTransitionEnd);
            };
            iframe.addEventListener('transitionend', handleTransitionEnd);
            iframe.classList.remove('tds-portal-iframe--show');
        }
    }
    /**
   * モーダルとコンテナを破棄する
   */ destroy() {
        const container = document.getElementById(this.#containerId);
        if (container) {
            window.removeEventListener('message', this._onMessage);
            document.body.removeChild(container);
        }
    }
    /**
   * iframe及びモーダルコンテナを生成し、bodyに追加する
   * @param id 生成するiframeのid
   * @param url 生成するiframeのURL
   */ _createElement(url) {
        // 既存のモーダルiframeをチェック
        if (document.getElementById(this.#containerId)) {
            console.warn(`Modal iframe with id "${this.#containerId}" already exists. Skipping creation.`);
            return;
        }
        // モーダルコンテナを作成
        const modalContainer = document.createElement('div');
        modalContainer.id = this.#containerId;
        modalContainer.className = 'tds-portal-container';
        Object.assign(modalContainer.style, {
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1000',
            backdropFilter: 'blur(4px)'
        });
        // iframe要素を作成
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.id = this.#iframeId;
        iframe.className = 'tds-portal-iframe';
        Object.assign(iframe.style, {
            width: '95%',
            maxWidth: '600px',
            height: '88%',
            borderRadius: '4px',
            backgroundColor: '#fff',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            transform: 'scale(0.95)',
            opacity: '0',
            transition: 'transform 0.2s, opacity 0.2s'
        });
        modalContainer.appendChild(iframe);
        // モーダルコンテナをDOMに追加
        document.body.appendChild(modalContainer);
        setTimeout(()=>{
            Object.assign(iframe.style, {
                transform: 'scale(1)',
                opacity: '1'
            });
        }, 0);
    }
    /**
   * iframeからのメッセージを受信する
   */ _onMessage(e) {
        // 3Dセキュアの認証処理以外のメッセージは無視する
        if (e.data.key !== 'tds-portal') {
            return;
        }
        if (e.origin && !validateOrigin(e.origin)) {
            throw new Error('Invalid origin');
        }
        const { event, data, error } = e.data;
        if (event === 'success') {
            this._hideModal(()=>this.destroy());
            this.#onSuccess?.(data);
        } else if (event === 'error') {
            this._hideModal(()=>this.destroy());
            if (error) {
                this.#onError?.(error);
            }
        }
    }
}
/**
 * メッセージを送信する
 */ const sendMessage = (_data)=>{
    // 自身がiframe内で表示されているかどうか
    if (window.self == window.top) {
        throw new Error('This page is not displayed in iframe');
    }
    if (!window.parent) {
        throw new Error('window.parent is not found');
    }
    window.parent.postMessage(_data, '*');
};
/**
 * 認証成功時のメッセージを送信する
 */ export const sendSuccessMessages = (data)=>{
    const _data = {
        key: 'tds-portal',
        event: 'success',
        data
    };
    sendMessage(_data);
};
export const sendErrorMessage = (error)=>{
    const _data = {
        key: 'tds-portal',
        event: 'error',
        data: undefined,
        error
    };
    sendMessage(_data);
};

const password = {
    currentPassword: '現在のパスワード',
    newPassword: '新しいパスワード',
    newPasswordConfirmation: '確認用パスワード',
    PasswordEdit: {
        title: 'パスワード変更',
        buttonLabel: '変更を保存する'
    },
    ResetPassword: {
        title: 'パスワードの再発行',
        email: 'メールアドレス',
        buttonLabel: '送信'
    },
    ForgotPassword: {
        title: 'パスワードの再設定',
        newPassword: '新しいパスワード（８文字以上）',
        buttonLabel: '変更'
    },
    validation: {
        invalidInput: '入力に誤りがあります',
        errorPattern: '半角英数字8文字以上で入力してください',
        differentNewPassword: '確認用パスワードと一致しません',
        different: '新しいパスワードと一致しません'
    }
};
export default password;

const isInitialized = (state)=>state.user.isInitialized;
const isLikedProduct = (id)=>(state)=>{
        return state.user.likedProducts.some((_id)=>id === _id);
    };
const likedProductIds = (state)=>state.user.likedProducts;
const me = (state)=>state.user.me;
const oAuth = (state)=>state.user.oAuth;
export default {
    isInitialized,
    isLikedProduct,
    likedProductIds,
    me,
    oAuth
};

import { useEffect } from 'react';
import { useMeta } from './meta';
export const useDocumentTitle = (documentTitle)=>{
    const { createDocumentTitle } = useMeta();
    useEffect(()=>{
        createDocumentTitle(documentTitle);
    }, [
        createDocumentTitle,
        documentTitle
    ]);
};

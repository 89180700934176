/* eslint-disable @typescript-eslint/naming-convention */ import { getFirstCategoryName } from '../../_shared/utils';
import postMessageToIOS from '../postMessageToIOS/postMessageToIOS';
/**
 * iOS向けにcartイベントを送信する (EC/ふるさと共通)
 */ const sendCart = ({ purchaseId, product, price, prefecture, hasFurusatoPurchase })=>{
    const { rootCategoryName, leafCategoryName } = getFirstCategoryName(product.categories);
    const data = {
        purchase_id: purchaseId,
        product_id: product.id.toString(),
        title: product.title,
        category: rootCategoryName,
        category2: leafCategoryName,
        tags: product.tags,
        price,
        producer_id: product.user.id.toString(),
        prefecture,
        has_furusato_purchase: hasFurusatoPurchase
    };
    postMessageToIOS({
        topic: 'cart',
        data
    });
};
export default sendCart;

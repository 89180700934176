const breadcrumb = {
    top: '産直・通販のポケマル',
    producers: '生産者一覧',
    categories: 'カテゴリからさがす',
    categoryDetail: '{{categoryName}}の産地直送 商品',
    producerPrefecture: '{{prefectureLabel}}の生産者',
    post: '投稿',
    posts: 'みんなの投稿',
    producerPosts: '{{producerName}}さんの投稿',
    messageBox: 'メッセージ一覧',
    messageInquiry: 'メッセージ',
    login: 'ログイン',
    resetPassword: 'パスワードの再発行',
    forgotPassword: 'パスワードの再設定',
    signup: '新規登録',
    signupTemporaryComplete: 'メールアドレスの確認',
    changePassword: 'パスワード変更',
    myPage: 'マイページ',
    profile: 'プロフィール編集',
    leaveMembership: '退会',
    orders: '注文履歴（発送予定・発送済み）',
    order: '注文内容',
    subscriptions: '注文履歴（定期・予約の管理）',
    subscription: '定期・予約注文内容',
    subscriptionSkip: '定期スキップ',
    subscriptionCancel: '定期・予約キャンセル',
    followingCommunities: 'フォロー生産者',
    likedProducts: 'お気に入り一覧',
    mailNotificationSetting: 'メール通知設定',
    sharing: '友達にポケマルを紹介する',
    stories: '読みもの一覧',
    announcements: 'お知らせ一覧',
    specifiedCommercialLaw: '特定商取引法に基づく表記',
    furusato: {
        top: 'ふるさと納税',
        contributions: '納税履歴',
        contributionDetail: '納税履歴詳細',
        points: 'ポイント残高',
        simulation: 'ふるさと納税控除上限額シミュレーター',
        guide: 'ふるさと納税とは？'
    }
};
export default breadcrumb;

// 2つの文字列のLCP(Longest Common Prefix)を取得する
//  { 'こんにちは', 'こんばんは'} => { lcp: 'こん' }
export const getLCP = (str1, str2)=>{
    let lcp = '';
    for(let i = 0; i < Math.min(str1.length, str2.length); i++){
        if (str1[i] === str2[i]) {
            lcp += str1[i];
        } else {
            break;
        }
    }
    return lcp;
};
// 文字列を部分文字列に分解する
// ヒット位置を返すため、文字列の各文字に対して、文字列とそのインデックスを返す
// 'こんにちは' => [['こんにちは', 0], ['んにちは',1], ['にちは',2], ['ちは',3], ['は',4]]
export const splitString = (str)=>{
    const result = [];
    for(let i = 0; i < str.length; i++){
        result.push([
            str.slice(i),
            i
        ]);
    }
    return result;
};
/**
 * ノードを走査し、ノードに含まれている値を取得する
 */ export const traverse = (node)=>{
    const matchedIDs = [];
    const stack = [
        node
    ];
    while(stack.length > 0){
        const node = stack.shift();
        if (!node) {
            continue;
        }
        if (node.value) {
            for (const val of node.value){
                matchedIDs.push(val);
            }
        }
        stack.push(...Object.values(node.children));
    }
    return matchedIDs;
};

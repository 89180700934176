import TagManager from 'react-gtm-module';
import { getFirstCategoryName } from '../../_shared/utils';
/**
 * GTM(Karte)へkarte_cartイベントを送信する
 * @param product EC/ふるさと納税の商品データ
 * @param purchaseId 仮購入ID
 * @param hasFurusatoPurchase  ふるさと納税の購入か
 */ const sendKarteCart = (product, price, purchaseId, hasFurusatoPurchase)=>{
    const { rootCategoryName, leafCategoryName } = getFirstCategoryName(product.categories);
    const item = {
        /* eslint-disable @typescript-eslint/naming-convention */ product_id: product.id,
        title: product.title,
        category: rootCategoryName,
        category2: leafCategoryName,
        price,
        url: `${window.location.origin}${hasFurusatoPurchase ? '/furusato' : ''}/products/${product.id}`,
        image_url: product.images?.[0],
        producer_id: product.user.id,
        prefecture: product.user.address
    };
    const cartItem = {
        /* eslint-disable @typescript-eslint/naming-convention */ purchase_id: purchaseId,
        has_furusato_purchase: hasFurusatoPurchase,
        items: item
    };
    // GTMで送る
    const dataLayer = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'karte-sendCart',
            cart: cartItem
        }
    };
    TagManager.dataLayer(dataLayer);
};
export default sendKarteCart;

import * as yup from 'yup';
import i18n from '~/locale';
const { t } = i18n;
// 商品の納税先都道府県と購入者の都道府県が同一の購入ができない都道府県名のリスト
// TODO: ハードコードせずAPIから取得するようにしたい
const RESTRICTED_STATES = [
    {
        code: '030007',
        name: '岩手県'
    }
];
/**
 * 商品の納税先都道府県と購入者の都道府県が同一の購入ができない都道府県の場合、バリデーションを設定するスキーマを返す
 */ export const buildContributionStateRestrictionForPurchaseFormSchema = (donateeStateCode)=>{
    // 商品の納税先都道府県と購入者の都道府県が同一の購入ができない都道府県名のケースに絞る
    const donateeState = RESTRICTED_STATES.find((state)=>state.code === donateeStateCode)?.name;
    if (donateeState) {
        return yup.object().shape({
            state: yup.string().test({
                name: 'is-donatee-and-customer-both-same-state-and-it-restricted',
                test: (customerState)=>{
                    return donateeState !== customerState;
                },
                message: t('validation:validate.restrictedPrefectureForPurchase', {
                    prefecture: donateeState
                })
            })
        });
    }
    // 上記の条件に当てはまらない場合は、バリデーションなしのスキーマを返す
    return yup.object().shape({});
};

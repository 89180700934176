import { ROUTER_PATH } from 'ec-constants/routes';
import { useLocation, useNavigate } from 'react-router';
export function useAddressEditParams(deliverTo) {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const addressId = state?.addressId || null;
    const linkWithFrom = state?.linkWithFrom || null;
    const pushToAddressList = ()=>{
        const params = new URLSearchParams();
        if (deliverTo) {
            params.append('deliver_to', deliverTo);
        }
        navigate(`${ROUTER_PATH.PURCHASE_ADDRESS}?${params}`, {
            replace: true,
            state: {
                selectedAddressId: addressId,
                linkWithFrom
            }
        });
    };
    return {
        pushToAddressList
    };
}

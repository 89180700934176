const isDefinedErrorResponse = (/* eslint-disable @typescript-eslint/no-explicit-any */ e)=>{
    if (e.response === undefined) {
        return false;
    }
    if (e.response.data.errors === undefined) {
        return false;
    }
    if (!e.response.data.errors.length) {
        return false;
    }
    if (e.response.data.errors[0].code === undefined) {
        return false;
    }
    return true;
};
export const handleStandardApiError = (e)=>{
    const isTypeOfErrorResponse = isDefinedErrorResponse(e);
    if (e.response && isTypeOfErrorResponse) {
        const errors = e.response.data.errors;
        return {
            statusCode: e.response.status,
            errorCode: errors[0].code,
            meta: {
                params: errors[0].meta?.params,
                validations: errors[0].meta?.validations,
                gmopg: errors[0].meta?.gmopg
            }
        };
    }
    return {
        statusCode: e.response ? e.response.status : null,
        errorCode: null,
        meta: {
            params: undefined,
            validations: undefined,
            gmopg: undefined
        }
    };
};

export const SET_SELECTED_OTHER_ADDRESS_FOR_SENDING = 'otherAddressForSending/SET_SELECTED_OTHER_ADDRESS_FOR_SENDING';
export const setSelectedOtherAddressForSending = (selectedOtherAddressForSending)=>({
        type: SET_SELECTED_OTHER_ADDRESS_FOR_SENDING,
        payload: {
            selectedOtherAddressForSending
        }
    });
export const RESET_OTHER_ADDRESS_FOR_SENDING = 'otherAddressForSending/RESET_OTHER_ADDRESS_FOR_SENDING';
export const resetOtherAddressForSending = ()=>({
        type: RESET_OTHER_ADDRESS_FOR_SENDING
    });
export default {
    setSelectedOtherAddressForSending,
    resetOtherAddressForSending
};

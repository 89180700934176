export const creditCardRegExp = ()=>{
    const holderNameRegExp = /^[A-Z][A-Z ]+[A-Z]$/;
    const cardNumberRegExp = /^[0-9]{14,16}$/;
    const securityCodeRegExp = /^[0-9]{3,4}$/;
    return {
        holderNameRegExp,
        cardNumberRegExp,
        securityCodeRegExp
    };
};
export const creditCardBrand = (cardNumber)=>{
    const cardBrands = [
        {
            name: 'jcb',
            regExp: /^35(28|29|[3-8]\d)\d{12}$/
        },
        {
            name: 'mastercard',
            regExp: /^(5[1-5]\d{4}|677189|222[1-9]\d{2}|22[3-9]\d{3}|2[3-6]\d{4}|27[01]\d{3}|2720\d{2})\d{10}$/
        },
        {
            name: 'visa',
            regExp: /^4\d{12}(\d{3})?(\d{3})?$/
        },
        {
            name: 'diners_club',
            regExp: /^3(0[0-5]|[68]\d)\d{11}$/
        },
        {
            name: 'american_express',
            regExp: /^3[47]\d{13}$/
        },
        {
            name: 'maestro',
            regExp: /^(5[06-8]|6\d)\d{10,17}$/
        },
        {
            name: 'discover',
            regExp: /^6011[0-9]{12}$/
        }
    ];
    const getCreditCardBrand = ()=>{
        const targetedCardBrand = cardBrands.reduce((accumulator, brand)=>{
            return brand.regExp.test(cardNumber) ? brand : accumulator;
        });
        return targetedCardBrand.name;
    };
    return {
        getCreditCardBrand
    };
};
/**
 * (開発環境でのみ)登録できるカードかどうかを判定する
 */ export const isVerificationCard = (securityCode)=>{
    return securityCode === '999';
};

import useSWR from 'swr';
import { fetchCategories, getCategories, getRootCategories, swrFetcher } from '~/repositories/categories';
/**
 * カテゴリ一覧を取得する
 * NestedSet形式のカテゴリデータが返却される
 */ export function useFetchCategories({ active } = {
    active: true
}) {
    const { data, error } = useSWR(active ? getCategories() : null, swrFetcher);
    return {
        categories: data,
        error,
        loading: !data
    };
}
/**
 * ルートカテゴリのみのカテゴリ一覧を取得する
 * 通常のリスト形式のカテゴリデータが返却される
 */ export function useFetchRootCategories() {
    const { data, error, isLoading } = useSWR(getRootCategories(), fetchCategories);
    return {
        categories: data,
        error,
        isLoading
    };
}

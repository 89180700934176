import { useEffect, useRef, useState } from 'react';
const tokenScriptUrl = process.env.GMO_TOKEN_SCRIPT_URL;
export function useGmoPG(shopId) {
    const [isReady, setIsReady] = useState(false);
    const scriptRef = useRef(document.createElement('script'));
    useEffect(()=>{
        const script = scriptRef.current;
        if (!script) {
            return;
        }
        const scriptId = 'gmo-poyment-gateway-script-tag';
        if (document.getElementById(scriptId)) {
            // scriptタグが既にある場合はreadyであると見なす
            setIsReady(true);
        } else {
            // bodyにscriptタグを挿入
            script.id = scriptId;
            script.src = tokenScriptUrl || '';
            script.addEventListener('load', ()=>{
                // script読み込み完了時にreadyにする
                setIsReady(true);
            });
            document.body.appendChild(script);
        }
    }, []);
    const getToken = (cardData)=>{
        return new Promise((resolve, reject)=>{
            if (!isReady) {
                return reject(new Error('GMO-PG is not loaded yet'));
            }
            const { Multipayment } = window;
            const tokenSuccessedCode = '000';
            Multipayment.init(shopId);
            Multipayment.getToken(cardData, (res)=>{
                if (res.resultCode !== tokenSuccessedCode) {
                    return reject({
                        errorCode: res.resultCode
                    });
                }
                resolve(res.tokenObject);
            });
        });
    };
    return {
        isReady,
        getToken
    };
}

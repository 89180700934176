import { ROUTER_PATH } from 'ec-constants/routes';
/**
 * お気に入り一覧ページへのパスを生成する
 */ export const buildLikedProductsPath = ({ onlySalable, onlyFurusato, page, ref })=>{
    const searchParams = new URLSearchParams();
    if (ref === 'header') {
        searchParams.append('ref', 'header');
    } else if (ref === 'sideNav') {
        searchParams.append('ref', 'sideNav');
    }
    if (onlySalable) {
        searchParams.append('state', 'salable');
    }
    if (onlyFurusato) {
        searchParams.append('site', 'furusato');
    }
    if (page && page > 1) {
        searchParams.append('page_no', page.toString());
    }
    return `${ROUTER_PATH.LIKED_PRODUCT_LIST}?${searchParams.toString()}`;
};

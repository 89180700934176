import * as api from '~/services/api';
function resToUser(res) {
    return {
        id: Number(res.id),
        email: res.email || '',
        type: res.type || 'Consumer',
        name: res.name,
        avatarImageUrl: res.avatarImageUrl,
        acceptEmailInfo: res.acceptEmailInfo || false,
        registeredAt: res.registeredAt || '',
        firstPurchasedAt: res.firstPurchasedAt || '',
        lastPurchasedAt: res.lastPurchasedAt || '',
        purchaseCount: res.purchaseCount || 0,
        signInCount: res.signInCount || 0,
        prefecture: res.prefecture,
        subscribeNewOfficialPost: res.subscribeNewOfficialPost
    };
}
export async function fetchMe() {
    const json = await api.get('v1/users/me', {
        hasCustomActionOnNotAuthorized: true
    });
    const res = await api.deserializeJsonApi(json);
    return resToUser(res);
}
export async function deleteMe() {
    return await api.del('v1/users/me');
}
function createUserWithApple(params) {
    const { name, acceptEmailInfo, returnPath } = params;
    return api.post('v1/users/apple', {
        /* eslint-disable @typescript-eslint/naming-convention */ user: {
            name,
            accept_email_info: acceptEmailInfo,
            return_path: returnPath
        }
    });
}
function createUserWithLine(params) {
    const { name, acceptEmailInfo } = params;
    return api.post('v1/users/line', {
        /* eslint-disable @typescript-eslint/naming-convention */ user: {
            name,
            accept_email_info: acceptEmailInfo
        }
    });
}
export async function createUser(params) {
    const { email, name, password, acceptEmailInfo, returnPath } = params;
    return api.post('v1/users', {
        user: {
            email,
            name,
            password,
            /* eslint-disable @typescript-eslint/naming-convention */ accept_email_info: acceptEmailInfo,
            return_path: returnPath
        }
    });
}
export async function createUserWithOauth(params) {
    const { name, acceptEmailInfo, returnPath, authorizedProvider } = params;
    if (authorizedProvider === 'apple') {
        return createUserWithApple({
            name,
            acceptEmailInfo,
            returnPath: returnPath || ''
        });
    } else if (authorizedProvider === 'line') {
        return createUserWithLine({
            name,
            acceptEmailInfo
        });
    }
}
export async function confirmUser(token) {
    return api.get(`v1/users/confirmation?confirmation_token=${token}`);
}
export async function patchMe(email, name, acceptEmailInfo, removeAvatar, avatar) {
    const formData = new FormData();
    if (avatar) {
        formData.append('avatar', avatar);
    }
    formData.append('remove_avatar', String(removeAvatar));
    formData.append('email', email);
    formData.append('name', name);
    formData.append('accept_email_info', String(acceptEmailInfo));
    const updatedMe = await api.patch('v1/users/me', formData, api.multiPartFormHeaderConfig);
    const res = await api.deserializeJsonApi(updatedMe);
    return resToUser(res);
}
export async function getLikedProducts() {
    const json = await api.get('v1/liked_products/all_ids');
    const res = await api.deserializeJsonApi(json);
    const ids = Array.from(res.productIds);
    return ids;
}
export async function putPassword(token, password) {
    return api.put('v1/users/forgot_password', {
        user: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            reset_password_token: token,
            password
        }
    });
}

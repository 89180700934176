const purchase = {
    PurchaseInProgress: {
        title: '注文手続き',
        backToProduct: '商品詳細へ戻る',
        OrderConfirmLabel: '注文内容を確認する'
    },
    PurchaseConfirm: {
        title: '注文内容の確認',
        caution: '※注文はまだ確定していません。内容をご確認の上、画面下の「注文を確定する」を押してください。​修正する場合は「注文手続きに戻る」を押して前のページに戻ってください。',
        backToInProgress: '注文手続きに戻る',
        SubmitPurchaseButton: '注文を確定する',
        PurchaseLoadingLabel: '購入処理中です。画面を閉じないでください'
    },
    PurchaseCompleted: {
        Menu: {
            title: 'お買い上げありがとうございます\n発送までしばらくお待ちください',
            description: '<0>{{email}}</0> に注文完了メールをお送りしました。生産者からの発送をお待ちください。',
            descriptionOneTimeProduct: '配送状況はマイページの「注文履歴」よりご確認いただけます。',
            descriptionSubscription: '定期購入中・予約中の商品はマイページの「定期・予約の管理」よりご確認いただけます。',
            backToTop: 'トップへ戻る',
            toOrders: '注文履歴で確認する',
            toSubscription: '定期・予約の商品を確認する',
            furusato: {
                title: 'ご注文ありがとうございます',
                description: '<0>{{mail}}</0> に注文完了メールをお送りしました。\n今回のふるさと納税の税控除を受けるためには、必ず下記いずれかの手続きが必要です。\n手続きをしないと税控除が受けられず全額自己負担になってしまいますのでご注意ください。',
                backToTop: 'トップへ戻る',
                toContributionList: '納税履歴で確認する',
                oneStop: 'ワンストップ特例申請',
                oneStopText: '確定申告を行わない方におすすめです。\n注文画面で申請書の送付を希望するにした場合は、自治体から申請書が後日届きますので、必要事項を記入し、必ず1/10必着で郵送してください。\n希望するを選択し忘れた場合でも、<0>納税履歴</0>より、該当の履歴ページから申請書がダウンロードできます。印刷してご活用ください。',
                taxReturn: '確定申告',
                taxReturnText: '確定申告が必要な方は、自治体から後日届く寄付証明書を確定申告時に添付して、申告が必要です。',
                detailLink: '詳しい説明ページへ'
            }
        },
        RecommendedProductsTitle: 'こんな商品もおすすめ'
    },
    // 注文者情報
    PurchaserAddressInfo: {
        title: '注文者情報',
        noAddressSelected: '住所が選択されていません',
        addressChange: '注文者情報を変更する',
        noneRegisration: '情報が登録されていません',
        chooseOrRegist: '住所を選択・登録する'
    },
    // お届け先情報
    DeliveryDestination: {
        title: 'お届け先',
        confirmedTitle: 'お届け先情報',
        asHome: '注文者と同じ',
        asGift: '自分以外に送る',
        chooseOrRegist: '住所を選択・登録する',
        change: '変更する',
        caution: '注文者情報を先に選択してください',
        addressChange: 'お届け先情報変更・登録'
    },
    // お支払い方法
    PaymentMethod: {
        title: 'お支払い方法',
        // 後払い（つど払い）
        atonePostPayment: {
            caption: '登録なし・SMS認証ですぐに注文完了',
            caution: `注文が完了してから、コンビニ・銀行ATMで支払いできる安心・簡単な「後払い」です。
      携帯電話番号とメールアドレスのみでご利用いただけます。
      後払い手数料は209円（税込）です。

      ■お支払いの流れ
      ・注文確定の翌日に、メールとSMSでお支払いに必要な番号をお知らせします。コンビニまたは銀行ATM (Pay-easy) で10日後までにお支払いください。

      ■ご注意
      ・代金は株式会社ネットプロテクションズの<0>利用規約</0>に基づき、指定の方法で請求いたします。
      ・詳細は、<1>公式ページ</1>をご覧ください。`
        },
        // atone 翌月払い
        atone: {
            caption: '今月の利用分をまとめて翌月にお支払い',
            promotionNow: 'キャンペーン中',
            cautionLead: `atone (アトネ) は誰でもすぐに使える翌月払いです。今月のご利用分をまとめて、翌月にコンビニまたは口座振替でお支払いいただけます。

                200円で1ポイント、atoneのお買い物に使えるお得なNPポイントが貯まります。詳細は <0>atone の公式ページ</0> をご覧ください。`,
            cautionNote: `■お支払い方法
                お支払いに必要な番号を、お買い物の翌月初旬にメールでご案内いたします。Loppiなどのコンビニ端末、または銀行ATM (Pay-easy) で、翌月10日までにお支払いください。
                お支払い方法は以下4つから選べます。スマホアプリまたはWebマイページから、月末までにお手続きください。

                (1) コンビニ端末 / 銀行ATM (Pay-easy) ※標準設定
                (2) 電子バーコード (コンビニ)
                (3) はがき請求書 (コンビニ)
                (4) 口座振替
                ※コンビニ端末 / 銀行ATM (Pay-easy) は、セブンイレブンではお支払いいただけません。
                ※電子バーコード・はがき請求書は、セブンイレブンでもお支払いいただけます。

                ■注意事項
                ・決済手数料は無料です。
                ・ご利用月のみ請求手数料209円(税込)が発生いたします。 (口座振替の場合は無料)
                ・支払期限日は支払い方法によって異なります。
                ・代金は株式会社ネットプロテクションズの<0>会員規約</0>に基づき指定の方法で請求いたします。`
        },
        // TODO: subscriptionと共通なのでここじゃないかも?? 'global.common.paymentMethod.creditCard'とか
        creditCard: {
            expired: '有効期限切れ',
            edit: 'カードを変更・登録',
            register: 'クレジットカードを登録'
        },
        secureMessage: {
            text: '安全にクレジットカード決済をご利用いただくために、本人認証サービス（3Dセキュア2.0）を導入しています。「3Dセキュア2.0」をご利用いただくためには、カード発行会社のウェブサイトにおいてあらかじめ設定が必要な場合がございます。',
            linkText: '詳細はこちら'
        },
        creditCardIsEmpty: 'クレジットカードが設定されていません',
        furusato: {
            description: {
                main: 'ポケットマルシェのふるさと納税は、返礼品として各自治体のポイントをお渡しし、そのポイントで商品を注文いただく「ポイント制」です。',
                none: 'ポイントは1,000円納税するごとに300ポイント獲得できますので、この商品は<0>{{amountOfTaxRequired}}円納税</0>をし<0>{{numberOfPointsRequired}}ポイント獲得</0>することで注文できます。',
                part: '<0>お客様は既に{{pocketPointName}}ポイント{{pointBalance}}ポイント、納税額{{paidTax}}円相当をお持ちなので、本商品を購入するための不足分{{numberOfPointsRequired}}ポイント、{{amountOfTaxRequired}}円の追加納税が必要となります。</0>',
                full: '<0>お客様は既に{{pocketPointName}}ポイント{{pointBalance}}ポイントをお持ちなので、新たに寄付をする必要はありません。</0>'
            },
            contribution: '① {{localGovernmentName}}に{{amountOfTaxRequired}}円ふるさと納税をする',
            canUse: '{{localGovernmentName}}の生産物に使える',
            getPoint: '{{point}}ポイント獲得',
            order: '② {{point}}ポイントを使って商品を注文',
            furusatoPointUnit: 'ふるさとポイント',
            pointUnit: 'ポイント',
            apply: {
                new: '今回獲得分のポイントを自動適用：',
                old: '既に保有している分のポイントを適用：',
                balance: '購入後の残高：'
            },
            question: 'なぜポイントでの注文が必要なのですか'
        }
    },
    // クーポンセクション
    Coupon: {
        title: 'クーポンを適用する',
        otherApply: 'クーポンコードを入力し直す',
        CouponInputText: {
            notFound: '入力したクーポンは存在しません',
            serverError: '通信中にエラーが発生しました',
            outOfPeriod: '入力したクーポンは利用期間外です',
            exceedArrivalCount: '入力したクーポンは先着限定数を超えています',
            exceedMaxCount: '入力したクーポンは利用回数を超えています',
            notApplicableUser: '入力したクーポンは対象ユーザーではありません',
            disablePayment: '入力したクーポンでは使用できない支払い方法です',
            unusableWithFreeDelivery: '入力したクーポンは送料無料商品には適用できません',
            exceedBudgetLimit: '入力したクーポンは現在ご利用いただけません',
            placeholder: 'クーポンコードを入力',
            applied: 'クーポンが適用されました'
        },
        notApplicableReason: {
            isNotOneTimeProduct: '定期・予約商品ではクーポンはご利用いただけません',
            hasAutoCoupon: '割引済み商品のためクーポンはご利用いただけません',
            furusato: 'ふるさと納税ではクーポンはご利用いただけません'
        },
        ApplyButtonLabel: '適用'
    },
    // 見積もりセクション
    EstimationPriceDisplay: {
        title: 'ご請求内容',
        appliedCoupon: {
            amountDiscount: '割引対象商品です。（※クーポン利用不可）',
            rateDiscount: '割引対象商品です。（※クーポン利用不可）',
            freeShipping: '送料無料対象商品です。（※クーポン利用不可）',
            discountDeliveryCost: '送料割引対象商品です。（※クーポン利用不可）'
        },
        noEstimated: 'お支払い方法と配送先住所が正しく入力されていることをご確認ください。',
        noAvailableReason: 'ご請求内容の計算が完了できません。\n【理由】この商品は生産者さんが鮮度などを考慮し、遠方エリアへの配送を難しいと判断しているため、ご希望の配送先へはお届けができません。詳しくは生産者さんにお問い合わせください。',
        noAvailableReasonColdDelivery: 'ご指定のお届け先では、クール便の注文を承ることができません。',
        furusato: {
            price: '{{localGovernmentName}}へのふるさと納税額',
            getPoint: '獲得{{pocketPointName}}',
            totalDeliveryRate: '送料',
            taxPaymentSubtotal: 'ふるさと納税額小計',
            nowPointName: '今回獲得分{{pocketPointName}}',
            usePointName: '保有分{{pocketPointName}}',
            subtotal: '商品小計',
            totalAmount: 'ご請求額',
            pointUnit: 'ポイント',
            plus: '+{{point}}ポイント',
            minus: '-{{point}}ポイント',
            noEstimated: 'お支払い方法と配送先住所を登録の上、購入手続きに進んでください。',
            minimumAmountError: 'お客様は、別商品のキャンセルに伴い返金されたポイントを保有しているため、自治体が定めたルールに基づき、本商品の注文をすることができません。<br/>カスタマーサービスにて対応いたしますので、お手数ではございますが、<0>こちら</0>よりお問い合わせください。'
        }
    },
    // 配送オプション
    DeliveryOption: {
        title: '配送オプション',
        subTitle: '配送方法',
        yamatoDeliveryDateTitle: 'お届け予定日',
        deliveryFirstTime: '初回の',
        deliveryDate: 'お届け日',
        shippingDate: '発送予定日',
        noSelected: '指定なし',
        notAllow: '選択できません',
        notSelectable: 'ご指定いただけません',
        betweenShippingDate: '指定しない場合は{{deliveryDate}}',
        deliveryFrequencyTitle: '配送頻度',
        deliveryDateCautions: {
            noSelectableDate: '※この商品は、お届け日を指定できません。',
            noSelectableDateSubscription: '※定期商品では、お届け日を指定できません。',
            noSelectableDateReservedSubscription: '※予約商品では、お届け日を指定できません。'
        },
        deliveryTime: 'お届け時間帯',
        cautions: {
            contactToProducer: '※この商品は、お届け日を指定できません。希望がある場合は必ず注文前に生産者にお問い合わせください。',
            coupon: '「{{tagName}}」でお届け日指定が可能な商品は、{{formattedEndDate}}までの指定が可能です。選択肢が表示されない場合は、「選択できません」のままでご注文ください。',
            deliveryArea: '※この商品は配送エリアの問題で午前中の指定を承れません。'
        }
    },
    // 生産者への申し送り事項
    MessageToProducer: {
        title: '生産者への申し送り事項',
        cautionMessage: '※申し送り事項で日時指定を承ることはできません。'
    },
    alcoholAgeVerification: {
        title: '年齢確認',
        caution: '20歳未満の方へのお酒の販売はいたしません。20歳以上であることを確認するため、チェックを入れてください。',
        fieldLabel: '20歳以上です',
        attachedMessage: '年齢：20歳以上チェックあり',
        required: 'チェックを入れてください'
    },
    SubscriptionProductCaution: {
        common: '※定期購入は購入者または生産者がキャンセルをするまで継続します。生産者の都合で終了する場合は生産者よりお知らせします。',
        monthly: '※配送月の前月25日に次回配送分の確定と決済が行われます。確定前の配送分は注文履歴からキャンセルすることができます。',
        weekly: '※この商品は毎週{{day}}曜日に次回配送分の確定と決済が行われます。確定前の配送分は注文履歴からキャンセルすることができます。'
    },
    Cautions: {
        couponNote: '商品割引クーポンをご利用の場合は商品価格に対して割引が適用されます。送料クーポンをご利用の場合は、送料＋クール代に対して割引が適用されます。',
        deliveryCostCaution: '配送先エリアによって送料は異なります。必ず金額をご確認ください。',
        notAllowedCancel: '注文後のお客様都合によるキャンセル・返品はできません',
        wrapNote: 'まとめての発送をご希望の場合は、必ず注文前に生産者さんへお問い合わせください。注文確定後の同梱対応はできません。<0>（お問い合わせ方法）</0>',
        deliveryDateCaution: '商品や生産現場の状況などによりお届け日時のご要望にお応えできない場合がございます。',
        furusatoDeliveryDateCaution: '商品や生産現場の状況などにより、お届け日時のご要望や、お届け予定日に応えられない場合がございます。',
        alcoholCaution: '【酒類をご購入の方へ】20歳未満の者に対しては酒類の販売ができかねますのでご了承ください。'
    },
    // TODO: ↓ここらへんも別のドメインに移動するかも
    invoice: {
        productPriceWithStandardTax: '商品価格(税込 - 10%)',
        subscriptionUnit: '/配送毎',
        productPriceWithTax: '商品価格(税込 - 軽税8%)',
        totalDeliveryRate: '送料',
        temperatureControlRate: 'クール代',
        paymentFee: '決済手数料',
        discountAmount: '割引額',
        totalPrice: '合計',
        totalAmount: 'ご請求額',
        reducedTaxTotalAmount: '8%対象合計',
        standardTaxTotalAmount: '10%対象合計',
        hasAmountChangeWarning: '部分的な返金が実施されたため、ご請求内容に変更があります。'
    },
    /**
   * ふるさと納税
   */ furusato: {
        Navigations: {
            order: '1. 注文手続き',
            contribution: '2. 納税者情報入力',
            confirm: '確認画面'
        },
        OneStop: {
            title: 'ワンストップ特例申請書の送付',
            no: '希望しない',
            yes: '希望する',
            descriptionTitle: 'ワンストップ特例制度とは？',
            descriptionBody: 'ワンストップ特例制度とは、ふるさと納税をした後に確定申告をしなくても控除が受けられる便利な仕組みです。「申請書」に必要事項を記入して、ふるさと納税した自治体に送るだけなので、とってもかんたんです！',
            descriptionBodyAttention: 'ただし、以下に該当の方はワンストップ特例制度の適用外のため、確定申告をご利用ください。',
            attentionList1: '年間のふるさと納税先が6自治体以上になる方（基準は自治体数のため、1自治体に複数回申込をしても1カウント）',
            attentionList2: '確定申告が必要な方（年収2000万以上の給与所得者や個人事業主）',
            oneStopLink: '詳しい説明はこちら',
            birthDate: '生年月日',
            gender: '性別',
            placeholder: '選択してください',
            male: '男性',
            female: '女性',
            edit: '内容を変更'
        },
        TaxPayerInfo: {
            title: '納税者情報',
            Attention: {
                title: '必ずご確認ください',
                content1: '住民税納付者の氏名・住所を、住民票の記載通りに入力して下さい',
                content2: 'また、<0>{{localGovernment}}に住民票の籍がある方</0>は、本品の注文はできません'
            },
            // caution: '※納税者情報は、住民票に記載の情報をご記入ください。',
            edit: '納税者情報変更'
        },
        PurchaseContribution: {
            title: '納税者情報入力',
            buttonText: '確認画面に進む',
            buttonSubText: '(決済はまだされません)'
        },
        PurchaseConfirm: {
            title: '確認画面',
            caution: '※申込はまだ確定していません。内容をご確認の上、画面下の確定ボタンを押してください。',
            buttonText: '申込内容を確定する',
            ConfirmedMessage: {
                title: '申込前の確認事項',
                message1: '<0>ふるさと納税に対する自治体の返礼品は「ポイント」です。</0>商品に対するご質問や、不具合のご連絡は、自治体ではなく生産者へお願いいたします。',
                message2: 'お客様都合でのキャンセルは一切承ることができません。ただし気象災害で商品がやむを得ず発送困難になった等の場合には、ポイントの返還を行います。'
            },
            ConfirmedWayToUse: {
                title: '寄付金の使い道',
                edit: '使い道を変更'
            }
        },
        ProductCard: {
            price: '納税額{{quantityPrice}}円 (送料無料)'
        },
        addressChange: '注文者情報変更・登録',
        WayToUse: {
            title: '寄付金の使い道を選択',
            description: 'ふるさと納税では、自治体が寄付金をどのような使い道にするのか、自治体が提示している選択肢から選ぶことができます。（自治体に一任いただく場合もございます）'
        },
        SubmitButton: {
            contribution: {
                text: '納税者情報の入力に進む',
                subText: '（決済はまだされません）'
            },
            confirm: {
                text: '注文内容を確認する'
            }
        },
        form: {
            Mail: {
                title: 'メールアドレス',
                placeholder: 'pokemaru@poke-m.com'
            }
        }
    }
};
export default purchase;

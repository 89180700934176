export function useMobileWebView() {
    const fromIosWebView = ()=>{
        if (window.navigator.userAgent.match(/com.pokem.pocketmarche/)) {
            return true;
        }
        return false;
    };
    const fromAndroidWebView = ()=>{
        if (window.navigator.userAgent.match(/PocketMarcheAndroidWebView/)) {
            return true;
        }
        return false;
    };
    const isFromIosWebView = fromIosWebView();
    const isFromAndroidWebView = fromAndroidWebView();
    return {
        isFromIosWebView,
        isFromAndroidWebView
    };
}
/**
 * @deprecated ec-service/nativeMessageSenderを利用すること
 */ export function usePostMessageToNative() {
    const postMessage = (messageType)=>{
        if (!window.webkit) {
            return;
        }
        // close  webviewを閉じるためにiOS側に通知させている
        // account_deactivated 退会したことをiOS側に通知させている
        window.webkit.messageHandlers.nativebridgeiOS.postMessage({
            topic: messageType
        });
    };
    return {
        postMessage
    };
}

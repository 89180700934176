const myPage = {
    title: 'マイページ',
    profile: {
        title: 'プロフィール編集',
        profilePicture: 'プロフィール写真',
        changeProfilePicture: '変更',
        leaveMembership: '退会',
        nickname: 'ニックネーム',
        email: 'メールアドレス',
        checkNote: 'ポケットマルシェからの旬にまつわるお便りやお得な情報を受け取る',
        warning: '写真やニックネームはコミュニティでの投稿や生産者とのやりとりになどで利用されます。',
        saveBtn: '変更を保存する'
    },
    mailNotificationSetting: {
        title: 'メール通知設定',
        items: {
            newPostComment: '投稿へのコメント',
            newAnotherPostComment: 'あなたのコメントした投稿へのコメント',
            resaleProduct: '再販のお知らせ',
            willEndProduct: 'まもなく終了のお知らせ',
            remindLikedProducts: 'お気に入り商品リマインド'
        },
        buttonLabel: '保存する',
        failed: '保存できませんでした'
    },
    leaveMembership: {
        label: '退会する',
        ConfirmDialog: {
            confirmingText: '退会すると注文履歴やコミュニティの閲覧もできなくなります。よろしいですか?定期購入中の商品がある場合は、次回分からキャンセルされます。確定済みの注文は取り消されませんのでご注意ください。',
            cancel: 'キャンセル',
            confirmed: 'OK'
        },
        LoadingContainer: {
            description: '退会処理中です。画面を閉じないでください。'
        },
        email: {
            placeholder: '例）support@poke-m.com'
        }
    },
    sharing: {
        title: '友達にポケマルを紹介する',
        description: '以下のクーポンコードをシェアすると<br />お友達の初めての購入が<0>¥200円OFF</0>に!',
        couponCode: 'クーポンコード',
        SnsList: {
            sharingInX: 'Xで<br />シェアする',
            sharingInLine: 'LINEで<br />シェアする',
            sharingInEmail: 'メールで<br />シェアする',
            thisIsCoupon: 'ポケマルで使えるクーポンです',
            shareDescription: 'ポケマルで生産者から直接食べ物を買ってみませんか?',
            couponDescription: '初回200円のクーポンコード:{{couponCode}}',
            serviceUrl: 'URL:{{url}}'
        }
    }
};
export default myPage;

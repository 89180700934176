/* eslint-disable @typescript-eslint/naming-convention */ import { getFirstCategoryName } from '../../_shared/utils';
import postMessageToAndroid from '../postMessageToAndroid/postMessageToAndroid';
import postMessageToIOS from '../postMessageToIOS/postMessageToIOS';
/**
 * モバイルアプリ向けにbuyイベントを送信する
 */ const sendBuyEvent = ({ purchaseId, product, productTags, estimatedPriceItem, appliedCouponCode, price, tax, purchaseCount, hasFurusatoPurchase })=>{
    const { rootCategoryName, leafCategoryName } = getFirstCategoryName(product.categories);
    const data = {
        purchase_id: purchaseId,
        product_id: product.id.toString(),
        title: product.title,
        category: rootCategoryName,
        category2: leafCategoryName,
        tags: productTags,
        producer_id: product.user.id.toString(),
        prefecture: product.user.address || undefined,
        coupon_code: appliedCouponCode || undefined,
        revenue: estimatedPriceItem.totalAmount,
        price,
        delivery_cost: estimatedPriceItem.totalDeliveryRate,
        discount: estimatedPriceItem.discountAmount,
        tax,
        purchase_count: purchaseCount,
        has_furusato_purchase: hasFurusatoPurchase
    };
    postMessageToIOS({
        topic: 'buy',
        data
    });
    postMessageToAndroid({
        topic: 'buy',
        data
    });
};
export default sendBuyEvent;

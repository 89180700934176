import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastActions, toastSelectors } from '~/state/ducks/toast';
export function useToast() {
    const dispatch = useDispatch();
    const status = useSelector(toastSelectors.status);
    const message = useSelector(toastSelectors.message);
    const duration = useSelector(toastSelectors.duration);
    const sleep = async (duration)=>await new Promise((resolve)=>setTimeout(resolve, duration));
    const setSuccessMessage = useCallback((message, duration)=>{
        dispatch(toastActions.setToastStatus('success'));
        dispatch(toastActions.setToastMessage(message, duration));
    }, [
        dispatch
    ]);
    const initialize = useCallback(()=>{
        dispatch(toastActions.resetToast());
    }, [
        dispatch
    ]);
    useEffect(()=>{
        const showToast = async ()=>{
            await sleep(duration);
            initialize();
        };
        if (status === 'success') {
            showToast();
        }
        return;
    }, [
        status,
        initialize,
        duration
    ]);
    return {
        status,
        message,
        setSuccessMessage
    };
}

const specifiedCommercialLaw = {
    title: '特定商取引法に基づく表記',
    subTitle: '特定商取引法に基づく表記',
    contactInfo: '運営会社<0/>株式会社 雨風太陽<0/><0/>代表者<0/>高橋 博之<0/><0/>所在地<0/>〒025-0092<0/>岩手県花巻市大通り1丁目1-43-2 JR東日本 花巻駅構内<0/><0/>メールアドレス<0/><1>info@poke-m.com</1><0/><0/>電話番号<0/>開示請求後速やかに開示します。<0/><0/>',
    aboutPrice: '販売価格および送料<0/>各商品ページの表記をご確認ください。<0/><0/>',
    aboutFee: '商品代金以外に必要な費用<0/>配送エリアにより追加の送料<0/>（「<1>送料について</1>」をご確認ください。）<0/><0/>',
    aboutPaymentMethod: '代金の支払い方法<0/>- クレジットカード<0/>- 後払い（コンビニ/ATM）<0/>- atone 翌月払い（コンビニ/口座振替）<0/>' + '後払い（コンビニ/ATM）及びatone 翌月払い（コンビニ/口座振替）は単品商品の決済のみご利用いただけます。<0/><0/>',
    aboutPaymentTerm: '代金の支払い時期<0/>クレジットカード決済：ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせ下さい。<0/><0/>',
    paymentDateAtone: 'atone 翌月払い（コンビニ/口座振替）<br/>お支払いに必要な番号を、お買い物の翌月初旬にメールでご案内いたします。Loppiなどのコンビニ端末、または銀行ATM (Pay-easy) で、翌月10日までにお支払いください。<br/>お支払い方法は以下4つから選べます。スマホアプリまたはWebマイページから、月末までにお手続きください。<0/><0/> (1) コンビニ端末 / 銀行ATM (Pay-easy) ※標準設定<0/>(2) 電子バーコード (コンビニ)<0/>(3) はがき請求書 (コンビニ)<0/>(4) 口座振替※コンビニ端末 / 銀行ATM (Pay-easy) は、セブンイレブンではお支払いいただけません。<0/>※電子バーコード・はがき請求書は、セブンイレブンでもお支払いいただけます。<0/><0/>',
    paymentDateAtoneLite: '後払い（コンビニ/ATM）<0/>' + '注文確定の翌日に、メールとSMSでお支払いに必要な番号をお知らせします。コンビニまたは銀行ATM (Pay-easy) で10日後までにお支払いください。<0/>' + '後払い手数料は209円（税込）です。<0/>' + '代金は株式会社ネットプロテクションズの<1>利用規約</1>に基づき、指定の方法で請求いたします。<0/><0/>',
    cautionAtone: '■注意事項<0/>・決済手数料は無料です。<0/>・ご利用月のみ請求手数料209円(税込)が発生いたします。 (口座振替の場合は無料)<0/>・支払期限日は支払い方法によって異なります。<0/>・代金は株式会社ネットプロテクションズの<1>会員規約</1>に基づき指定の方法で請求いたします。<0/><0/>',
    aboutReceivingProducts: '商品の引渡し時期<0/>各商品ページの表記をご確認ください。<0/><0/>',
    applicationExpirationDate: '申込の有効期限<0/>各商品ページの表記をご確認ください。<0/><0/>',
    aboutCancel: 'キャンセル・交換について<0/>商品購入確定（会員による注文確定操作のことを言い、以下同じとします。）後の会員都合によるキャンセル・返品はできかねます。商品に契約不適合があった場合、会員は当社が定める方法で生産者に連絡を行うものとしますが、万が一会員と生産者間で当該商品の契約適合性について協議が整わないときには、当社が定める方法で当社に連絡を行うものとします。なお、予約商品及び定期商品については、会員は、商品購入確定後から当該商品ごとに定められたキャンセル期日までの間に限り、注文履歴よりキャンセルを行えるものとします。<0/>'
};
export default specifiedCommercialLaw;

const validation = {
    required: '入力必須です',
    minLength: '{{length}}文字以上で入力してください',
    maxLength: '{{length}}文字以内で入力してください',
    pattern: {
        email: 'メールアドレス形式で入力してください',
        password: '半角英数字8文字以上で入力してください',
        firstNamePronunciation: '全角カタカナで入力してください',
        lastNamePronunciation: '全角カタカナで入力してください',
        postalCode: '半角数字7桁で入力してください',
        phoneNumber: '半角数字のみ10桁~11桁で入力してください',
        holderName: '半角英大文字で入力してください',
        cardNumber: '半角数字14〜16桁で入力してください',
        securityCode: '半角数字3桁もしくは4桁で入力してください',
        priceNumber: '半角整数値1桁~7桁で入力してください'
    },
    validate: {
        firstNameMaxByteLength: '名は全角12文字、半角25文字以内で入力してください',
        lastNameMaxByteLength: '姓は全角12文字、半角24文字以内で入力してください',
        cityMaxByteLength: '市区町村は全角44文字、半角88文字以内で入力してください',
        address1MaxByteLength: '番地は全角24文字、半角49文字以内で入力してください',
        address2MaxByteLength: '建物名・部屋番号は全角25文字、半角50文字以内で入力してください',
        notEqualEmail: '入力されたメールアドレスがアカウントのメールアドレスと一致しません',
        priceNumber: '下限価格は上限価格以下になるように入力してください',
        expirationValue: '有効な日付形式を入力してください',
        expirationPast: '過去の日付が入力されています',
        image: {
            fileSize: '画像のファイルサイズは{{mb}}MB以下にしてください',
            fileResolution: '画像の幅、高さは{{size}}px以下にしてください'
        },
        restrictedPrefectureForPurchase: '{{prefecture}}に住民票の籍がある方は、本品の注文はできません'
    },
    // TODO このメッセージは現状使用されていない(ec-reactの互換のために残しているが、ec-reactのページが削除されたら削除する)
    alreadyExists: {
        email: 'このメールアドレスは既に存在しています'
    }
};
export default validation;

/* eslint-disable @typescript-eslint/naming-convention */ import TagManager from 'react-gtm-module';
import { getFirstCategoryName } from '../../_shared/utils';
import { DEFAULT_DATA_LAYER_NAME } from '../constants';
import { flattenProductTags, optimizeListName } from '../utils';
/**
 * add_to_wishlistイベントを送信
 * @param product 商品のリストデータ
 * @param itemListName 商品のリスト名, default = ''
 * @param itemIndex 商品の表示位置インデックス、default = -1
 */ const sendAddToWishlist = (product, itemListName, itemIndex)=>{
    const listName = itemListName || '';
    const index = itemIndex ?? -1;
    const { categories } = product;
    const tags = product.tags ? flattenProductTags(product.tags) : [];
    const quantity = product.quantities.length > 0 ? product.quantities[0] : null;
    const price = quantity ? quantity.price : 0;
    const { leafCategoryName } = getFirstCategoryName(categories);
    const items = [
        {
            item_id: product.id.toString(),
            item_name: product.title,
            currency: 'JPY',
            index,
            item_brand: product.user.id.toString(),
            item_category: leafCategoryName,
            item_variant: quantity ? quantity.description : '',
            price,
            location_id: product.user.state,
            has_furusato_quantity: product.hasFurusatoQuantity || false,
            tags,
            product_type: product.type,
            coupon_type: product.autoCoupon.type
        }
    ];
    const dataLayer = {
        event: 'add_to_wishlist',
        item_list_name: optimizeListName(listName),
        currency: 'JPY',
        value: price,
        items,
        _clear: true
    };
    TagManager.dataLayer({
        dataLayerName: DEFAULT_DATA_LAYER_NAME,
        dataLayer
    });
};
export default sendAddToWishlist;

import { useEffect, useState } from 'react';
import { usePaymentTax } from '~/hooks/furusato/paymentTax';
import { useLatestContribution } from '~/hooks/furusato/swr/contribution';
export function useLatestTaxPayerAddress(contribution) {
    const address = contribution ? {
        address: {
            id: contribution.id,
            address1: contribution.address1,
            address2: contribution.address2,
            city: contribution.city,
            firstName: contribution.firstName,
            firstNamePronunciation: contribution.firstNamePronunciation,
            lastName: contribution.lastName,
            lastNamePronunciation: contribution.lastNamePronunciation,
            phoneNumber: contribution.phoneNumber,
            postalCode: contribution.postalCode,
            state: contribution.state,
            main: false
        },
        mail: contribution.mail
    } : undefined;
    return {
        address
    };
}
export function useLatestOneStop() {
    const { contribution, isLoading: isLoadingContribution } = useLatestContribution();
    const [oneStop, setOneStop] = useState();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(()=>{
        if (!isLoadingContribution) {
            setIsLoading(false);
        }
        if (!contribution) {
            return;
        }
        const oneStop = {
            isOneStop: contribution.isOneStop,
            birthDate: contribution.birthDate,
            gender: contribution.gender
        };
        setOneStop(oneStop);
    }, [
        contribution,
        isLoadingContribution
    ]);
    return {
        oneStop,
        isLoading
    };
}
export function useInputTaxPayerAddress() {
    const { paymentTax } = usePaymentTax();
    const taxPayer = paymentTax?.taxPayer;
    const address = taxPayer ? {
        address: {
            id: paymentTax.id,
            address1: taxPayer.address1,
            address2: taxPayer.address2,
            city: taxPayer.city,
            firstName: taxPayer.firstName,
            firstNamePronunciation: taxPayer.firstNamePronunciation,
            lastName: taxPayer.lastName,
            lastNamePronunciation: taxPayer.lastNamePronunciation,
            phoneNumber: taxPayer.phoneNumber,
            postalCode: taxPayer.postalCode,
            state: taxPayer.state,
            main: false
        },
        mail: taxPayer.mail
    } : undefined;
    return {
        editedAddress: address
    };
}

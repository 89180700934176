import { useCallback, useState } from 'react';
import { fetchAddressFromPostalCode } from '~/repositories/postalCode';
export function usePostalCode() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const getAddressFromPostalCode = useCallback(async (zipCode)=>{
        setError(null);
        setIsLoading(true);
        return await fetchAddressFromPostalCode(zipCode).then((address)=>{
            return address;
        }).catch((e)=>{
            setError(e);
            return null;
        }).finally(()=>{
            setIsLoading(false);
        });
    }, []);
    return {
        getAddressFromPostalCode,
        isLoading,
        error
    };
}

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { color, size } from './constants';
export const GlobalStyle = createGlobalStyle`
  ${reset}
  body {
    font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3", Meiryo, "メイリオ", sans-serif;
    color: ${color.font.MAIN};
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  input {
    color: ${color.font.MAIN};
    font-size: ${size.font.M};
  }
  button {
    padding: 0;
    background: none;
    border: none;
    font: inherit;
  }

`;

import * as api from '~/services/api';
export async function fetchPaymentMethods() {
    const json = await api.get('v1/payment_methods');
    const res = await api.deserializeJsonApi(json);
    return res.map((method)=>{
        const { id, main, number, cardBrand, expYear, expMonth, expired, isUsedFromAnySubscriptions } = method;
        return {
            id: Number(id),
            isMain: main,
            number,
            cardBrand,
            expYear,
            expMonth,
            expired,
            isUsedFromAnySubscriptions
        };
    });
}

import { useEffect, useState } from 'react';
export function useApiValidationError(error) {
    const [validationErrors, setValidationErrors] = useState([]);
    useEffect(()=>{
        if (!error) {
            return;
        }
        if (!error.meta.validations) {
            return;
        }
        const errorMessages = Object.values(error.meta.validations).reduce((acc, cur)=>{
            acc.push(...cur);
            return acc;
        }, []);
        setValidationErrors(errorMessages);
    }, [
        error
    ]);
    return {
        validationErrors
    };
}
export function useApiParamsError(error) {
    const [paramsErrors, setParamsErrors] = useState([]);
    useEffect(()=>{
        if (!error) {
            return;
        }
        if (!error.meta.params) {
            return;
        }
        const errorMessages = Object.values(error.meta.params).reduce((acc, cur)=>{
            acc.push(...cur);
            return acc;
        }, []);
        setParamsErrors(errorMessages);
    }, [
        error
    ]);
    return {
        paramsErrors
    };
}
export function useGmoValidationError(error) {
    const [gmoValidationErrors, setGmoValidationErrors] = useState([]);
    useEffect(()=>{
        if (!error) {
            return;
        }
        if (!error.meta.gmopg) {
            return;
        }
        setGmoValidationErrors(Object.values(error.meta.gmopg));
    }, [
        error
    ]);
    return {
        gmoValidationErrors
    };
}

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeta } from '~/hooks/seo/meta';
export function useSeo() {
    const { t } = useTranslation('meta');
    const { createDocumentTitle, createMetaInfo } = useMeta();
    useEffect(()=>{
        createDocumentTitle(t('title.furusato.purchaseConfirm') + t('title.furusato.serviceSubTitle'));
        createMetaInfo(t('description.furusato.top'));
    }, [
        createDocumentTitle,
        createMetaInfo,
        t
    ]);
}

const appConductor = {
    iosMessage: 'アプリでもっとサクサク◎\n限定情報も',
    androidMessage: '新着情報をアプリでいち早く\nチェックしよう',
    iosAppOpen: 'アプリで開く',
    androidStoreOpen: 'アプリを利用する',
    closeButtonAriaLabel: '閉じるボタン',
    dynamicLink: {
        productList: {
            st: '新着食材の一覧',
            sd: '生産者から直接購入できる新着食材の一覧です。野菜や果物、肉、お魚などを産地直送で宅配・お取り寄せいただけます。',
            si: 'https://poke-m.com/ogp.png'
        },
        others: {
            st: 'ポケットマルシェ | 旬の食べものを農家・漁師から産地直送で通販・取り寄せ',
            sd: '農家・漁師さんが旬の食材を出品・販売するオンラインマルシェです。生産者と会話しながら直売所感覚でお買い物をお楽しみください。',
            si: 'https://poke-m.com/ogp.png'
        }
    },
    dynamicLinkFurusato: {
        st: 'ポケマルふるさと納税 | 農家・漁師直送の返礼品をさがす',
        sd: '農家・漁師の直接出品だから新鮮で美味しい返礼品が見つかる！野菜、果物、肉、魚、お米を通じて地域の生産者を応援しませんか。',
        si: 'https://poke-m.com/images/furusato/top/pcMainVisual.jpg'
    }
};
export default appConductor;

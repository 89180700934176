/**
 * ひらがなをカタカナに変換
 * @param text 変換する文字列
 * @returns 変換後の文字列
 */ const hiraToKana = (text)=>{
    return text.replace(/[\u3042-\u3093]/g, (substring)=>String.fromCharCode(substring.charCodeAt(0) + 96));
};
/**
 * カテゴリのデータからサジェスト用データを作成
 * @param data NestedSetのカテゴリデータ
 * @returns SuffixTreeへの入力データ
 */ export const createCategorySuggestions = (data)=>{
    const srcData = [];
    // wordの重複を除くために定義
    const set = new Set();
    for (const { left, parentId, attributes: { id, name, keywords } } of data){
        if (set.has(`${id}-${name}`)) {
            continue;
        }
        const baseDta = {
            id,
            string: name,
            categoryParams: {
                left,
                parentId
            }
        };
        srcData.push(baseDta);
        set.add(`${id}-${name}`);
        if (!keywords) {
            continue;
        }
        for (const keyword of keywords.split(',')){
            if (set.has(`${id}-${keyword}`)) {
                continue;
            }
            srcData.push({
                ...baseDta,
                string: keyword
            });
            set.add(`${id}-${keyword}`);
            // 平仮名→カナへ変換
            const kanaWord = hiraToKana(keyword);
            if (set.has(`${id}-${kanaWord}`)) {
                continue;
            }
            srcData.push({
                ...baseDta,
                string: kanaWord
            });
            set.add(`${id}-${kanaWord}`);
        }
    }
    return srcData;
};

import { buildProductsPagePath } from '~/domains/search/searchHelper';
export function useProductSearch() {
    // ec(next.js)の検索画面に遷移する
    const searchProducts = (option)=>{
        window.location.assign(buildProductsPagePath(option));
    };
    return {
        searchProducts
    };
}

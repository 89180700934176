const defaultHtmlFontSize = 16;
export function pxToRem(px) {
    return `${px / defaultHtmlFontSize}rem`;
}
/**
 * px値をデバイスの下部セーフエリアを含めたrem値に変換する。ページ下部の余白やフロートバーの高さに利用する。
 * calcで括らない場合はoptions.withoutCalcをtrueにする。
 * @returns {string} calcで括られたrem値。例：calc(1rem + env(safe-area-inset-bottom))。options.withoutCalcがtrueの場合はcalcで括らない。
 */ export function pxToRemWithSafeAreaBottom(px, options = {}) {
    const line = `(${pxToRem(px)} + env(safe-area-inset-bottom))`;
    return options.withoutCalc ? line : `calc(${line})`;
}
export function remToPxNumber(rem) {
    const num = Number(rem.replace(/rem/, ''));
    return num * defaultHtmlFontSize;
}
export const size = {
    font: {
        L: pxToRem(18),
        M: pxToRem(16),
        S: pxToRem(12),
        XS: pxToRem(10),
        XXS: pxToRem(8)
    },
    fontWeight: {
        W3: '300',
        W4: '400',
        W5: '500',
        W6: '600',
        W7: '700'
    },
    breakpoint: {
        MIN: 320,
        TABLET: 768,
        PC: 1100
    },
    width: {
        CONTENT_MAX_WITH_PADDING: 1300,
        CONTENTS_MAX: 1200,
        CONTENTS_MID: 500
    },
    height: {
        spHeader: {
            signIned: 50,
            notSignined: 90
        },
        tabletAndPcHeader: 80,
        copyrightFooter: 61,
        breadcrumb: 40
    },
    borderRadius: {
        MESSAGE: '16px',
        NORMAL: '8px',
        BUTTON: '6px',
        CIRCLE: '1000px'
    }
};

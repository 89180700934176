const COMMON = {
    TITLE: 'ポケットマルシェ｜産地直送で旬の食材が生産者(農家・漁師)から届く',
    DESCRIPTION_SUFFIX: '日本最大級の産直(産地直送)通販「ポケットマルシェ(ポケマル)」の生産者プロフィールは、生産者の顔や名前、商品が分かり、作り手のこだわりや思いが読めます。生産者と心がふれあうお買い物体験をお楽しみください。',
    PRODUCTS_DESCRIPTION_SUFFIX: '産直通販サイト ポケットマルシェ(ポケマル)で販売中の、旬の果物・野菜・魚介・肉・卵などを生産者(農家・漁師)から産地直送でお届け。品種・産地・価格などこだわりのお取り寄せ条件でも探せます。'
};
/**
 * NOTE: `as const` を末尾につけると、(エスケープさせないための)ハイフン記法とTSの型が一致しないので付けない
 */ const meta = {
    title: {
        top: COMMON.TITLE,
        product: {
            of: '{{- name}}の',
            default: '{{- title}}｜{{- category}}商品詳細｜' + COMMON.TITLE
        },
        serviceSubtitle: ' | 農家漁師から産地直送の通販 ポケットマルシェ',
        announcements: 'お知らせ一覧',
        login: 'ログイン',
        signUpCompleted: '会員登録完了',
        userSignup: '新規ユーザー登録',
        confirmEmail: '仮登録が完了しました',
        resetPassword: 'パスワード再発行',
        forgotPassword: 'パスワードの再設定',
        mypage: 'マイページ',
        profile: 'プロフィール編集',
        editPassword: 'パスワード変更',
        sharing: '友達にポケマルを紹介する',
        leave: '退会する',
        producer: `{{stateAndCity}}｜{{- organizationName}}｜{{fullname}}さんの生産者プロフィール｜${COMMON.TITLE}`,
        posts: {
            producer: `{{stateAndCity}}｜{{- organizationName}}｜{{fullname}}さんの投稿｜${COMMON.TITLE}`,
            all: `{{stateAndCity}}｜{{- organizationName}}｜{{fullname}}さんのみんなの投稿｜${COMMON.TITLE}`,
            new: `投稿｜${COMMON.TITLE}`
        },
        stories: '読みもの一覧',
        purchase: '注文手続き',
        address: '住所の選択・新しい住所の登録',
        creditCard: 'クレジットカードの選択・新しいクレジットカードの登録',
        purchaseConfirm: '注文内容の確認',
        purchaseComplete: '注文完了',
        messageList: 'メッセージ一覧',
        producerName: '{{producer}}さんとの連絡',
        orders: '発送予定・発送済みの注文',
        subscriptions: '注文履歴（定期・予約の管理）',
        orderDetails: '注文内容',
        subscriptionDetails: '定期・予約注文内容',
        subscriptionPurchase: '定期購入詳細',
        reservedPurchase: '予約購入詳細',
        subscriptionPurchaseEdit: 'お届け先の変更',
        subscriptionPurchaseEditPayment: 'お支払い方法、お届け時間帯の変更',
        subscriptionCancel: '定期・予約キャンセル',
        subscriptionSkip: '定期スキップ',
        addressEdit: '住所編集',
        mailNotification: 'メール通知設定',
        likedProduct: 'お気に入り一覧 ',
        likedProductOnSale: 'お気に入り一覧 - 販売中のみ',
        products: {
            and: 'の',
            pagination: '({{currentPage}}ページ目)',
            default: `産直通販・お取り寄せ商品{{pagination}}｜${COMMON.TITLE}`,
            atLeastOneCondition: `{{- searchedItem}}の産直通販・お取り寄せ商品{{pagination}}｜{{- rootCategory}}${COMMON.TITLE}`
        },
        producers: {
            and: 'の',
            pagination: '({{currentPage}}ページ目)',
            default: `すべての生産者一覧{{pagination}}｜${COMMON.TITLE}`,
            atLeastOneCondition: `{{- searchedItem}}の生産者一覧{{pagination}}｜${COMMON.TITLE}`
        },
        messageInquiries: '{{producerName}} | {{- organizationName}}との連絡 | 農家漁師から産地直送の通販 ポケットマルシェ',
        followingCommunities: 'フォロー生産者',
        categories: `カテゴリからさがす｜${COMMON.TITLE}`,
        categoryDetail: `{{- categoryName}}の通販・お取り寄せ商品｜{{- rootCategoryName}}${COMMON.TITLE}`,
        feedback: `注文評価｜${COMMON.TITLE}`,
        furusato: {
            serviceSubTitle: ' | 農家・漁師直送のポケマルふるさと納税',
            top: 'ポケマルふるさと納税｜生産者とつながる産直(産地直送)ふるさと納税サイト',
            simulation: 'ふるさと納税上限額シミュレータ',
            purchaseInProgress: 'ふるさと納税 申し込みフォーム',
            purchaseContribution: 'ふるさと納税 納税者情報入力フォーム',
            purchaseConfirm: 'ふるさと納税 申し込み内容確認',
            contributions: 'ふるさと納税履歴一覧',
            contribution: 'ふるさと納税履歴',
            balances: 'ポイント残高',
            guide: 'ふるさと納税が初めての方へ',
            products: {
                and: 'の',
                pagination: '({{currentPage}}ページ目)',
                default: '産直食材のふるさと納税 返礼品一覧{{pagination}}｜ポケマルふるさと納税｜生産者とつながる産直(産地直送)ふるさと納税サイト',
                atLeastOneCondition: '{{- searchedItem}}の産直食材の返礼品一覧{{pagination}}｜ポケマルふるさと納税｜生産者とつながる産直(産地直送)ふるさと納税サイト'
            }
        }
    },
    description: {
        top: '日本最大級の産直(産地直送)通販「ポケットマルシェ(ポケマル)」は果物・野菜・魚・肉のお取り寄せ・ふるさと納税に対応。生産者と心がふれあうお買い物体験をお楽しみください。',
        product: {
            of: '{{- name}}の',
            default: '{{address}}の{{- organizationName}}の{{name}}さんが出品している{{- category}}商品詳細{{extraInfo}}です。日本最大級の産直(産地直送)通販「ポケットマルシェ(ポケマル)」の商品詳細は、生産者の産地やこだわり、保存方法、発送目安が分かり、画像付きのみんなの投稿(レビュー)も充実。生産者と心がふれあうお買い物体験をお楽しみください。'
        },
        productJsonLd: {
            of: '{{- name}}の',
            default: '{{address}}の{{- organizationName}}の{{name}}さんが出品している{{- category}}商品詳細です。{{extraInfo}}'
        },
        producer: `{{stateAndCity}}の{{- organizationName}}の{{fullname}}さんが出品している生産者プロフィールです。${COMMON.DESCRIPTION_SUFFIX}`,
        posts: {
            producer: `{{stateAndCity}}の{{- organizationName}}の{{fullname}}さんの投稿一覧です。${COMMON.DESCRIPTION_SUFFIX}`,
            all: `{{stateAndCity}}の{{- organizationName}}の{{fullname}}さんのみんなの投稿一覧です。${COMMON.DESCRIPTION_SUFFIX}`
        },
        stories: '読みものの一覧ページです。ポケットマルシェ（ポケマル）は、農家・漁師さんがで旬の食材を出品・販売するオンラインマルシェです。野菜や果物、肉、お魚などを産地直送で宅配・お取り寄せ。生産者と会話しながら、直売所感覚でお買い物をお楽しみください。',
        products: {
            multipleConditions: `{{- multipleConditions}}{{- rootCategory}}の商品一覧です。${COMMON.PRODUCTS_DESCRIPTION_SUFFIX}`,
            default: `産直通販の商品一覧です。${COMMON.PRODUCTS_DESCRIPTION_SUFFIX}`
        },
        producers: {
            and: 'の',
            multipleConditions: '{{- multipleConditions}}の生産者一覧です。日本最大級の産直(産地直送)通販「ポケットマルシェ(ポケマル)」の生産者一覧は、カテゴリや生産地、生産者名などから探せ、生産者の顔と名前が見えます。生産者と心がふれあうお買い物体験をお楽しみください。',
            default: 'すべての生産者一覧です。日本最大級の産直(産地直送)通販「ポケットマルシェ(ポケマル)」の生産者一覧は、カテゴリや生産地、生産者名などから探せ、生産者の顔と名前が見えます。生産者と心がふれあうお買い物体験をお楽しみください。'
        },
        categories: '産直通販サイト ポケットマルシェ(ポケマル)で販売中の、旬の果物・野菜・魚介・肉・卵などを生産者(農家・漁師)から産地直送でお届け。',
        categoryDetail: `{{- categoryName}}の商品です。${COMMON.PRODUCTS_DESCRIPTION_SUFFIX}`,
        furusato: {
            top: '産直(産地直送)ふるさと納税サイト「ポケマルふるさと納税」は、他では手に入らない新鮮・高品質な美味しい返礼品がたくさん。農家・漁師の直接出品による、生産者と心がふれあう体験をお楽しみ下さい。返礼品は、ポケットマルシェの通販サイトでも注文も可能です。',
            products: {
                and: 'の',
                multipleConditions: '{{- multipleConditions}}の返礼品一覧です。産地直送のふるさと納税サイト「ポケマルふるさと納税」で販売中の、旬の果物・野菜・魚介・肉・卵などを生産者(農家・漁師)から産地直送でお届け。ふるさと納税で定番人気の返礼品から、自治体・品種・価格などこだわり条件でも探せます。',
                default: '産直食材の返礼品一覧です。産地直送のふるさと納税サイト「ポケマルふるさと納税」で販売中の、旬の果物・野菜・魚介・肉・卵などを生産者(農家・漁師)から産地直送でお届け。ふるさと納税で定番人気の返礼品から、自治体・品種・価格などこだわり条件でも探せます。'
            },
            simulation: 'ふるさと納税の上限額をシミュレータで算出できます。自営業の方・副業者の方にも対応。かんたんシミュレーションと詳細シミュレーション両方ご用意しております。ポケマルふるさと納税は農家・漁師の直接出品だから、他では手に入らない新鮮・高品質な美味しい返礼品が見つかるサイトです。選んだ返礼品は、ポケットマルシェの通販サイトで注文も可能です。',
            guide: 'ふるさと納税が初めての方へ、ふるさと納税の仕組み・やり方について分かりやすくかんたんにご紹介します。ポケマルふるさと納税は農家・漁師の直接出品だから、他では手に入らない新鮮・高品質な美味しい返礼品が見つかるサイトです。選んだ返礼品は、ポケットマルシェの通販サイトで注文も可能です。'
        }
    }
};
export default meta;

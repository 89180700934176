import { PARAM_PATH } from 'ec-constants/routes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDelivery } from '~/hooks/furusato/delivery';
import { usePaymentMethod } from '~/hooks/furusato/paymentMethod';
import { useOtherAddressForSending } from '~/hooks/otherAddressForSending';
import { usePurchaserAddresses } from '~/hooks/purchaserAddresses';
import { fetchEstimatedResult } from '~/repositories/furusato/estimation';
import { deliveryActions } from '~/state/furusato/ducks/delivery';
import { estimationActions, estimationSelectors } from '~/state/furusato/ducks/estimation';
import { usePointCustomer } from './pointCustomer';
export function useEstimation() {
    const dispatch = useDispatch();
    const estimatedResult = useSelector(estimationSelectors.estimatedResult);
    const setEstimatedResult = useCallback((estimatedResult)=>{
        dispatch(estimationActions.setEstimatedResult(estimatedResult));
    }, [
        dispatch
    ]);
    const resetEstimatedResult = useCallback(()=>{
        dispatch(estimationActions.setEstimatedResult(null));
    }, [
        dispatch
    ]);
    return {
        estimatedResult,
        setEstimatedResult,
        resetEstimatedResult
    };
}
export function usePurchaseEstimation({ product, quantityId }) {
    const navigate = useNavigate();
    const productId = product.id;
    const quantity = product.quantities.find((item)=>{
        return item.id === quantityId;
    });
    const pocketPointCode = quantity?.pocketPointCode;
    const { selectedPurchaserAddress } = usePurchaserAddresses();
    const { paymentMethod } = usePaymentMethod();
    const { selectedOtherAddressForSending } = useOtherAddressForSending();
    const { deliveryDate, deliveryTime } = useDelivery();
    const { setEstimatedResult, resetEstimatedResult } = useEstimation();
    const { customerId } = usePointCustomer();
    const dispatch = useDispatch();
    useEffect(()=>{
        const targetedAddress = selectedOtherAddressForSending || selectedPurchaserAddress;
        if (targetedAddress && pocketPointCode && customerId) {
            const estimationItem = {
                productId,
                productQuantityId: quantityId,
                deliveryDestinationId: targetedAddress.id,
                paymentMethod,
                pocketPointCode,
                timeOfDay: deliveryTime,
                customerId
            };
            const fetch = async ()=>{
                await fetchEstimatedResult(estimationItem).then((estimatedResult)=>{
                    setEstimatedResult(estimatedResult);
                    // お届け日、時間帯が選択できないようになった場合はクリアする
                    if (deliveryDate && !(estimatedResult.availableScheduleDates || []).includes(deliveryDate)) {
                        dispatch(deliveryActions.setDeliveryDate(''));
                    }
                    if (!(estimatedResult.availableTimesOfDay || []).includes(deliveryTime)) {
                        dispatch(deliveryActions.setDeliveryTime(''));
                    }
                }).catch((e)=>{
                    navigate(PARAM_PATH.ERROR(e.errorCode || ''), {
                        state: {
                            error: e
                        }
                    });
                });
            };
            fetch();
            return;
        }
        resetEstimatedResult();
    }, [
        setEstimatedResult,
        resetEstimatedResult,
        paymentMethod,
        selectedPurchaserAddress,
        selectedOtherAddressForSending,
        productId,
        pocketPointCode,
        quantityId,
        navigate,
        deliveryDate,
        deliveryTime,
        customerId,
        dispatch
    ]);
}

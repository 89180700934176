import { PARAM_PATH, ROUTER_PATH } from 'ec-constants/routes';
import { sendSignUp } from 'ec-services/gtmService';
import { postMessageToIOS } from 'ec-services/nativeMessageSender';
import postMessageToAndroid from 'ec-services/nativeMessageSender/postMessageToAndroid/postMessageToAndroid';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import store from 'store';
import { STORE_KEY, isSignedIn, setIsSignedIn } from '~/domains/user';
import { useMobileWebView, usePostMessageToNative } from '~/hooks/mobileWebView';
import { deleteAccessToken, fetchAccessToken } from '~/repositories/accessToken';
import { confirmUser, createUser, createUserWithOauth, deleteMe, fetchMe, getLikedProducts, patchMe, putPassword } from '~/repositories/user';
import { sanitizeFromQuery } from '~/services/sanitizeFromQuery';
import { commonActions } from '~/state/ducks/common';
import { userActions, userSelectors } from '~/state/ducks/user';
import { useToast } from './components/toast';
export function useMe() {
    const dispatch = useDispatch();
    const me = useSelector(userSelectors.me);
    const isInitialized = useSelector(userSelectors.isInitialized);
    const [isLoading, setIsLoading] = useState(false);
    // 関数再生成によるuseEffect内での重複実行を避ける為、useCallbackを使用
    const initialize = useCallback(()=>{
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        fetchMe().then((fetchedMe)=>{
            setIsSignedIn(true);
            dispatch(userActions.setMe(fetchedMe));
            getLikedProducts().then((data)=>{
                dispatch(userActions.setLikeProducts(data));
            });
        }).catch(()=>{
            // 初期化時のプロフィール取得はアクセストークンの有無によって成功も失敗も有り得るのでエラーは握りつぶす
            setIsSignedIn(false);
        }).finally(()=>{
            dispatch(userActions.setIsInitialized(true));
            setIsLoading(false);
        });
    }, [
        dispatch,
        isLoading
    ]);
    return {
        initialize,
        isInitialized,
        me
    };
}
export function useOAuthUser() {
    const oAuthUser = useSelector(userSelectors.oAuth);
    return {
        oAuthUser
    };
}
export function useSignIn() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const signIn = (email, password)=>{
        setError(null);
        setIsLoading(true);
        return fetchAccessToken(email, password).then(()=>fetchMe()).then(async (fetchedMe)=>{
            setIsLoading(false);
            setIsSignedIn(true);
            dispatch(userActions.setMe(fetchedMe));
            getLikedProducts().then((data)=>{
                dispatch(userActions.setLikeProducts(data));
            });
            return {
                success: true,
                userType: fetchedMe.type
            };
        }).catch((e)=>{
            setIsLoading(false);
            setError(e);
            return {
                success: false,
                userType: undefined
            };
        });
    };
    return {
        signIn,
        isLoading,
        error
    };
}
export function useSignOut() {
    const dispatch = useDispatch();
    const { setSuccessMessage } = useToast();
    const { t } = useTranslation('toast');
    //関数再生成によるuseEffect内での重複実行を避ける為、useCallbackを使用
    const signOut = useCallback(async ()=>{
        return deleteAccessToken().then(()=>{
            setIsSignedIn(false);
            dispatch(commonActions.signOut());
            setSuccessMessage(t('signOutDone'));
            window.location.replace(ROUTER_PATH.HOME);
            return true;
        }).catch(()=>{
            return false;
        });
    }, [
        dispatch,
        setSuccessMessage,
        t
    ]);
    return {
        signOut
    };
}
export function useSignUp() {
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const getRedirectedUrl = (fromLocation)=>{
        if (!fromLocation) {
            return '';
        }
        if (typeof fromLocation === 'string') {
            return fromLocation;
        }
        const willRedirectedPath = fromLocation.search ? `${fromLocation.pathname}${fromLocation.search}` : `${fromLocation.pathname}`;
        return willRedirectedPath;
    };
    const signUp = (params)=>{
        const fromQuery = sanitizeFromQuery(new URLSearchParams(location.search).get('from') || '');
        const fromLocation = state?.from;
        const willRedirectedPath = getRedirectedUrl(fromLocation) || fromQuery || ROUTER_PATH.HOME;
        store.set(STORE_KEY.LINK_AFTER_SIGN_UP, willRedirectedPath);
        setApiError(null);
        setIsLoading(true);
        return createUser({
            ...params,
            ...{
                returnPath: willRedirectedPath
            }
        }).then(()=>{
            setIsLoading(false);
            navigate(`${ROUTER_PATH.SIGN_UP_TEMPORARY_COMPLETE}?email=${encodeURIComponent(params.email)}`);
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        signUp,
        isLoading,
        apiError
    };
}
export function useSignUpWithOauth() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('toast');
    const { setSuccessMessage } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const signUp = (params)=>{
        let friendshipStatusChangedForGa;
        if (params.authorizedProvider === 'line') {
            friendshipStatusChangedForGa = params.friendshipStatusChanged;
        } else {
            friendshipStatusChangedForGa = undefined;
        }
        setApiError(null);
        setIsLoading(true);
        return createUserWithOauth(params).then(()=>fetchMe()).then((fetchedMe)=>{
            dispatch(userActions.setMe(fetchedMe));
            // SignUp完了をGTM(GA4)へ送信
            // (LINEの場合は友達登録されたかどうかの値を含む)
            sendSignUp(params.authorizedProvider, friendshipStatusChangedForGa);
            setIsLoading(false);
            // OAuth経由のサインアップはメール確認が不要なのでそのままログインさせる
            setIsSignedIn(true);
            setSuccessMessage(t('signupDone'));
            navigate(ROUTER_PATH.SIGN_UP_COMPLETE);
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        signUp,
        isLoading,
        apiError
    };
}
export function useSignUpConfirmation() {
    const { t } = useTranslation('toast');
    const { setSuccessMessage } = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const signUpConfirmation = (token)=>{
        if (isSignedIn()) {
            return;
        }
        setIsLoading(true);
        return confirmUser(token).then(()=>{
            setIsLoading(false);
            return fetchMe().then((fetchedMe)=>{
                setSuccessMessage(t('signupDone'));
                // SignUp完了をGTM(GA4)へ送信
                sendSignUp('mail');
                navigate(ROUTER_PATH.SIGN_UP_COMPLETE);
                setIsSignedIn(true);
                dispatch(userActions.setMe(fetchedMe));
                return true;
            }).catch(()=>{
                return false;
            });
        }).catch((e)=>{
            setIsLoading(false);
            if (e.errorCode) {
                navigate(PARAM_PATH.ERROR(e.errorCode), {
                    state: {
                        error: e
                    }
                });
            }
            return false;
        });
    };
    return {
        signUpConfirmation,
        isLoading
    };
}
export function useSignUpCompleted() {
    const { isFromIosWebView } = useMobileWebView();
    const { postMessage } = usePostMessageToNative();
    const redirect = ()=>{
        if (isFromIosWebView) {
            postMessage('close');
            return;
        }
        window.location.assign(store.get(STORE_KEY.LINK_AFTER_SIGN_UP) || ROUTER_PATH.HOME);
        store.remove(STORE_KEY.LINK_AFTER_SIGN_UP);
    };
    return {
        redirect
    };
}
export function useDeleteMe() {
    const { isFromIosWebView, isFromAndroidWebView } = useMobileWebView();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const leaveMembership = async ()=>{
        setApiError(null);
        setIsLoading(true);
        return await deleteMe().then(()=>{
            setIsLoading(false);
            setIsSignedIn(false);
            if (isFromIosWebView) {
                postMessageToIOS({
                    topic: 'account_deactivated'
                });
            }
            if (isFromAndroidWebView) {
                postMessageToAndroid({
                    topic: 'account_deactivated'
                });
            }
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        leaveMembership,
        isLoading,
        apiError
    };
}
export function useUpdateMe() {
    const { me } = useMe();
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [avatar, setAvatar] = useState({
        file: null,
        imageUrl: me?.avatarImageUrl || null
    });
    const dispatch = useDispatch();
    const updateMe = async (email, name, acceptEmailInfo, avatar, removeAvatar)=>{
        setIsLoading(true);
        return await patchMe(email, name, acceptEmailInfo, removeAvatar, avatar).then((updatedMe)=>{
            setIsLoading(false);
            if (avatar) {
                setAvatar({
                    file: avatar,
                    imageUrl: null
                });
            }
            dispatch(userActions.setMe(updatedMe));
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setApiError(e);
            return false;
        });
    };
    return {
        updateMe,
        setAvatar,
        avatar,
        isLoading,
        apiError
    };
}
export function useEmailUpdateConfirmation() {
    const { t } = useTranslation('toast');
    const { setSuccessMessage } = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const confirmEmailUpdate = async (token)=>{
        setIsLoading(true);
        return await confirmUser(token).then(async ()=>{
            setIsLoading(false);
            return await fetchMe().then((fetchedMe)=>{
                setSuccessMessage(t('emailUpdateConfirmationDone'));
                navigate(ROUTER_PATH.MY_PAGE);
                dispatch(userActions.setMe(fetchedMe));
                return true;
            }).catch(()=>{
                return false;
            });
        }).catch((e)=>{
            setIsLoading(false);
            if (e.errorCode) {
                navigate(PARAM_PATH.ERROR(e.errorCode), {
                    state: {
                        error: e
                    }
                });
            }
            return false;
        });
    };
    return {
        confirmEmailUpdate,
        isLoading
    };
}
export function useForgotPassword() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const forgotPassword = async (token, password)=>{
        setIsLoading(true);
        return await putPassword(token, password).then(()=>{
            return true;
        }).catch((e)=>{
            setApiError(e);
            return false;
        }).finally(()=>{
            setIsLoading(false);
        });
    };
    return {
        forgotPassword,
        isLoading,
        apiError
    };
}

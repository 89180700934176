import useSWR from 'swr';
import { getBalancesPath, swrFetcher } from '~/repositories/furusato/balances';
export function useBalances() {
    const { data, error } = useSWR(getBalancesPath(), swrFetcher);
    return {
        balances: data,
        isLoading: !data,
        error
    };
}

/**
 * オブジェクトから特定のキーを削除
 * https://youmightnotneed.com/lodash/#omit
 */ const omit = (obj, props)=>{
    obj = {
        ...obj
    };
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    props.forEach((prop)=>delete obj[prop]);
    return obj;
};
export default omit;

import styled, { css } from 'styled-components';
import { color, size } from '~/styles/constants/furusato';
import { breakPoint } from '~/styles/utils';
export const Title = styled.h2`
  margin: 0 1rem 24px;
  font-size: ${size.font.L};
  font-weight: ${size.fontWeight.W6};
  ${breakPoint.PC(css`
    margin: 0 0 30px;
  `)}
`;
export const RoundBox = styled.div`
  margin: ${size.borderRadius.NORMAL} 1rem;
  background-color: ${color.WHITE};
  border-radius: ${size.borderRadius.NORMAL};
  border: ${color.border.GRAY} solid 1px;
  ${breakPoint.PC(css`
    margin: ${size.borderRadius.NORMAL} 0;
  `)}
`;
export const Content = styled.div`
  width: 100%;
  line-height: 1.5;
`;

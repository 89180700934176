import { SIGN_OUT } from '~/state/ducks/common/actions';
import { FURUSATO_DELETE_ALLOCATED_PURCHASE_ITEM, FURUSATO_RESET_PURCHASE, FURUSATO_SET_ALLOCATED_PURCHASE_ITEM, FURUSATO_SET_PURCHASE_COMPLETED } from './actions';
const initialState = {
    isPurchaseCompleted: false,
    allocatedPurchaseItem: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case FURUSATO_SET_ALLOCATED_PURCHASE_ITEM:
            return {
                ...state,
                allocatedPurchaseItem: action.payload.allocatedPurchaseItem
            };
        case FURUSATO_SET_PURCHASE_COMPLETED:
            return {
                ...state,
                isPurchaseCompleted: action.payload.isPurchaseCompleted
            };
        case FURUSATO_DELETE_ALLOCATED_PURCHASE_ITEM:
            return {
                ...state,
                allocatedPurchaseItem: initialState.allocatedPurchaseItem
            };
        case FURUSATO_RESET_PURCHASE:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

import { useState } from 'react';
import store from 'store';
export function useAppConductor() {
    const appConductor = store.get('appConductor');
    const [isDenied, setIsDenied] = useState(appConductor && appConductor.isDenied || false);
    const deny = ()=>{
        setIsDenied(true);
        store.set('appConductor', {
            isDenied: true
        });
    };
    /**
   * deeplinkのURLを開く
   * URLがない場合は何もしない。（基本的にはURLは入る）
   */ const openNativeAppLink = (link)=>{
        if (link) {
            window.open(link, '_self');
        }
    };
    return {
        isDenied,
        deny,
        openNativeAppLink
    };
}

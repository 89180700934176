/* eslint-disable @typescript-eslint/naming-convention */ import TagManager from 'react-gtm-module';
import { DEFAULT_DATA_LAYER_NAME } from '../constants';
import { convertProductIntoItem } from '../utils';
/**
 * GTM(GA4)へpurchaseイベントを送信する
 * @param product ec or furusato 商品データ
 * @param quantity  ec or furusato 選択している分量
 * @param purchase
 * @param hasFurusatoPurchase ふるさと納税の注文か
 * @param totalDeliveryRate ECのみ 送料
 */ const sendPurchase = (product, quantity, purchase, paymentType, hasFurusatoPurchase, totalDeliveryRate)=>{
    const item = convertProductIntoItem(product, quantity);
    const dataLayer = {
        event: 'purchase',
        currency: 'JPY',
        value: quantity.price || 0,
        transaction_id: purchase.id.toString(),
        tax: purchase.purchaseContract?.consumptionTax || 0,
        shipping: totalDeliveryRate || 0,
        payment_type: paymentType,
        first_purchase: purchase.firstPurchase,
        has_furusato_purchase: hasFurusatoPurchase,
        items: [
            item
        ],
        _clear: true
    };
    TagManager.dataLayer({
        dataLayerName: DEFAULT_DATA_LAYER_NAME,
        dataLayer
    });
};
export default sendPurchase;

import dayjs from 'dayjs';
import * as api from '~/services/api';
function resToBalances(res) {
    return res.map((item)=>{
        const { name, balance, expiredAt, code, localGovernment } = item;
        return {
            name,
            balance,
            expiredAt: dayjs(expiredAt).format('YYYY年MM月DD日'),
            code,
            localGovernment: {
                id: Number(localGovernment?.id),
                name: localGovernment?.name,
                state: localGovernment?.state,
                createdAt: dayjs(localGovernment?.createdAt).format('YYYY年MM月DD日'),
                updatedAt: dayjs(localGovernment?.updatedAt).format('YYYY年MM月DD日')
            }
        };
    });
}
export function getBalancesPath() {
    return `v1/furusato/point_balances`;
}
export async function swrFetcher(path) {
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    return resToBalances(res);
}

import { FURUSATO_PARAM_PATH } from 'ec-constants/routes';
import { sendKarteBuy, sendPurchase } from 'ec-services/gtmService';
import { sendBuy } from 'ec-services/nativeMessageSender';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { flattenProductTags } from '~/domains/product';
import { useCreditCards } from '~/hooks/creditCards';
import { useDeliveryDestination } from '~/hooks/delivery';
import { useDelivery } from '~/hooks/furusato/delivery';
import { useEstimation } from '~/hooks/furusato/estimation';
import { useMessageInPurchase } from '~/hooks/furusato/messageInPurchase';
import { usePaymentMethod } from '~/hooks/furusato/paymentMethod';
import { usePaymentTax } from '~/hooks/furusato/paymentTax';
import { usePointCustomer } from '~/hooks/furusato/pointCustomer';
import { useProduct } from '~/hooks/furusato/swr/product';
import { useOtherAddressForSending } from '~/hooks/otherAddressForSending';
import { usePurchaserAddresses } from '~/hooks/purchaserAddresses';
import i18n from '~/locale';
import { postAllocateStockForPurchase, postPurchase } from '~/repositories/furusato/purchase';
import { fetchMe } from '~/repositories/user';
import { creditCardActions } from '~/state/ducks/creditCard';
import { otherAddressForSendingActions } from '~/state/ducks/otherAddressForSending';
import { ageVerificationFieldSelectors } from '~/state/furusato/ducks/ageVerification';
import { deliveryActions } from '~/state/furusato/ducks/delivery';
import { estimationActions, estimationSelectors } from '~/state/furusato/ducks/estimation';
import { messageInPurchaseActions } from '~/state/furusato/ducks/messageInPurchase';
import { paymentMethodActions } from '~/state/furusato/ducks/paymentMethod';
import { purchaseActions, purchaseSelectors } from '~/state/furusato/ducks/purchase';
import { purchaseFormSelectors } from '~/state/furusato/ducks/purchaseForm';
export function usePurchaseState() {
    const dispatch = useDispatch();
    const { selectedPurchaserAddress } = usePurchaserAddresses();
    const { selectedCreditCard } = useCreditCards();
    const { selectedOtherAddressForSending } = useOtherAddressForSending();
    const { deliverToMe, deliverToOther } = useDeliveryDestination();
    const { estimatedResult } = useEstimation();
    const resetRelatedStateForPurchase = (disablePurchaseCompleted)=>{
        dispatch(creditCardActions.resetCreditCard());
        dispatch(deliveryActions.resetDelivery());
        dispatch(estimationActions.resetEstimatedResult());
        dispatch(otherAddressForSendingActions.resetOtherAddressForSending());
        dispatch(paymentMethodActions.resetPaymentMethod());
        dispatch(messageInPurchaseActions.setMessage(''));
        dispatch(purchaseActions.deleteAllocatedPurchaseItem());
        if (disablePurchaseCompleted) {
            dispatch(purchaseActions.setPurchaseCompleted(false));
        }
    };
    // TODO: 戻り値の正しい値がわからないので型にvoidを指定しておく
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    const availablePurchase = ()=>{
        if (!estimatedResult) {
            return false;
        }
        const { withinDeliveryArea, deliveryAreaByTemperatureControl } = estimatedResult;
        if (!withinDeliveryArea) {
            return false;
        }
        // NOTE: APIの互換性を保つため、 deliveryAreaByTemperatureControl がない場合は通るようにする
        // ４/５ リリース後にはずす
        if (deliveryAreaByTemperatureControl !== undefined && !deliveryAreaByTemperatureControl) {
            return false;
        }
        if (!selectedCreditCard) {
            return false;
        }
        if (selectedCreditCard.expired) {
            return false;
        }
        if (estimatedResult?.lessMinimumTax) {
            return false;
        }
        if (deliverToMe) {
            return !!selectedPurchaserAddress;
        }
        if (deliverToOther) {
            return !!selectedOtherAddressForSending;
        }
    };
    return {
        resetRelatedStateForPurchase,
        availablePurchase
    };
}
export function useStockInPurchaseProgress(productId, quantityId) {
    const dispatch = useDispatch();
    const allocatedPurchaseItem = useSelector(purchaseSelectors.allocatedPurchaseItem);
    const [apiError, setApiError] = useState(null);
    useEffect(()=>{
        if (!allocatedPurchaseItem) {
            postAllocateStockForPurchase(productId, quantityId).then((allocatedPurchaseItem)=>{
                dispatch(purchaseActions.setAllocatedPurchaseItem(allocatedPurchaseItem));
            }).catch((e)=>{
                setApiError(e);
            });
        }
    }, [
        productId,
        quantityId,
        allocatedPurchaseItem,
        dispatch
    ]);
    return {
        allocatedPurchaseItem,
        apiError
    };
}
export function usePurchaseDetermine() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const { paymentMethod } = usePaymentMethod();
    const { deliveryDate, deliveryTime } = useDelivery();
    const { deliverToMe, selectedDestinationAddress } = useDeliveryDestination();
    const { selectedPurchaserAddress } = usePurchaserAddresses();
    const { message } = useMessageInPurchase();
    const allocatedPurchaseItem = useSelector(purchaseSelectors.allocatedPurchaseItem);
    const { paymentTax } = usePaymentTax();
    const { productId } = useParams();
    const query = new URLSearchParams(useLocation().search);
    const quantityId = Number(query.get('quantity_id'));
    const { product } = useProduct(Number(productId), {
        includes: [
            'categories'
        ]
    });
    const quantity = product?.quantities.find((item)=>{
        return item.id === quantityId;
    });
    const { selectedCreditCard } = useCreditCards();
    const { customerId } = usePointCustomer();
    const navigate = useNavigate();
    const estimatedResult = useSelector(estimationSelectors.estimatedResult);
    const scheduledDeliveryKind = useSelector(purchaseFormSelectors.scheduledDeliveryKind);
    const preparationDays = useSelector(purchaseFormSelectors.preparationDays);
    const daysToShippingDeadline = useSelector(purchaseFormSelectors.daysToShippingDeadline);
    const quantityPriceJpy = useSelector(purchaseFormSelectors.quantityPriceJpy);
    const temperatureControl = useSelector(purchaseFormSelectors.temperatureControl);
    const ageVerification = useSelector(ageVerificationFieldSelectors.ageVerification);
    useEffect(()=>{
        if (!allocatedPurchaseItem || !selectedCreditCard || !paymentMethod || !selectedDestinationAddress?.id || !selectedPurchaserAddress?.id) {
            navigate(FURUSATO_PARAM_PATH.PURCHASE(Number(productId), quantityId));
            return;
        }
    }, [
        navigate,
        productId,
        quantityId,
        allocatedPurchaseItem,
        selectedCreditCard,
        paymentMethod,
        selectedDestinationAddress?.id,
        selectedPurchaserAddress?.id
    ]);
    const buildParamsForPurchase = useCallback(()=>{
        if (!quantity || !customerId || !scheduledDeliveryKind || !allocatedPurchaseItem || !selectedDestinationAddress || !selectedPurchaserAddress) {
            return null;
        }
        let _message = message;
        // 年齢確認チェックありの場合、申し送り事項に付与する
        if (ageVerification) {
            _message = [
                _message,
                '---',
                i18n.t('purchase:alcoholAgeVerification.attachedMessage')
            ].join('\n');
        }
        const purchaseParam = {
            purchaseId: allocatedPurchaseItem.id,
            paymentType: paymentMethod,
            pointCustomerId: Number(customerId),
            pocketPointCode: quantity.pocketPointCode,
            paymentTaxId: paymentTax?.id,
            deliveryDestinationId: Number(selectedDestinationAddress.id),
            deliveryRequestingCustomerId: deliverToMe ? null : Number(selectedPurchaserAddress.id),
            scheduledDeliveryKind,
            scheduledDeliveryDate: deliveryDate?.replace(/_/g, '-'),
            timeOfDay: deliveryTime,
            message: _message
        };
        return purchaseParam;
    }, [
        allocatedPurchaseItem,
        customerId,
        deliverToMe,
        deliveryDate,
        deliveryTime,
        message,
        paymentMethod,
        paymentTax?.id,
        quantity,
        scheduledDeliveryKind,
        selectedDestinationAddress,
        selectedPurchaserAddress,
        ageVerification
    ]);
    const setPurchase = async ()=>{
        const builtParams = buildParamsForPurchase();
        if (!product || !quantity || !builtParams || !estimatedResult || preparationDays === null || daysToShippingDeadline == null) {
            return false;
        }
        // 購入時商品パラメータ
        const contractProductParams = {
            preparationDays,
            daysToShippingDeadline,
            quantityPriceJpy,
            temperatureControl
        };
        setApiError(null);
        setIsLoading(true);
        return await postPurchase(builtParams, contractProductParams).then(async (postPurchaseResult)=>{
            // 購入イベントをGTMへ送信
            sendPurchase(product, quantity, postPurchaseResult, 'gmopg_payment', true);
            sendKarteBuy(product, quantity.point, postPurchaseResult, estimatedResult, null, true);
            // ユーザ情報を取り直し、iOSに送る
            const { purchaseCount } = await fetchMe();
            sendBuy({
                purchaseId: postPurchaseResult.id.toString(),
                product,
                productTags: flattenProductTags(product.tags),
                estimatedPriceItem: {
                    discountAmount: 0,
                    paymentFee: 0,
                    productPriceWithTax: 0,
                    reducedTaxTotalAmount: 0,
                    standardTaxTotalAmount: 0,
                    temperatureControlRate: 0,
                    totalAmount: estimatedResult.totalAmount,
                    totalDeliveryRate: 0
                },
                appliedCouponCode: '',
                price: quantity.price,
                tax: 0,
                purchaseCount: purchaseCount || 0,
                hasFurusatoPurchase: true
            });
            return true;
        }).catch((e)=>{
            setApiError(e);
            return false;
        }).finally(()=>{
            setIsLoading(false);
        });
    };
    return {
        setPurchase,
        isLoading,
        apiError
    };
}

import { SIGN_OUT } from '../common/actions';
import { RESET_DELIVERY, SET_DELIVERY_DESTINATION } from './actions';
const initialState = {
    deliveryDestination: 'me'
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_DELIVERY_DESTINATION:
            return {
                ...state,
                deliveryDestination: action.payload.deliveryDestination
            };
        case RESET_DELIVERY:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

import { SIGN_OUT } from '../common/actions';
import { ADD_LIKE_PRODUCT, REMOVE_LIKE_PRODUCT, RESET_USER, SET_IS_INITIALIZED, SET_LIKE_PRODUCTS, SET_ME, SET_OAUTH } from './actions';
const initialState = {
    isInitialized: false,
    me: null,
    likedProducts: [],
    oAuth: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_IS_INITIALIZED:
            return {
                ...state,
                isInitialized: action.payload.isInitialized
            };
        case SET_ME:
            return {
                ...state,
                me: action.payload.me
            };
        case ADD_LIKE_PRODUCT:
            {
                const { id } = action.payload;
                const found = state.likedProducts.indexOf(id);
                if (found !== -1) {
                    return state;
                }
                return {
                    ...state,
                    likedProducts: [
                        ...state.likedProducts,
                        id
                    ]
                };
            }
        case REMOVE_LIKE_PRODUCT:
            {
                const { id } = action.payload;
                return {
                    ...state,
                    likedProducts: [
                        ...state.likedProducts.filter((data)=>data !== id)
                    ]
                };
            }
        case SET_LIKE_PRODUCTS:
            {
                const { ids } = action.payload;
                return {
                    ...state,
                    likedProducts: ids
                };
            }
        case RESET_USER:
            {
                return {
                    ...state,
                    ...initialState
                };
            }
        case SIGN_OUT:
            {
                return {
                    ...state,
                    ...{
                        isInitialized: true,
                        me: null,
                        likedProducts: [],
                        oAuth: null
                    }
                };
            }
        case SET_OAUTH:
            {
                return {
                    ...state,
                    oAuth: action.payload.oAuth
                };
            }
        default:
            return state;
    }
}

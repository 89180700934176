import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { messageInPurchaseActions, messageInPurchaseSelectors } from '~/state/furusato/ducks/messageInPurchase';
export function useMessageInPurchase() {
    const dispatch = useDispatch();
    const message = useSelector(messageInPurchaseSelectors.message);
    const setMessage = useCallback((message)=>{
        dispatch(messageInPurchaseActions.setMessage(message));
    }, [
        dispatch
    ]);
    return {
        message,
        setMessage
    };
}

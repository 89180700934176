const producer = {
    commercialCodeTitle: '特定商取引法に基づく表記',
    bestProducer: 'マークのついた生産者さんは、過去1年間で平均して特に高い評価を得ています。',
    bestProducerPromotion: {
        caption: '生産者バッジの基準が新しくなりました。',
        linkText: '詳しくはこちら'
    },
    media: {
        title: '{{name}}さんのメディア',
        snsUrl: {
            facebook: 'https://www.facebook.com/{{name}}/',
            instagram: 'https://www.instagram.com/{{name}}/',
            x: 'https://x.com/{{name}}/',
            line: 'https://page.line.me/{{name}}?openQrModal=true'
        }
    },
    products: {
        title: '{{fullName}}さんの商品',
        notFound: '生産者の商品はありません'
    }
};
export default producer;

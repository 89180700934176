import { useEffect } from 'react';
import { ValidationError } from 'yup';
/**
 * RHFのビルトインバリデーションに加え、スキーマによるカスタムバリデーションを適用するためのHook
 */ export function useCustomSchemaValidation({ schema, values, onError, isValidating = false }) {
    useEffect(()=>{
        // RHFビルトインバリデーションの完了を待つ
        if (isValidating || values === undefined || values === null) {
            return;
        }
        try {
            schema.validateSync(values);
        } catch (err) {
            if (ValidationError.isError(err) && err.path) {
                onError(err.path, {
                    type: err.type,
                    message: err.message
                });
            }
        }
    // formValues全体の変更によりeffectをトリガする
    }, [
        isValidating,
        onError,
        schema,
        values
    ]);
}

const creditCard = {
    CreditCardForm: {
        title: '新しいクレジットカードの登録',
        submit: '登録して、このカードを使用する',
        save: '登録する',
        subscriptionNotice: 'チェックを入れると、すでに定期購入中・予約済みの注文がある場合、次回のお支払い分以降このカードを使用します。'
    },
    creditCardList: {
        title: 'クレジットカードの選択',
        caution: '※最大5枚まで登録可能',
        select: 'このクレジットカードを選択する',
        edit: '編集',
        delete: '削除',
        main: 'メインクレジットカード',
        add: 'クレジットカードを追加する',
        payment: 'お支払い',
        paymentMethod: 'お支払い方法'
    },
    disabledRegisterCard: {
        text: '現在、新しいクレジットカードは登録できません。',
        detail: '登録できるクレジットカードは5枚までです。新しいクレジットカードを登録する場合は、現在登録中のいずれかのカードの削除をお願いいたします。',
        link: 'クレジットカードの削除はこちら'
    },
    DeleteDialog: {
        confirm: '登録したクレジットカードを削除しますか?',
        delete: '削除する',
        cancel: 'キャンセル'
    },
    reCaptcha: {
        caption1: 'This site is protected by reCAPTCHA and the Google ',
        privacy: 'Privacy Policy',
        privacyUrl: 'https://policies.google.com/privacy',
        caption2: ' and ',
        terms: 'Terms of Service',
        termsUrl: 'https://policies.google.com/terms',
        caption3: ' apply.'
    },
    form: {
        HolderName: {
            title: 'カード名義人',
            placeholder: 'TARO TANAKA'
        },
        CardNumber: {
            title: 'カード番号',
            placeholder: '1234567890123456'
        },
        Expiration: {
            title: '有効期限（月/年）',
            placeholder: '04/29'
        },
        ExpirationMonth: {
            title: '有効期限',
            placeholder: '月'
        },
        ExpirationYear: {
            placeholder: '年'
        },
        SecurityCode: {
            title: 'セキュリティコード',
            placeholder: '000'
        },
        RegisterAsMain: {
            title: 'メインのカードとして登録する'
        },
        SubscriptionPaymentChecked: {
            title: '定期購入・予約済み商品のお支払いに適用する'
        }
    }
};
export default creditCard;

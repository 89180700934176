const defaultHtmlFontSize = 16;
/**
 * @deprecated ec-react(src/styles/contents/size.ts)の方を使用してください
 */ export function pxToRem(px) {
    return `${px / defaultHtmlFontSize}rem`;
}
/**
 * @deprecated ec-react(src/styles/contents/size.ts)の方を使用してください
 */ export function remToPxNumber(rem) {
    const num = Number(rem.replace(/rem/, ''));
    return num * defaultHtmlFontSize;
}
/**
 * @deprecated ec-react(src/styles/contents/size.ts)の方を使用してください
 * ec-reactの方と微妙に違うのでマージする必要がある
 */ export const size = {
    font: {
        XL: pxToRem(20),
        L: pxToRem(18),
        M: pxToRem(16),
        S: pxToRem(12),
        XS: pxToRem(10),
        XXS: pxToRem(8)
    },
    fontWeight: {
        W3: '300',
        W4: '400',
        W5: '500',
        W6: '600'
    },
    breakpoint: {
        MIN: 320,
        TABLET: 768,
        PC: 1100
    },
    width: {
        CONTENT_MAX_WITH_PADDING: 1300,
        CONTENTS_MAX: 1200,
        CONTENTS_MID_LARGE: 800,
        CONTENTS_MID: 500
    },
    height: {
        spHeader: 104,
        tabletAndPcHeader: 80,
        copyrightFooter: 61
    },
    borderRadius: {
        MESSAGE: '15px',
        NORMAL: '8px',
        BUTTON: '6px',
        CIRCLE: '1000px'
    }
};

import { getFirstCategoryName } from '../../_shared/utils';
// TODO 一旦ここに
const shouldShowingTag = (tag)=>{
    return tag.state === 'published';
};
/**
 * タグデータをフラット化し有効なもののテキスト部分のみを返す
 * @param tags タグデータ
 * @returns {string[]} eg. ['#セット', '#タグ']
 */ export const flattenProductTags = (tags)=>{
    return Object.values(tags).reduce((accumulator, item)=>accumulator.concat(item), []).filter((tag)=>shouldShowingTag(tag)).map(({ name })=>name);
};
/**
 * view_item_listイベントで利用するitem_list_name名に変換
 * @param listName
 * @returns
 */ export const optimizeListName = (listName)=>{
    switch(listName){
        case 'ProducerProducts':
            return '生産者商品一覧';
        case 'SearchedProducts':
            return '商品一覧';
        case 'CategoryProducts':
            return 'カテゴリ詳細';
        case 'LikedProduct':
        case 'LikedFurusatoProduct':
            return 'お気に入り一覧';
        default:
            return listName;
    }
};
/**
 * GET Gtm authorization Method from authorizedProvider
 * @param authorizedProvider ログイン nul可
 * @returns GtmLoginDataLayer['method']か nullか eg. Apple
 */ export const getGtmAuthorizationMethod = (authorizedProvider)=>{
    switch(authorizedProvider){
        case 'apple':
            return 'Apple';
        case 'mail':
            return 'Mail';
        case 'line':
            return 'Line';
        default:
            return null;
    }
};
// ECとfurusatoの商品データをdataLayerのItem型に変換
export const convertProductIntoItem = (product, quantity)=>{
    const tags = flattenProductTags(product.tags);
    // 末端のカテゴリを取得
    const { leafCategoryName } = getFirstCategoryName(product.categories);
    /* eslint-disable @typescript-eslint/naming-convention */ return {
        item_id: product.id.toString(),
        item_name: product.title,
        currency: 'JPY',
        index: 0,
        item_brand: product.user.id.toString(),
        item_category: leafCategoryName,
        item_variant: quantity ? quantity.description : '',
        price: quantity ? quantity.price : 0,
        location_id: product.user.state,
        has_furusato_quantity: product.hasFurusatoQuantity,
        tags,
        product_type: product.type,
        coupon_type: product.autoCoupon.type
    };
/* eslint-enable @typescript-eslint/naming-convention */ };

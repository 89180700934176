import styled from 'styled-components';
import { color, pxToRem, size } from '~/styles/constants';
export const Container = styled.li`
  display: flex;
  border-bottom: 1px solid ${color.border.GRAY};
  font-size: ${pxToRem(14)};
  font-weight: ${size.fontWeight.W6};
  a {
    display: block;
    width: 100%;
    padding: 1rem;
  }
`;
export const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: block;
    margin-left: auto;
  }
`;

import { lighten } from 'polished';
import styled, { css } from 'styled-components';
import { transition } from '~/styles/animations';
import { color, size } from '~/styles/constants';
const disabledStyle = (bgColor = color.WHITE)=>css`
  pointer-events: none;
  ${bgColor === color.WHITE ? css`
        color: ${color.font.SUB};
        border: ${color.border.GRAY} solid 1px;
      ` : bgColor === color.background.GRAY ? css`
          color: ${color.font.SUB};
          background-color: ${lighten(0.05, color.background.GRAY)};
        ` : css`
          background-color: #d3d3d3;
        `}
`;
const baseStyle = (bgColor = color.WHITE, fontSize = size.font.S, full = false)=>css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${full ? '100%' : 'auto'};
  height: 40px;
  padding: 0 16px;
  color: ${bgColor === color.background.GRAY ? color.font.MAIN : bgColor !== color.WHITE ? color.WHITE : color.PRIMARY};
  background-color: ${bgColor};
  /* stylelint-disable unit-no-unknown */
  border: ${bgColor !== color.WHITE ? 'none' : `${color.PRIMARY} solid 1px`};
  /* stylelint-enable unit-no-unknown */
  border-radius: ${size.borderRadius.BUTTON};
  font-size: ${fontSize};
  font-weight: ${size.fontWeight.W6};
  cursor: pointer;
  ${transition.hoverBtn}
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    ${disabledStyle(bgColor)}
  }
`;
/**
 * @deprecated parts/_common/button/StanderdButton を使用してください
 */ export const StandardButton = styled.button`
  outline: none;
  ${(props)=>baseStyle(props.$bgColor, props.$fontSize, props.$full)}
`;
/**
 * @deprecated button/StanderdButton/StandardButton.tsx の方を使用してください
 * NOTE: disabledはspanの属性ではないので、つけ替えている
 */ export const StandardButtonInLink = styled.span`
  ${(props)=>baseStyle(props.$bgColor, props.$fontSize, props.$full)}
  ${(props)=>props.$disabled && disabledStyle(props.$bgColor)}
  display: inline-flex;
`;

import { getLCP, splitString, traverse } from './utils';
/**
 * SuffixTreeクラス
 */ class SuffixTree {
    root;
    #dict;
    constructor(){
        this.root = {
            children: {},
            value: []
        };
        this.#dict = {};
    }
    /**
   * Trie構造のデータを構築する
   * @param src 登録する単語のデータ
   */ buildTree(src) {
        for (const { string, id, categoryParams: { left, parentId } } of src){
            // idとデータを紐づける辞書を生成する
            if (!this.#dict[id]) {
                this.#dict[id] = {
                    word: string,
                    categoryParams: {
                        left,
                        parentId: parentId || null
                    }
                };
            }
            // 単語を分割してTrie木に挿入する
            const suffixes = splitString(string);
            for (const [suffix, idx] of suffixes){
                this.#insert(suffix, id, idx);
            }
        }
    }
    /**
   * Trie木に文字列を挿入する
   * @param string 挿入する文字列
   * @param id 文字列に紐づくid
   * @param idx 文字の位置
   */ #insert(string, id, idx) {
        // ルートノードを検索し、一致する最後のノードと残りの文字列を取得する
        const res = this.#query(string);
        let { node } = res;
        const { remainingString } = res;
        // 残りの文字列がある場合、 見つけた最後のノードにエッジを追加または分割する。
        if (remainingString) {
            node = this.#addEdge(node, remainingString);
        }
        let leaf = node;
        // もしパスが内部ノードで終わる場合は、終端のleaf('$')を追加する
        if (Object.values(node.children).length > 0) {
            leaf = node.children.$ ||= {
                value: [],
                children: {}
            };
        }
        // リーフノードにこの文字列(string)に関連付けられたid等を設定する
        leaf.value.push([
            id,
            idx
        ]);
    }
    /**
   * 与えられた文字列に対してTrieの中を探索し、一致する最長の接頭辞とその後の残り文字列を返す
   * @param string 探索する文字列
   * @param node 探索を開始するノード
   * @returns { node, remainingString } 一致する最後のノードと残りの文字列
   */ #query(string, node = this.root) {
        let queryString = '';
        for (const char of string){
            queryString += char;
            // エッジがその接頭辞に一致するかどうかをチェックする。
            const nextNode = node.children[queryString];
            // ノードが存在する場合、残りの文字列でそのノードを再帰検索する。
            if (nextNode) {
                const remainingString = string.slice(queryString.length);
                return this.#query(remainingString, nextNode);
            }
        }
        // 最後にマッチしたノードとマッチしていない残りの文字列（完全一致した場合は空文字）を返す
        return {
            node,
            remainingString: string
        };
    }
    /**
   * 共通の接頭辞でエッジを分割できない場合、メインノードに新しいエッジを追加する。
   * @param node 新しいエッジを追加するノード
   * @param string 新しいエッジの文字列
   * @returns 新しいエッジの終端ノード
   */ #addEdge(node, string) {
        let targetParent = node;
        let newEdge = string;
        // ノードのエッジを反復処理し、分割するエッジを探す
        for (const [edge, subtree] of Object.entries(node.children)){
            // edge(key)とstringの間のLCPを取得する
            const lcp = getLCP(edge, string);
            // LCPが存在する場合、エッジをlcpで分割し、残りを新しいノードとして追加する
            if (lcp) {
                // 共通の接頭辞の新しいノードを作成
                const prefixNode = {
                    value: [],
                    children: {}
                };
                node.children[lcp] = prefixNode;
                // 新しい接頭辞のノードの下に元のサブツリーを移動し、元のエッジを削除する
                const remainingEdge = edge.slice(lcp.length);
                prefixNode.children[remainingEdge] = subtree;
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete node.children[edge];
                if (lcp === string) {
                    // この接頭辞ノードはパスの終端であり、新しいノードを追加する必要はない
                    return prefixNode;
                }
                // 新しいエッジとノードを追加するためのターゲット親を更新する
                targetParent = prefixNode;
                newEdge = string.slice(lcp.length);
                break;
            }
        }
        // 指定されたノードに新しいエッジ/ノードを追加して、文字列のパスを作成する
        const endNode = {
            value: [],
            children: {}
        };
        targetParent.children[newEdge] = endNode;
        return endNode;
    }
    /**
   * ヒットしたidを元に、マッチしたデータを生成する
   * @param ids ヒットしたワードのidの配列
   * @param limit 返すデータの最大件数
   */ #mapIDs(ids, limit = 10) {
        const uniqueIDs = new Map();
        for (const [id, idx] of ids){
            if (uniqueIDs.has(id)) {
                continue;
            }
            uniqueIDs.set(id, [
                id,
                idx
            ]);
        }
        const uniqueIDsArray = [
            ...uniqueIDs.values()
        ];
        const matches = uniqueIDsArray.map(([id, index])=>{
            const data = this.#dict[id];
            return {
                id,
                word: data?.word || '',
                _offset: index,
                categoryParents: this.#getParents(id),
                _priority: data?.categoryParams?.left || 0
            };
        });
        // 第一ソートをoffset, 第二ソートをpriority(現状はleft)とする
        matches.sort((a, b)=>{
            if (a._offset === b._offset) {
                return a._priority - b._priority;
            }
            return a._offset - b._offset;
        });
        return matches.slice(0, limit);
    }
    /**
   * dictデータから親の情報を再帰的に取得する
   * 取得したデータはルートに近い要素が先頭になるようにする
   * @param id
   * @returns 親要素の配列
   */ #getParents(id) {
        const parents = [];
        let parentId = this.#dict[id]?.categoryParams?.parentId;
        while(parentId && this.#dict[parentId]){
            parents.unshift({
                id: parentId,
                word: this.#dict[parentId].word
            });
            parentId = this.#dict[parentId]?.categoryParams?.parentId;
        }
        return parents;
    }
    /**
   * autoComplete(部分一致)を検索する
   * @param string 検索する文字列
   * @param limit 返すデータの最大件数
   */ autoComplete(string, limit = 10) {
        if (!string) {
            return {
                matches: []
            };
        }
        const { node, remainingString } = this.#query(string, this.root);
        let searchRoot = undefined;
        // すでに完全に一致している場合、パスの末尾を検索する
        if (!remainingString) {
            searchRoot = node;
        } else {
            // マッチしない場合、最後のノードからエッジの一部を使ってマッチを完了しようとする
            for (const [edge, childNode] of Object.entries(node.children)){
                // エッジと残りの文字列の共通の接頭辞を取得する
                const lcp = getLCP(edge, remainingString);
                if (!lcp) {
                    continue;
                }
                if (lcp === remainingString) {
                    searchRoot = childNode;
                }
                break;
            }
        }
        // no matches
        if (!searchRoot) {
            return {
                matches: []
            };
        }
        const matchedIDs = traverse(searchRoot);
        return {
            matches: this.#mapIDs(matchedIDs, limit)
        };
    }
}
export default SuffixTree;

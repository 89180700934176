import { useState } from 'react';
import { usePaymentTax } from '~/hooks/furusato/paymentTax';
import { postContribution } from '~/repositories/furusato/contribution';
export function useContribution() {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setPaymentTax } = usePaymentTax();
    const createContributionPost = async (data, quantity, amount)=>{
        setError(null);
        setIsLoading(true);
        return await postContribution(data, quantity, amount).then((res)=>{
            setPaymentTax(res);
            setIsLoading(false);
            return true;
        }).catch((e)=>{
            setIsLoading(false);
            setError(e);
            return false;
        });
    };
    return {
        error,
        isLoading,
        createContributionPost
    };
}

import { FURUSATO_ROUTER_PATH, ROUTER_PATH } from 'ec-constants/routes';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { searchBarActions, searchBarSelectors } from '~/state/ducks/searchBar';
export function useSearchBar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const text = useSelector(searchBarSelectors.text);
    const setSearchBarText = useCallback((text)=>{
        dispatch(searchBarActions.setInputSearchText(text));
    }, [
        dispatch
    ]);
    //他ページ遷移時に検索ワードが残存しないようにリセットする
    useEffect(()=>{
        return ()=>{
            const { PRODUCER_LIST } = ROUTER_PATH;
            const { PRODUCT_LIST } = FURUSATO_ROUTER_PATH;
            const nextPath = location.pathname;
            if (nextPath !== PRODUCT_LIST && nextPath !== PRODUCER_LIST) {
                setSearchBarText('');
            }
        };
    }, [
        location,
        setSearchBarText
    ]);
    return {
        text,
        setSearchBarText
    };
}

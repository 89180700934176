import useSWR from 'swr';
import { getProductPath, swrFetcher } from '~/repositories/furusato/product';
export function useProduct(id, options) {
    const { data, error } = useSWR(getProductPath(id, options?.includes), swrFetcher);
    return {
        product: data,
        error,
        loading: !data
    };
}

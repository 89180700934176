import { ROUTER_PATH } from 'ec-constants/routes';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { otherAddressForSendingActions } from '~/state/ducks/otherAddressForSending';
import { purchaserAddressActions } from '~/state/ducks/purchaserAddress';
export function usePurchaseAddress() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const linkWithFrom = state?.linkWithFrom;
    const setSelectedAddress = (deliverTo, createdAddress)=>{
        if (linkWithFrom !== ROUTER_PATH.MY_PAGE) {
            if (deliverTo === 'other') {
                dispatch(otherAddressForSendingActions.setSelectedOtherAddressForSending(createdAddress));
            }
            if (deliverTo === 'me') {
                dispatch(purchaserAddressActions.setSelectedPurchaserAddress(createdAddress));
            }
            return navigate(linkWithFrom);
        }
    };
    return {
        setSelectedAddress
    };
}

import { css } from 'styled-components';
import { size } from './constants';
export const breakPoint = {
    PHONE: (...args)=>css`
    @media (max-width: ${size.breakpoint.TABLET - 1}px) {
      ${args};
    }
  `,
    TABLET: (...args)=>css`
    @media (min-width: ${size.breakpoint.TABLET}px) {
      ${args};
    }
  `,
    PC: (...args)=>css`
    @media (min-width: ${size.breakpoint.PC}px) {
      ${args};
    }
  `
};
export const lineClamp = (line)=>css`
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-enable value-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
  overflow: hidden;
`;
export const contentsMaxWidth = (width)=>css`
  ${breakPoint.PC(css`
    width: 100%;
    margin: 0 auto;
    max-width: ${width || size.width.CONTENTS_MAX}px;
  `)}
  ${width === size.width.CONTENT_MAX_WITH_PADDING && breakPoint.PC(css`
    padding-left: 50px;
    padding-right: 50px;
  `)}
`;
/**
 * スクロールバーを消すcss
 * @see https://github.com/reslear/tailwind-scrollbar-hide/blob/main/src/index.js
 */ export const scrollbarHide = ()=>css`
  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;

  /* Safari and Chrome */
  &::-webkit-scrollbar {
    display: none;
  }
`;

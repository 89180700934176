import { useDispatch, useSelector } from 'react-redux';
import { deliveryActions, deliverySelectors } from '~/state/furusato/ducks/delivery';
export function useDelivery() {
    const dispatch = useDispatch();
    const deliveryDate = useSelector(deliverySelectors.deliveryDate);
    const deliveryTime = useSelector(deliverySelectors.deliveryTime);
    const setDeliveryDate = (deliveryDate)=>{
        dispatch(deliveryActions.setDeliveryDate(deliveryDate));
    };
    const setDeliveryTime = (deliveryTime)=>{
        dispatch(deliveryActions.setDeliveryTime(deliveryTime));
    };
    return {
        deliveryDate,
        deliveryTime,
        setDeliveryDate,
        setDeliveryTime
    };
}

import styled, { css } from 'styled-components';
export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;

  @media all and (-ms-high-contrast: none) {
    /** IE fallback */
    display: flex;
    overflow: hidden;
  }
`;
export const Img = styled.img`
  ${(props)=>props.$objectFit !== 'none' && css`
      width: 100%;
      align-self: center; /** IEの場合はwidth 100%に揃えて中央表示させる */

      @supports (object-fit: cover) and (object-fit: contain) {
        height: 100%;
        object-fit: ${props.$objectFit};
      }
    `}
`;

import { useEffect, useMemo, useState } from 'react';
export function useFilterCategory(selectedCategory, onSearchCategory, categories) {
    // モーダル内で選択してるカテゴリを保持
    const [currentViewCategoryNode, setCurrentViewCategoryNode] = useState(null);
    /**
   * デフォルトの選択状態を設定
   */ useEffect(()=>{
        // 未選択
        if (!selectedCategory) {
            setCurrentViewCategoryNode(null);
            return;
        }
        if (selectedCategory.parentId) {
            // 子のカテゴリを選択している場合
            if (!selectedCategory.isParent()) {
                // 末端のカテゴリを選択している場合
                setCurrentViewCategoryNode(selectedCategory.parent);
            } else {
                // 「すべて」を選択（ルートでも末端でも中間のノードを選択している場合）
                setCurrentViewCategoryNode(selectedCategory);
            }
        } else {
            // ルートのカテゴリを選択している場合
            if (!selectedCategory.isParent()) {
                // 子のカテゴリがない場合
                setCurrentViewCategoryNode(null);
            } else {
                // 子のカテゴリがある場合、そのページを表示する
                setCurrentViewCategoryNode(selectedCategory);
            }
        }
    }, [
        categories,
        selectedCategory
    ]);
    /**
   * カテゴリ選択時に検索を行う
   */ const selectCategory = (categoryId)=>{
        const node = getCategoryNode(categoryId);
        if (node === null) {
            return;
        }
        // 子カテゴリがない場合は検索を実行
        if (!node.isParent()) {
            onSearchCategory(categoryId);
            return;
        }
        setCurrentViewCategoryNode(node);
    };
    /**
   * 上の階層のカテゴリに戻る処理
   */ const moveUpHigherCategory = ()=>{
        if (currentViewCategoryNode === null) {
            return;
        }
        const node = categories.findSingleNode({
            id: currentViewCategoryNode.attributes.id
        });
        if (node === null || node.parentId === null) {
            // ルートに戻る
            setCurrentViewCategoryNode(null);
        } else {
            // 一つ上の階層に戻る
            setCurrentViewCategoryNode(node.parent);
        }
    };
    /**
   * 表示するカテゴリのリストを設定
   */ const categoryNodeTreeList = useMemo(()=>{
        return currentViewCategoryNode ? currentViewCategoryNode.children() : categories.getRoots();
    }, [
        categories,
        currentViewCategoryNode
    ]);
    /**
   * カテゴリIDからカテゴリNodeを取得
   */ const getCategoryNode = (categoryId)=>{
        return categories.findSingleNode({
            id: categoryId
        });
    };
    return {
        currentViewCategoryNode,
        selectCategory,
        moveUpHigherCategory,
        categoryNodeTreeList,
        getCategoryNode
    };
}

export const SET_SUBSCRIPTION_DESTINATION = 'subscriptionDestination/SET_SUBSCRIPTION_DESTINATION';
export const setSubscriptionDestination = (subscriptionDestination)=>({
        type: SET_SUBSCRIPTION_DESTINATION,
        payload: {
            subscriptionDestination
        }
    });
export const RESET_SUBSCRIPTION_DESTINATION = 'subscriptionDestination/RESET_SUBSCRIPTION_DESTINATION';
export const resetSubscriptionDestination = ()=>({
        type: RESET_SUBSCRIPTION_DESTINATION
    });
export default {
    setSubscriptionDestination,
    resetSubscriptionDestination
};

/**
 * width,height,cropからサイズのパスを生成する
 * @see https://www.resizing.net/documents/images#_%E6%8B%A1%E5%A4%A7%E7%B8%AE%E5%B0%8F
 *
 * @returns サイズのパスの文字列。 例: "c_fill,w_500,h_500"
 */ const generateSizePath = ({ width, height, crop })=>{
    const sizePathArray = [];
    switch(crop){
        case 'fill':
            sizePathArray.push('c_fill');
            break;
        case 'fit':
            sizePathArray.push('c_fit');
            break;
        case 'limit':
            sizePathArray.push('c_limit');
            break;
        default:
            break;
    }
    if (width) {
        sizePathArray.push(`w_${width}`);
    }
    if (height) {
        sizePathArray.push(`h_${height}`);
    }
    return sizePathArray.join(',');
};
/**
 * formatからフォーマットのパスを生成する
 * @see https://www.resizing.net/documents/images#_format%E5%A4%89%E6%8F%9B
 *
 * @returns フォーマットのパスの文字列。 例: "f_png"
 */ const generateFormatPath = ({ format })=>{
    switch(format){
        case 'png':
            return 'f_png';
        case 'jpg':
            return 'f_jpg';
        case 'webp':
            return 'f_webp';
        case 'avif':
            return 'f_avif';
        case 'auto':
            return 'f_auto';
        default:
            return '';
    }
};
/**
 * resizing.netの画像URLをパラメータ付きで生成する
 * @see https://www.resizing.net/documents/images
 */ export const generateResizingImageUrl = ({ imageUrl, width, height, crop, format })=>{
    const url = imageUrl.replace(/\/$/, '');
    const sizePath = generateSizePath({
        width,
        height,
        crop
    });
    const formatPath = generateFormatPath({
        format
    });
    const paths = [
        sizePath,
        formatPath
    ].filter(Boolean).join('/');
    return `${url}${paths ? `/${paths}` : ''}`;
};

const SET_AGE_VERIFICATION = 'ageVerification/SET_AGE_VERIFICATION';
const setAgeVerification = (ageVerification)=>({
        type: SET_AGE_VERIFICATION,
        payload: {
            ageVerification
        }
    });
export default {
    SET_AGE_VERIFICATION,
    setAgeVerification
};

import { SIGN_OUT } from '~/state/ducks/common/actions';
import { RESET_ESTIMATED_RESULT, SET_ESTIMATED_RESULT } from './actions';
const initialState = {
    estimatedRedult: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_ESTIMATED_RESULT:
            return {
                ...state,
                estimatedRedult: action.payload.estimatedResult
            };
        case RESET_ESTIMATED_RESULT:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

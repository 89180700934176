// GTMの計測用class名を定義
const GTM_EVENT_CLASSNAMES = {
    BREADCRUMB_NAV: 'gtm__pankuzu',
    FAV_MENU: 'gtm__fav_menu',
    HUMBERGER_MENU: 'gtm__humberger_menu',
    SEARCH_MENU: 'gtm__search_menu',
    SUBBANNER: 'gtm__subbanner',
    MORE_PRODUCTS_BUTTON: 'gtm__more_products_button',
    HUMBERGER_MENU_LINK: 'gtm__humbergermenu_link',
    CATEGORY_ON_TOP: 'gtm__category_on_top',
    CATEGORY_ON_PRODUCT_DETAIL: 'gtm__category_on_product_detail',
    CATEGORY_LIST_RECOMMENDED: 'gtm__recommended_category',
    CATEGORY_LIST_CHILDREN: 'gtm__children_category_list',
    CATEGORY_LIST_SIBLINGS: 'gtm__siblings_category_list',
    SEARCH_FILTER: 'gtm__search_filter',
    SEARCH_TAG: 'gtm__search_tag',
    SEARCH_CATEGORY: 'gtm__search_category',
    SEARCH_AREAS: 'gtm__search_areas',
    SEARCH_OTHER_FILTERS: 'gtm__search_other_filters',
    SEARCH_FILTER_EC: 'gtm__filter_ec',
    MAINBANNER: 'gtm__mainbanner',
    FOOTER_LINK: 'gtm__footer_link',
    FOOTER_SNS_LINK: 'gtm__footer_sns_link',
    SEARCH_BUTTON_ON_SEARCH_MENU: 'gtm__search_button_on_search_menu',
    CATEGORY_ON_SEARCH_MENU: 'gtm__category_on_search_menu',
    CLOSE_BUTTON_ON_SEARCH_MENU: 'gtm__close_button_on_search_menu',
    PRODUCER_LINK_ON_PRODUCT_DETAIL: 'gtm__producer_link_on_product_detail',
    REQUEST_QUANTITY_LINK: 'gtm__request_quantity_link',
    TAG_ON_PRODUCER_DETAIL: 'gtm__tag_on_producer_detail',
    CONTACT_PRODUCER_LINK: 'gtm__contact_producer_link',
    CARD_MODAL: 'gtm__card_modal',
    NEW_CARD_MODAL: 'gtm__new_card_modal',
    SET_NEW_CARD_BUTTON: 'gtm__set_new_card_button',
    QUANTITY_OPTION: 'gtm__quantity_option',
    REORDER_LINK: 'gtm__reorder_button',
    CONSUMER_POST_LINK: 'gtm__gochi_button'
};
export default GTM_EVENT_CLASSNAMES;

import { FURUSATO_PRODUCT_LIST_PARAM, FURUSATO_ROUTER_PATH } from 'ec-constants/routes';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { SCHEDULED_DELIVERIES } from '~/types/furusato/productOthers';
function getSearchParams({ keyword, categoryId, order, prefectures, localGovernments, lowerPrice, higherPrice, scheduledDeliveries, page }) {
    const searchParams = new URLSearchParams();
    const { SEARCH_KEY } = FURUSATO_PRODUCT_LIST_PARAM;
    if (keyword) {
        searchParams.append(SEARCH_KEY.WORDS, keyword);
    }
    if (categoryId) {
        searchParams.append(SEARCH_KEY.CATEGORY, categoryId);
    }
    if (order && order !== 'popular') {
        searchParams.append(SEARCH_KEY.SORT_ORDER, order);
    }
    if (localGovernments.length > 0) {
        searchParams.append(SEARCH_KEY.LOCAL_GOVERNMENTS, localGovernments.join(','));
    } else if (prefectures.length > 0) {
        // 都道府県のみがチェックされている場合だけSEARCH_KEY.PREFECTURESで検索する(都道府県と自治体の混合検索はNG)
        prefectures.forEach((prefecture)=>{
            searchParams.append(SEARCH_KEY.PREFECTURES, prefecture);
        });
    }
    if (lowerPrice) {
        searchParams.append(SEARCH_KEY.LOWER_PRICE, lowerPrice);
    }
    if (higherPrice) {
        searchParams.append(SEARCH_KEY.HIGHER_PRICE, higherPrice);
    }
    if (scheduledDeliveries.length > 0) {
        scheduledDeliveries.forEach((scheduledDelivery)=>{
            searchParams.append(SEARCH_KEY.SCHEDULED_DELIVERIES, scheduledDelivery);
        });
    }
    if (page && page > 1) {
        searchParams.append(SEARCH_KEY.PAGE_NO, page.toString());
    }
    return searchParams.toString();
}
export function useProductSearch() {
    const productSearchPath = (option)=>{
        const searchParams = getSearchParams(option);
        const params = searchParams.length > 0 ? `?${searchParams}` : '';
        return `${FURUSATO_ROUTER_PATH.PRODUCT_LIST}${params}`;
    };
    const searchProducts = (option)=>{
        location.assign(productSearchPath(option));
    };
    return {
        searchProducts,
        productSearchPath
    };
}
export function useProductPrefectures() {
    const convertPrefectureListToLabels = (prefectures)=>{
        const checkedPrefectures = prefectures.filter((pref)=>pref.isChecked);
        const checkedPrefectureLabels = checkedPrefectures.map((pref)=>pref.label);
        return checkedPrefectureLabels.join(', ');
    };
    return {
        convertPrefectureListToLabels
    };
}
export function useProductLocalGovernments() {
    const convertLocalGovernmentValueToLabels = (localGovernmentValues, allLocalGovernments)=>{
        const localGovernmentLabels = localGovernmentValues.reduce((accumulator, currentId)=>{
            const matchedLabel = allLocalGovernments?.find((localGovernment)=>localGovernment.id === Number(currentId))?.name;
            return matchedLabel ? [
                ...accumulator,
                matchedLabel
            ] : accumulator;
        }, []);
        return localGovernmentLabels;
    };
    const getCheckedLocalGovernmentsFromIds = (localGovernmentList, checkedPrefectureValues)=>{
        return localGovernmentList.filter((localGovernment)=>!checkedPrefectureValues.includes(localGovernment.prefecture) && localGovernment.isChecked).map((localGovernment)=>localGovernment.value);
    };
    const convertLocalGovernmentListToLabels = (prefectures, localGovernmentList, allLocalGovernments)=>{
        const checkedPrefectureValues = prefectures.filter((prefecture)=>prefecture.isChecked).map((prefecture)=>prefecture.value);
        const checkedLocalGovernments = getCheckedLocalGovernmentsFromIds(localGovernmentList, checkedPrefectureValues);
        const convertedLocalGovernments = convertLocalGovernmentValueToLabels(checkedLocalGovernments, allLocalGovernments);
        return convertedLocalGovernments;
    };
    return {
        convertLocalGovernmentListToLabels,
        convertLocalGovernmentValueToLabels
    };
}
export function useSearchParams() {
    const search = useLocation().search;
    const query = useMemo(()=>new URLSearchParams(search), [
        search
    ]);
    const { SEARCH_KEY } = FURUSATO_PRODUCT_LIST_PARAM;
    let category = query.get(SEARCH_KEY.CATEGORY) || '';
    const order = query.get(SEARCH_KEY.SORT_ORDER) || '';
    const text = query.get(SEARCH_KEY.WORDS) || '';
    const prefectures = query.getAll(SEARCH_KEY.PREFECTURES) || [];
    const localGovernments = query.get(SEARCH_KEY.LOCAL_GOVERNMENTS)?.split(',') || [];
    const lowerPrice = query.get(SEARCH_KEY.LOWER_PRICE) || '';
    const higherPrice = query.get(SEARCH_KEY.HIGHER_PRICE) || '';
    const page = Number(query.get(SEARCH_KEY.PAGE_NO)) || 1;
    // お届け日指定の検索条件について、既知の値のみに絞り込む
    const selectedScheduledDeliveries = useMemo(()=>{
        const scheduledDeliveries = query.getAll(SEARCH_KEY.SCHEDULED_DELIVERIES) || [];
        return SCHEDULED_DELIVERIES.filter((needle)=>scheduledDeliveries.includes(needle));
    }, [
        query,
        SEARCH_KEY.SCHEDULED_DELIVERIES
    ]);
    // NOTE: カテゴリーIDは数値のみを許容する
    if (isNaN(Number(category))) {
        category = '';
    }
    return {
        params: {
            category,
            order,
            text,
            prefectures,
            localGovernments,
            lowerPrice,
            higherPrice,
            scheduledDeliveries: selectedScheduledDeliveries,
            page
        }
    };
}

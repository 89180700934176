const scheduledDeliveryKind = (state)=>state.furusatoPurchaseForm.scheduledDeliveryKind;
const preparationDays = (state)=>state.furusatoPurchaseForm.preparationDays;
const daysToShippingDeadline = (state)=>state.furusatoPurchaseForm.daysToShippingDeadline;
const quantityPriceJpy = (state)=>state.furusatoPurchaseForm.quantityPriceJpy;
const temperatureControl = (state)=>state.furusatoPurchaseForm.temperatureControl;
export default {
    scheduledDeliveryKind,
    preparationDays,
    daysToShippingDeadline,
    quantityPriceJpy,
    temperatureControl
};

import { SET_INPUT_SEARCH_TEXT } from './actions';
const initialState = {
    text: ''
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_INPUT_SEARCH_TEXT:
            return {
                ...state,
                text: action.payload.text
            };
        default:
            return state;
    }
}

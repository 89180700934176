import { useDispatch, useSelector } from 'react-redux';
import { otherAddressForSendingActions, otherAddressForSendingSelectors } from '~/state/ducks/otherAddressForSending';
export function useOtherAddressForSending() {
    const dispatch = useDispatch();
    const selectedOtherAddressForSending = useSelector(otherAddressForSendingSelectors.selectedOtherAddressForSending);
    const setSelectedOtherAddressForSending = (otherAddress)=>{
        dispatch(otherAddressForSendingActions.setSelectedOtherAddressForSending(otherAddress));
    };
    return {
        selectedOtherAddressForSending,
        setSelectedOtherAddressForSending
    };
}

const search = {
    MayBeKeywordInCategories: {
        goToCategorySearchIn: 'カテゴリ「{{- name}}」の商品のみを表示'
    },
    searchedProducts: {
        priceRange: '{{lowerPrice}} 〜 {{higherPrice}}',
        yen: '円',
        allEcProductsTitle: '産直通販・お取り寄せ商品',
        allFurusatoProductsTitle: '産直食材のふるさと納税 返礼品',
        ecProductsTitle: 'の$t(search:searchedProducts.allEcProductsTitle)',
        furusatoProductsTitle: 'の$t(search:searchedProducts.allFurusatoProductsTitle)',
        and: 'の',
        allResultsText: '検索結果: {{count}}件',
        allResults: 'すべての検索結果',
        conditionalResults: 'の検索結果:{{count}}件',
        notFound: '検索に一致する商品は見つかりませんでした。',
        notFoundAndRecommend: '$t(search:searchedProducts.notFound)\n下記の商品はいかがでしょう？',
        breadCrumbCondition: '{{- condition}}の検索結果',
        popularResults: '人気の商品: {{count}}件',
        productType: {
            oneTimeProduct: '単品商品',
            subscriptionProduct: '定期商品',
            reservedProduct: '予約商品'
        },
        coupons: {
            delivery: '送料無料・送料割引',
            product: '商品割引'
        },
        scheduledDeliveries: {
            possible: '可能',
            consultable: '要相談'
        },
        sortOrder: {
            popular: '人気順',
            latest: '新着順',
            price_asc: '価格安い順',
            price_desc: '価格高い順'
        }
    },
    searchedProducers: {
        allResults: 'すべての検索結果: {{totalCount}}件',
        conditionalResults: 'の検索結果: {{totalCount}}件',
        allProducersTitle: 'すべての生産者一覧',
        producersTitle: '{{- condition}}の生産者一覧',
        notFound: 'お探しのキーワードで、生産者が見つかりませんでした。'
    },
    searchNavigation: {
        clearBtn: 'クリア',
        saveBtn: '保存',
        unspecified: '指定なし',
        SearchNavigation: {
            keyword: 'キーワード',
            placeholder: '食材名や生産者名で検索',
            category: 'カテゴリ',
            area: '産地',
            refine: '絞り込み',
            filter: 'フィルター'
        },
        categoryPicker: {
            all: 'すべて'
        },
        ProducingAreaDialog: {
            area: '産地'
        },
        OthersDialog: {
            filter: '絞り込み',
            typeTitle: '商品タイプ',
            orderTitle: '並び順',
            type: {
                all: 'すべて',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                one_time: '単品',
                subscription: '定期',
                reservation: '予約'
            },
            price: {
                title: '価格',
                noLowerLimit: '下限なし',
                noUpperLimit: '上限なし'
            },
            furusatoPrice: {
                title: '納税額',
                noLowerLimit: '¥ 納税額下限',
                noUpperLimit: '¥ 納税額上限'
            },
            coupons: {
                title: '割引',
                delivery: '送料無料・送料割引',
                product: '商品割引'
            },
            scheduledDelivery: {
                title: 'お届け日指定',
                caption: '単品商品のみが対象となります',
                options: {
                    possible: '可能',
                    consultable: '要相談'
                }
            }
        },
        producers: {
            placeholder: '生産者名で検索',
            category: 'カテゴリ',
            area: '生産地'
        }
    }
};
export default search;

/**
 * サーバ側の値と同期を取るような値のテキストを定義する
 */ const constants = {
    yamatoTime: {
        am: '午前中',
        from14To16: '14:00 - 16:00',
        from16To18: '16:00 - 18:00',
        from18To20: '18:00 - 20:00',
        from19To21: '19:00 - 21:00'
    },
    orderState: {
        inPreparation: '発送準備中',
        cancelled: '生産者よりキャンセル',
        completed: '発送済み',
        chargeRefunded: '返金申請承諾済み',
        chargeRefundedFurusato: 'ポイント返還済み',
        returnedToSender: '返送',
        chargeFailed: '決済エラー',
        outOfDeliveryArea: '注文エラー'
    },
    subscriptionState: {
        active: '定期購入中',
        chargeFailed: '決済エラー',
        returnedToSender: '配送エラー',
        outOfDeliveryArea: '注文エラー',
        willSkip: '次回配送分をスキップ',
        completed: '定期購入終了'
    },
    reservedState: {
        active: '予約中',
        chargeFailed: '決済エラー',
        returnedToSender: '配送エラー',
        outOfDeliveryArea: '注文エラー',
        completed: '処理済み',
        willSkip: ''
    }
};
export default constants;

import { sendKarteIdentify } from 'ec-services/gtmService';
import { useEffect } from 'react';
import { useMe } from '~/hooks/user';
export function useKarteIdentifyGTM() {
    const { me } = useMe();
    useEffect(()=>{
        sendKarteIdentify({
            me
        });
    }, [
        me
    ]);
}

import { NestedSet } from 'ec-services/nestedSetUtils';
import * as api from '~/services/api';
/**
 * カテゴリ一覧データをNestedSetの値に変換する
 * NestedSetインスタンスを返す
 */ const resToCategoryNestedSet = (data)=>{
    return new NestedSet(data);
};
/**
 * 通常のDPOとして、シンプルなカテゴリデータを返す（主にルートのカテゴリ一覧に利用）
 */ const resToCategories = (data)=>{
    return data.map(({ id, name, image })=>({
            id,
            name,
            image: image || null
        }));
};
export function getCategories() {
    return `v1/categories?include=image`;
}
export function getRootCategories() {
    return `v1/categories?include=image&only_roots=true`;
}
const _fetchCategories = async (path)=>{
    const json = await api.get(path);
    const res = await api.deserializeJsonApi(json);
    return res;
};
/**
 * カテゴリデータをNesteedSet形式で取得する
 */ export async function swrFetcher(path) {
    const res = await _fetchCategories(path);
    return resToCategoryNestedSet(res);
}
/**
 * カテゴリデータをリスト形式で取得する
 */ export async function fetchCategories(path) {
    const res = await _fetchCategories(path);
    return resToCategories(res);
}

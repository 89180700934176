export const FURUSATO_SET_PAYMENT_TAX = 'purchase/FURUSATO_SET_PAYMENT_TAX';
export const setPaymentTax = (paymentTax)=>({
        type: FURUSATO_SET_PAYMENT_TAX,
        payload: {
            paymentTax
        }
    });
export default {
    setPaymentTax
};

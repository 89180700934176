import { deserializeJsonApi } from 'ec-services/api';
import * as api from '~/services/api';
function resToTdsResult(res) {
    return {
        threedsRedirectUrl: res.redirectUrl
    };
}
/**
 * マスク化されたクレジットカード情報をサーバに登録する
 * @param maskedCardData クレジットカード情報
 * @param tdsOptions 3Dセキュア認証のオプション
 * @returns
 */ export async function createMaskedCreditCardInfo(maskedCardData, tdsOptions) {
    const { gmopgCustomer, main, setSubscriptionPayments } = maskedCardData;
    const { maskedCardNo, cardExpMonth, cardExpYear, token, cvcCheck, cardBrand } = gmopgCustomer;
    /* eslint-disable @typescript-eslint/naming-convention */ const creditCardParams = {
        gmopg_customer: {
            masked_card_no: maskedCardNo,
            card_exp_month: cardExpMonth,
            card_exp_year: cardExpYear,
            token,
            cvc_check: cvcCheck,
            card_brand: cardBrand
        },
        main,
        set_subscription_payments: setSubscriptionPayments
    };
    /* eslint-enable @typescript-eslint/naming-convention */ const json = await api.post(`v1/tds_gmopg_customers`, {
        ...creditCardParams,
        // 3Dセキュア認証のstateを送信する
        tds: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            return_url: tdsOptions.returnUrl,
            state: tdsOptions.state
        }
    });
    const res = await api.deserializeJsonApi(json);
    return resToTdsResult(res);
}
function resToCreditCard(res) {
    return {
        id: Number(res.id),
        isMain: res.main,
        number: res.number,
        cardBrand: res.cardBrand,
        expYear: res.expYear,
        expMonth: res.expMonth,
        expired: res.expired,
        isUsedFromAnySubscriptions: res.isUsedFromAnySubscriptions
    };
}
/**
 * 旧処理のクレカ登録APIを呼び出す（3DS認証なし）
 * @deprecated こちらは開発ようなので、通常はcreateMaskedCreditCardInfoを使うようにしてください
 */ export async function createMaskedCreditCardInfoLegacy(maskedCardData) {
    const { gmopgCustomer, main, setSubscriptionPayments } = maskedCardData;
    const { maskedCardNo, cardExpMonth, cardExpYear, token, cvcCheck, cardBrand } = gmopgCustomer;
    /* eslint-disable @typescript-eslint/naming-convention */ const creditCardParams = {
        gmopg_customer: {
            masked_card_no: maskedCardNo,
            card_exp_month: cardExpMonth,
            card_exp_year: cardExpYear,
            token,
            cvc_check: cvcCheck,
            card_brand: cardBrand
        },
        main,
        set_subscription_payments: setSubscriptionPayments
    };
    /* eslint-enable @typescript-eslint/naming-convention */ const json = await api.post(`v1/gmopg_customers`, creditCardParams);
    const res = await api.deserializeJsonApi(json);
    return resToCreditCard(res);
}
export async function deleteCreditCardInfo(creditCardId) {
    return api.del(`v1/gmopg_customers/${creditCardId}`);
}
export const gmopgCustomerCallbacksPath = '/v1/gmopg_customer_callbacks';
/**
 * クレカ登録後の3DS認証後のコールバック
 * POST /v1/gmopg_customer_callbacks
 */ export async function gmopgCustomerCallbacks(url, { arg }) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const json = await api.post(url, {
        access_id: arg.accessId
    });
    const res = await deserializeJsonApi(json);
    return resToCreditCard(res);
}

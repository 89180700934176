const product = {
    ProductHelp: {
        oneTime: 'この商品のタイプについて',
        subscription: '商品タイプについて（定期商品）',
        reserved: '商品タイプについて（予約商品）'
    },
    ProductDescription: {
        addedDescription: '{{createdAt}}追記',
        headline: '商品説明',
        preservationMethod: {
            title: '保存方法'
        },
        ProductProcessedFood: {
            title: '食品表示'
        },
        alcoholCaution: '20歳未満の方の飲酒は法律で禁止されています'
    },
    ProductStatus: {
        suspended: '注文受付停止中',
        endOfSale: '販売終了',
        oneTimeOutOfStock: '一時在庫切れ',
        subscriptionOutOfStock: '在庫切れ'
    },
    ProductInformation: {
        suspendOfSale: '注文受付停止中',
        endOfSale: '販売終了',
        todayRemaining: '本日あと {{count}}点',
        remaining: 'あと{{count}}点',
        temporaryOutOfStock: '一時在庫切れ',
        inventoryCountResets: '21時に在庫数がリセットされます',
        subscriptionOutOfStock: '在庫切れ',
        outOfStock: '在庫がありません',
        reserved: '{{reserved}}人が予約中 | あと{{count}}点',
        subscribed: '{{subscribed}}人が定期購入中 | あと{{count}}点',
        quantityTabs: {
            defaultTitle: '通常購入',
            furusatoTitle: 'ふるさと納税'
        },
        quantityMore: '分量をもっと見る',
        delivery: {
            reservedMonth: '{{month}}に発送',
            weekSubscription: '1週間に{{count}}回定期配送',
            monthSubscription: '{{settlementPeriodicUnit}}ヶ月に{{count}}回定期配送',
            specificMonthSubscription: `{{months}}に定期配送`,
            reduceSpecificMonthSubscription: `特定月に定期配送`
        },
        furusato: {
            title: 'ポケマルふるさと納税',
            calculator: '控除上限額を調べる',
            notice: '自己負担年間 2,000円で返礼品が受け取れます',
            link: 'ふるさと納税について詳しく知る',
            furusatoFlow: '申し込みのながれ',
            aboutProcedure: '申請手続きについて',
            pointDescription: '注文後、申し込んだ返礼品は生産者さんから、ふるさと納税に関する書類は自治体から発送されます。生産者さんのこだわりの品を楽しみつつ、税金の控除を受けるために自治体から届いた書類で「ワンストップ特例申請」「確定申告」いずれかの手続きを行いましょう。',
            prefixPrice: '寄付金額',
            freeShipping: '送料無料'
        },
        postsAnchorLinkLabel: 'みんなの投稿を見る'
    },
    QuestionLabel: '商品について生産者に質問する',
    RequestingLabel: 'その他の分量をリクエスト',
    notifyOnRestock: '在庫復活時にお知らせ',
    notifyOnResume: '再販時にお知らせ',
    PurchaseBar: {
        purchaseProcedure: '購入手続きへ',
        furusatoPurchaseProcedure: '寄付手続きへ'
    },
    SharingProduct: {
        shareProduct: '商品をシェア',
        sharingQuote: '{{title}}｜{{producer}} - ポケットマルシェ（ポケマル）',
        copyLink: 'リンクをコピー',
        xHashtag: 'ポケマル',
        xAccount: 'pocket_marche'
    },
    ShippingInformation: {
        category: {
            title: 'カテゴリ'
        },
        productionArea: {
            title: '産地'
        },
        // 配送方法に関するもの
        deliveryMethod: {
            title: '配送方法',
            provider: {
                yamato: 'ポケマル提携のヤマト便'
            },
            deliveryDaysCaution: {
                withinOneDay: '※こちらの商品は配送に2日以上かかるエリアにはお届けできません',
                withinTwoDays: '※こちらの商品は配送に3日以上かかるエリアにはお届けできません'
            },
            deliveryType: {
                title: '配送区分: ',
                default: '常温',
                frozen: '冷凍',
                refrigerated: '冷蔵'
            }
        },
        // 配送料金に関するもの
        deliveryCompany: {
            title: '配送料金',
            fee: '（送料）',
            temperatureControlFeeIncluded: '（送料 + クール代）',
            deliveryCost: {
                title: '送料:',
                price: '{{value}}円 (全国一律)',
                withDiscount: 'から割引'
            },
            cautionLink: '※離島などの例外はあります。詳細はこちら',
            furusatoCaution: '※ふるさと納税の場合、送料はかかりません'
        },
        deliveryTime: {
            title: 'お届け日指定',
            possible: {
                label: '可',
                description: 'この商品はお届け日時をご指定いただけます。\n※天候等の生産現場における事情や、運送会社の都合等による配送遅延が発生する場合もございます。'
            },
            partiallyPossible: {
                label: '部分的に可',
                description: 'この商品はお届け日時をご指定いただけますが、発送対応が可能な曜日が限られます。\n※天候等の生産現場における事情や、運送会社の都合等による配送遅延が発生する場合もございます。'
            },
            priorConsultation: {
                label: '事前相談必須',
                description: 'お届け日については基本的に指定を受け付けていませんが、事前にご相談いただければ対応できる場合があります。\nお届け時間帯についてはご指定いただけます。',
                descriptionForOther: 'お届け日時について、基本的に指定を受け付けていませんが、事前にご相談いただければ対応できる場合があります。'
            },
            impossible: {
                label: '不可',
                description: 'お届け日の指定は受け付けていません。配送時間についてはご指定いただけます。',
                descriptionForOther: 'お届け日時の指定は受け付けていません。'
            }
        },
        initShippingForSubscription: {
            title: '最初の発送',
            weeklyDeadline: '毎週の締め曜日',
            monthlySubscriptionDescription: 'この商品は定期商品です。\n{{date}}に翌月配送分の注文を締め切ります。締め切り日までは翌月分のキャンセルや配送先変更が可能です。',
            weeklySubscriptionDescription: `この商品は定期商品です。\n{{dayOfTheWeek}}に次回配送分の注文を締め切ります。締め切り日までは次回分のキャンセルや配送先変更が可能です。`
        },
        reservedDeliveryMonth: {
            title: '配送月',
            description: 'この商品は予約商品です。\n{{date}}まではキャンセル及び配送先の変更が可能です。'
        },
        aboutMultiple: {
            title: '複数商品のご注文について',
            description: '・複数商品の同時注文はできません。\n・同じ出品者による複数商品の同梱を希望される場合は、必ずご注文前に出品者へお問い合わせください。\n・ご注文後に複数注文の送料をまとめることはできません。'
        },
        shipmentDate: {
            title: '最終注文受付日'
        },
        untilShipping: {
            title: '注文から発送まで',
            days: '{{value}}日',
            onTheDay: '当日',
            cardViewLabel: '注文から{{days}}日で発送'
        },
        couponAvailability: {
            title: 'クーポン利用可否',
            available: '可',
            unavailable: '不可',
            reasons: {
                isUsedProductDiscountCoupon: '商品割引済みの商品のため、クーポンの利用はできません',
                isUsedDeliveryCostDiscountCoupon: '送料割引済みの商品のため、クーポンの利用はできません',
                isNotOneTimeProduct: '定期・予約商品ではクーポンはご利用いただけません',
                isFurusatoQuantity: 'ふるさと納税での注文の場合、クーポンの利用はできません'
            }
        },
        paymentMethod: {
            title: '支払い方法',
            description: '各種クレジットカード',
            detail: '（ご利用可能なお支払い方法について）',
            atone: '、後払い(コンビニ/ATM)、atone 翌月払い(コンビニ/口座振替)',
            atoneCaution: '※後払い及びatone翌月払いは、ふるさと納税ではご利用出来ません。'
        },
        invoiceIssuerRegistered: {
            title: '適格請求書発行事業者登録',
            label: 'あり'
        }
    },
    OtherProducts: {
        title: '同じ生産者の商品',
        emptyMessage: '現在販売中の商品はありません。販売停止中の商品は<0>こちら</0>から確認できます。'
    },
    RecentlyViewedProducts: {
        title: '最近見た商品',
        emptyMessage: '販売中の最近見た商品はありません。'
    },
    RelatedProducts: {
        title: '同じカテゴリの商品',
        moreLink: '{{category}}の一覧を見る',
        emptyMessage: '販売中の同じカテゴリの商品はありません。\n<0>{{- categoryName}}の一覧</0>から他の商品を探してみてください。'
    },
    SameProductionAreaProducts: {
        title: '{{productionArea}}の商品',
        listName: '同じ産地の商品',
        emptyMessage: '販売中の{{productionArea}}の商品はありません。'
    },
    productLabel: {
        willEndIn: '終了まで{{days}}日',
        willEndToday: '本日終了',
        reserved: '予約',
        regular: '定期',
        possibleFurusato: 'ふるさと納税可',
        furusato: 'ふるさと納税',
        possibleFurusatoEllipsis: 'ふるさと可'
    },
    delivery: {
        deliveryFrequency: '配送頻度',
        deliveryMonth: '配送月',
        reservedYearAndMonth: '{{year}}年{{month}}月',
        weeklySubscription: '1週間に{{count}}回',
        monthlySubscription: '{{settlementPeriodicUnit}}ヶ月に{{count}}回',
        specificMonthSubscription: `{{months}}`
    },
    noProducts: 'データがありません',
    // issue#2843 abテスト用のテキスト
    RelatedProductsForUnableProduct: {
        caption: '閲覧中の商品は現在購入できません。\n他の商品はいかがですか？'
    }
};
export default product;

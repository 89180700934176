import { PARAM_PATH } from 'ec-constants/routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useCreditCards } from '~/hooks/creditCards';
import { fetchPointCustomer } from '~/repositories/furusato/pointCustomer';
export function usePointCustomer() {
    const navigate = useNavigate();
    const { selectedCreditCard } = useCreditCards();
    const [customerId, setCustomerId] = useState();
    useEffect(()=>{
        if (!selectedCreditCard) {
            return;
        }
        const fetch = async ()=>{
            await fetchPointCustomer(selectedCreditCard.id).then((customerId)=>{
                setCustomerId(customerId);
            }).catch((e)=>{
                navigate(PARAM_PATH.ERROR(e.errorCode || ''), {
                    state: {
                        error: e
                    }
                });
            });
        };
        fetch();
    }, [
        navigate,
        selectedCreditCard
    ]);
    return {
        customerId
    };
}

import dayjs from 'dayjs';
import i18n from '~/locale';
export function calcShippingDate() {
    /**
   * 単体商品の「発送までの日数」を取得する
   * @param preparationDays 最短発送日
   * @param daysToShippingDeadline 最長発送日
   * @returns いずれかの値がnullの場合は空文字を返す
   */ const getDaysBeforeShipping = (preparationDays, daysToShippingDeadline)=>{
        // 単体商品以外の場合は値がないので空文字を返す
        if (preparationDays === null || daysToShippingDeadline === null) {
            return '';
        }
        // NOTE: daysToShippingDeadline == 0 は存在しないのでこちらは数値を返す
        if (preparationDays === daysToShippingDeadline) {
            return String(preparationDays);
        }
        // その他配送の場合、最短日数が0となるときがあるため、その場合は「当日」を表示する
        return `${preparationDays === 0 ? i18n.t('product:ShippingInformation.untilShipping.onTheDay') : preparationDays}~${daysToShippingDeadline}`;
    };
    /**
   * キャンセル可能日付から「発送予定日」を取得する
   */ const getShippingDate = (cancellationDeadlineDate, preparationDays)=>{
        return dayjs(cancellationDeadlineDate).add(preparationDays, 'day').format('YYYY-MM-DD');
    };
    return {
        getDaysBeforeShipping,
        getShippingDate
    };
}

import { SIGN_OUT } from '~/state/ducks/common/actions';
import { RESET_DELIVERY, SET_DELIVERY_DATE, SET_DELIVERY_DESTINATION, SET_DELIVERY_TIME } from './actions';
const initialState = {
    deliveryDestination: 'me',
    deliveryDate: '',
    deliveryTime: ''
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_DELIVERY_DESTINATION:
            return {
                ...state,
                deliveryDestination: action.payload.deliveryDestination
            };
        case SET_DELIVERY_DATE:
            return {
                ...state,
                deliveryDate: action.payload.deliveryDate
            };
        case SET_DELIVERY_TIME:
            return {
                ...state,
                deliveryTime: action.payload.deliveryTime
            };
        case RESET_DELIVERY:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

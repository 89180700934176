const common = {
    pocketmarche: 'ポケットマルシェ',
    topLogo: 'ポケマル/ポケットマルシェ',
    companyName: '株式会社 雨風太陽',
    furusato: 'ポケマルふるさと納税',
    taberuTsushin: '食べる通信',
    copyright: 'Copyright {{year}} Ame Kaze Taiyo, Inc.',
    information: 'お知らせ',
    faq: 'よくある質問',
    inquiry: '運営へのお問い合わせ',
    company: '運営会社',
    terms: '利用規約',
    termsForProducer: '生産者向け利用規約',
    privacyPolicy: 'プライバシーポリシー',
    specifiedCommercialLaw: '特商法に基づく表記',
    menu: 'メニュー',
    paginationCountResults: '{{start}}-{{end}}表示/{{total}}件中',
    favoriteButtonAriaLabel: 'お気に入りボタン',
    yamatoProvider: 'ポケマル提携のヤマト便',
    errorCode: 'エラーコード',
    units: {
        price: '{{value}}円',
        priceUnitJp: '円',
        pointUnitJp: 'ポイント',
        pointUnitAbbr: 'pt',
        tax: '(税込)'
    },
    furusatoPriceLabel: '納税額',
    perDelivery: '/配送毎',
    withDeliveryCostPrefix: '+送料',
    includeDeliveryCost: '送料込み',
    dateFormats: {
        date: 'YYYY年M月D日',
        dateWithoutYear: 'M月D日',
        dateWithHour: 'YYYY年M月D日H時'
    },
    errors: {
        imageLoadFailed: '画像の読み込みに失敗しました',
        askingToReloadforLoading: '読み込みが完了しない場合\nリロードして再度表示してください'
    },
    // 一旦ここに置いておくがフィールド名としてまとめたほうがいいかも
    mailAddress: 'メールアドレス',
    password: 'パスワード',
    acceptPolicy: {
        accept: '<link1>利用規約<icon/></link1>および<link2>プライバシーポリシー<icon/></link2>に同意の上、',
        action: '{{action}}してください',
        login: 'ログイン',
        register: '登録',
        confirm: '申込内容を確定'
    },
    header: {
        login: 'ログイン',
        signup: '新規登録',
        favorite: 'お気に入りへ',
        SearchMenu: {
            placeholder: '食材名や生産者名で検索',
            category: 'カテゴリ',
            searchCategory: 'カテゴリからさがす',
            close: '閉じる'
        },
        furusato: {
            guide: 'ふるさと納税とは？',
            taxSimulation: '控除上限シミュレーター'
        }
    },
    topSearchBar: {
        search: '検索'
    },
    category: {
        viewCategories: 'カテゴリ一覧を見る'
    },
    SideNav: {
        categories: 'カテゴリからさがす',
        products: '商品をさがす',
        producers: '生産者さんをさがす',
        stories: '読みもの',
        about: 'ポケットマルシェとは?',
        question: 'よくあるご質問',
        connectToProducer: '生産者とつながる',
        introductionTaberuTsushin: 'ポケマルの食べ物付き情報誌',
        producerDashboard: '生産者管理画面',
        myPage: 'マイページ',
        favorite: 'お気に入り',
        orderHistory: '注文履歴',
        message: 'メッセージ',
        furusato: {
            signIn: 'ログイン・新規登録',
            guide: 'ふるさと納税が初めての方へ',
            about: 'ポケマルふるさと納税の特長',
            simulation: '控除上限額シミュレーター',
            history: '納税履歴',
            point: 'ポイント残高',
            question: 'よくあるご質問',
            pocketmarcheSub: '生産者と繋がる産直EC'
        }
    },
    footer: {
        forBeginer: 'はじめての方へ',
        forProducer: {
            title: '生産者の方へ',
            description: '農家さん・漁師さんを募集しています!'
        },
        forBusiness: {
            title: '法人・自治体の方へ',
            description: 'アライアンスのご相談はこちらから'
        },
        recruit: {
            title: '採用情報',
            description: '生産者と食べる人をつなぎたい'
        },
        caption: {
            ec: '日本最大級の産直（産地直送）ECサイト『ポケットマルシェ（ポケマル）』',
            furusato: '産直（産地直送）ふるさと納税サイト『ポケマルふるさと納税』'
        }
    },
    snsUrl: {
        facebook: 'https://www.facebook.com/pocketmarchejp/',
        instagram: 'https://www.instagram.com/pocket.marche/',
        x: 'https://x.com/pocket_marche',
        youtube: 'https://www.youtube.com/channel/UCcHTWckyqpyD5f8sBvIUItw'
    },
    service: {
        apple: 'Apple',
        google: 'Google',
        facebook: 'Facebook',
        line: 'LINE',
        x: 'X',
        instagram: 'Instagram'
    },
    actions: {
        showMore: 'もっと見る',
        backToTop: 'トップへ戻る',
        back: '戻る',
        close: '閉じる',
        unfollow: 'フォロー済み',
        follow: 'フォローする',
        cannotDelete: '削除できません'
    },
    cancelledOrDelayedReason: {
        badWeather: '天候不順',
        poorHarvest: '収穫量が足りない',
        sickness: '体調不良',
        other: 'その他'
    },
    paymentMethod: {
        creditCard: 'クレジットカード',
        atone: 'atone翌月払い（コンビニ/口座振替）',
        atonePayAsYouGo: '後払い（コンビニ/ATM）',
        cvs: 'コンビニ払い',
        applePay: 'Apple Pay',
        point: {
            label: '自治体への寄付で獲得したポイントで購入',
            useAndBuy: '使用して購入'
        }
    },
    cannotDelete: {
        text: '定期・予約の{{info}}に設定中のため削除できません',
        detail: '削除するには、定期・予約のご注文に設定済みの{{info}}を変更してください。<0>定期・予約一覧はこちら</0>'
    },
    form: {
        required: '※必須',
        required2: '必須',
        SimpleDateSelect: {
            year: '年',
            month: '月',
            day: '日'
        }
    },
    tds: {
        loadingMessage: '3Dセキュアの認証を行っています'
    }
};
export default common;

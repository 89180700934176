export const SET_MESSAGE = 'user/FURUSATO_SET_MESSAGE';
export const setMessage = (message)=>({
        type: SET_MESSAGE,
        payload: {
            message
        }
    });
export default {
    setMessage
};

const auth = {
    login: 'ログイン',
    signup: '新規登録',
    signupWith: '{{service}}で登録',
    signupWithApple: 'Appleでサインアップ',
    signinWith: '{{service}}でログイン',
    signinWithApple: 'Appleでサインイン',
    signInForm: {
        forgotPassword: 'パスワードを忘れた方（再発行）',
        loginByOtherMeans: 'それ以外の方法でログイン',
        errorDescription: 'メールアドレスまたは\nパスワードに誤りがあります。',
        invalidInput: '入力に誤りがあります'
    },
    alternative: {
        noAccount: {
            title: 'アカウントをお持ちでない方',
            registration: '新規会員登録'
        },
        toSignIn: {
            title: 'アカウントをお持ちの方'
        }
    },
    signUpForm: {
        title: '新規ユーザー登録',
        signUpByOtherMeans: 'それ以外の方法で登録',
        description: '内容を確認し、新規登録ボタンを押してください。',
        email: {
            placeholder: '例）support@poke-m.com'
        },
        nickname: {
            title: 'ニックネーム',
            placeholder: '例）ポケマル子'
        },
        password: {
            title: 'パスワード(8文字以上)',
            placeholder: '8文字以上の半角英数字'
        },
        acceptEmailInfo: 'ポケットマルシェからの旬にまつわるお便りやお得な情報を受け取る'
    },
    confirmationEmailFailed: {
        title: 'メールアドレスの認証に失敗しました',
        description: 'URLが間違っているか無効である場合があります。URLをお確かめの上、再度実行してください。\n解決しない場合はお手数ですが、再度登録をいただくか、サポートまでお問い合わせください'
    },
    confirmEmail: {
        title: '仮登録が完了しました',
        description1: '{{email}} 宛にメールを送信いたしました。',
        description2: 'メールに記載の確認リンクを押すと本登録が完了します。',
        note: {
            title: 'メールが届きませんか？\nこんな理由が考えられます',
            list1: '入力したメールアドレスが間違えている',
            list2: '迷惑メールフォルダーに入っている',
            list3: 'docomo、SoftBank、au のキャリアメールをお使いの場合受信許可設定が必要です',
            back: '戻って入力し直す'
        },
        confimationMessage: 'メールアドレスを確認しています'
    },
    // NOTE: 画面自体がなくなる予定
    signUpCompleted: {
        title: '会員登録が完了しました',
        description: 'ご登録いただき、ありがとうございました。<0 />以下ボタンから引き続き、お買い物をお楽しみください。',
        cautionTitle: 'iPhoneアプリから登録いただいた方へ',
        caution: '「買い物を続ける」ボタンを押したあと、ご登録いただいた方法にて再度ログインをお願いいたします。',
        continue: '買い物を続ける'
    },
    // FBログインの提供終了に伴うメッセージ
    facebookLoginEnd: {
        description1: 'Facebookログインの提供を終了いたしました',
        description2: 'Facebookログインを利用されていた方は、お手数ですが、「<0>$t(signInForm.forgotPassword)</0>」よりパスワードを設定していただき、メールアドレスでのログインをお願いいたします。（<1>詳細</1>）'
    }
};
export default auth;

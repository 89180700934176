const posts = {
    producersPosts: {
        title: '{{fullname}}さんの投稿',
        notFound: '生産者の投稿はありません'
    },
    communitiesPosts: {
        titles: {
            onlyThisProduct: '商品の投稿',
            all: 'みんなの投稿'
        },
        notFound: '投稿はありません',
        count: '{{value}}件'
    },
    backToProducer: '生産者のページへ戻る',
    post: '投稿する',
    consumerPost: '「ごちそうさま」を投稿する',
    postItem: {
        alt: 'みんなの投稿の画像'
    },
    // コミュニティ投稿(ごちそうさま投稿）画面
    new: {
        title: '投稿'
    },
    productInfo: {
        producer: '投稿先'
    },
    postForm: {
        selectPicture: '写真を追加',
        remove: '削除',
        textPlaceholder: 'コメントを入力してください',
        selectPlaceholder: '商品を選択してください',
        selectTitle: '以下の商品について投稿',
        submit: '投稿する',
        caution: '投稿内容は生産者含め全ユーザーが閲覧できます',
        completed: '投稿しました'
    }
};
export default posts;

export const SET_ESTIMATED_RESULT = 'estimation/FURUSATO_SET_ESTIMATED_RESULT';
export const setEstimatedResult = (estimatedResult)=>({
        type: SET_ESTIMATED_RESULT,
        payload: {
            estimatedResult
        }
    });
export const RESET_ESTIMATED_RESULT = 'estimation/FURUSATO_RESET_ESTIMATED_RESULT';
export const resetEstimatedResult = ()=>({
        type: RESET_ESTIMATED_RESULT
    });
export default {
    setEstimatedResult,
    resetEstimatedResult
};

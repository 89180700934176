export const SET_TOAST_STATUS = 'toast/SET_TOAST_STATUS';
export const setToastStatus = (status)=>({
        type: SET_TOAST_STATUS,
        payload: {
            status
        }
    });
export const SET_TOAST_MESSAGE = 'toast/SET_TOAST_MESSAGE';
export const setToastMessage = (message, duration)=>({
        type: SET_TOAST_MESSAGE,
        payload: {
            message,
            duration
        }
    });
export const RESET_TOAST = 'toast/RESET_TOAST';
export const resetToast = ()=>({
        type: RESET_TOAST
    });
export default {
    setToastStatus,
    setToastMessage,
    resetToast
};

/**
 * カテゴリデータから最初のカテゴリを取得
 * @param categories カテゴリデータリスト
 */ const getFirstCategory = (categories)=>{
    if (!categories || categories.length === 0) {
        return null;
    }
    return categories[0] || null;
};
/**
 * カテゴリデータリストから最初のカテゴリのルートカテゴリと末端のカテゴリの名称を取得
 * @param categories カテゴリデータリスト
 */ export const getFirstCategoryName = (categories)=>{
    const category = getFirstCategory(categories);
    if (!category) {
        return {
            rootCategoryName: '',
            leafCategoryName: ''
        };
    }
    return {
        rootCategoryName: category[0]?.name || '',
        leafCategoryName: category[category.length - 1]?.name || ''
    };
};

import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export const multiPartFormHeaderConfig = {
    headers: {
        'content-type': 'multipart/form-data'
    }
};
/**
 * APIのリクエストを行うクラス
 * @example
 * const http = new HttpService('https://baseURL.com', () => {}, (e) => {});
 * const res = await http.get('/foo/path')
 * const res = await http.post('/baz/path', { data: 'bar' }, { headers: {..} })
 */ class HttpService {
    service;
    constructor(baseURL, onSuccess, onError){
        const service = axios.create({
            baseURL,
            withCredentials: true
        });
        service.interceptors.response.use(onSuccess, onError);
        this.service = service;
    }
    /**
   * GET リクエスト
   * @param path
   * @param config
   * @returns
   */ async get(path, config) {
        const response = await this.service.get(path, config);
        return response.data;
    }
    /**
   * POST リクエスト
   * @param path
   * @param data
   * @param config
   * @returns
   */ async post(path, // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data, config) {
        const response = await this.service.post(path, data, config);
        return response.data;
    }
    /**
   * PUT リクエスト
   * @param path
   * @param data
   * @param config
   * @returns
   */ async put(path, // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data, config) {
        const response = await this.service.put(path, data, config);
        return response.data;
    }
    /**
   * DELETE リクエスト
   * @param path
   * @param config
   * @returns
   */ async del(path, config) {
        const response = await this.service.delete(path, config);
        return response.data;
    }
    /**
   * PATCH リクエスト
   * @param path
   * @param data
   * @param config
   * @returns
   */ async patch(path, // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data, config) {
        const response = await this.service.patch(path, data, config);
        return response.data;
    }
}
export default HttpService;

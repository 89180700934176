const producerCommercialCode = {
    title: '特定商取引法に基づく表記',
    organizationName: '運営会社',
    representative: '代表者',
    address: '所在地',
    releaseAfterOrder: '請求後遅滞なく開示します。',
    phoneNumber: '電話番号',
    referProduct: '各商品ページの表記をご確認ください。',
    remark: '※お取引やサービスについてのお問い合わせは電話では受け付けておりません。商品ページの「お問い合わせ」フォームよりご連絡ください。',
    price: '販売価格および送料',
    otherCosts: '商品代金以外に必要な費用',
    shippingCost: '配送エリアにより追加の送料',
    linkToShippingCost: '（「<0>送料について</0>」をご確認ください。）',
    paymentMethod: '代金の支払い方法',
    paymentMethodDescription: [
        {
            title: 'クレジットカード'
        },
        {
            title: '後払い（コンビニ/ATM）'
        },
        {
            title: 'atone 翌月払い（コンビニ/口座振替）'
        }
    ],
    paymentMethodCaution: '後払い（コンビニ/ATM）及びatone 翌月払い（コンビニ/口座振替）は単品商品の決済のみご利用いただけます。',
    paymentDate: '代金の支払い時期',
    paymentDateCredit: 'クレジットカード\nご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせ下さい。\n\n' + '後払い（コンビニ/ATM）\n注文確定の翌日に、メールとSMSでお支払いに必要な番号をお知らせします。コンビニまたは銀行ATM (Pay-easy) で10日後までにお支払いください。\n後払い手数料は209円（税込）です。\n代金は株式会社ネットプロテクションズの<0>利用規約</0>に基づき、指定の方法で請求いたします。\n\n' + 'atone 翌月払い（コンビニ/口座振替）\nお支払いに必要な番号を、お買い物の翌月初旬にメールでご案内いたします。Loppiなどのコンビニ端末、または銀行ATM (Pay-easy) で、翌月10日までにお支払いください。\nお支払い方法は以下4つから選べます。スマホアプリまたはWebマイページから、月末までにお手続きください。\n\n' + '(1) コンビニ端末 / 銀行ATM (Pay-easy) ※標準設定\n(2) 電子バーコード (コンビニ)\n(3) はがき請求書 (コンビニ)\n(4) 口座振替※コンビニ端末 / 銀行ATM (Pay-easy) は、セブンイレブンではお支払いいただけません。\n' + '※電子バーコード・はがき請求書は、セブンイレブンでもお支払いいただけます。\n\n' + '■注意事項\n・決済手数料は無料です。\n・ご利用月のみ請求手数料209円(税込)が発生いたします。 (口座振替の場合は無料)\n・支払期限日は支払い方法によって異なります。\n・代金は株式会社ネットプロテクションズの<1>会員規約</1>に基づき指定の方法で請求いたします。',
    arrivalDate: '商品の引渡し時期',
    deadline: '申込の有効期限',
    aboutCancelation: 'キャンセル・交換について',
    aboutCancelationDescription: '商品購入確定（会員による注文確定操作のことを言い、以下同じとします。）後の会員都合によるキャンセル・返品はできかねます。商品に契約不適合があった場合、会員は株式会社雨風太陽（以下「雨風太陽」といいます。） が定める方法で生産者に連絡を行うものとしますが、万が一会員と生産者間で当該商品の契約適合性について協議が整わないときには、雨風太陽が定める方法で雨風太陽に連絡を行うものとします。なお、予約商品及び定期商品については、会員は、商品購入確定後から当該商品ごとに定められたキャンセル期日までの間に限り、注文履歴よりキャンセルを行えるものとします。'
};
export default producerCommercialCode;

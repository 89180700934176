export const SET_PAYMENT_METHOD = 'paymentMethod/FURUSATO_SET_PAYMENT_METHOD';
export const setPaymentMethod = (paymentMethod)=>({
        type: SET_PAYMENT_METHOD,
        payload: {
            paymentMethod
        }
    });
export const RESET_PAYMENT_MRETHOD = 'paymentMethod/FURUSATO_RESET_PAYMENT_METHOD';
export const resetPaymentMethod = ()=>({
        type: RESET_PAYMENT_MRETHOD
    });
export default {
    setPaymentMethod,
    resetPaymentMethod
};

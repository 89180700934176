export const EXTERNAL_LINKS = {
    KURONEKO_TRACKING: 'https://toi.kuronekoyamato.co.jp/cgi-bin/tneko?number00=1&number01=',
    ZEN_DESK: 'https://poke-m.zendesk.com/hc/ja',
    ZEN_DESK_QUESTION: 'https://poke-m.zendesk.com/hc/ja/requests/new',
    TABERU_TSUSHIN: 'https://taberu.me/',
    COOPERATE_SITE: 'https://ame-kaze-taiyo.jp',
    PRODUCER_COOPERATE: 'https://producer.pocket-marche.com/',
    RECRUIT: 'https://www.wantedly.com/companies/pocket-marche',
    CONTACT: 'https://ame-kaze-taiyo.jp/contact',
    PRIVACY_POLICY: 'https://ame-kaze-taiyo.jp/privacy_policy/',
    ONE_TIME_PRODUCT_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/4609690632089',
    SUBSCRIPTION_PRODUCT_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/4610050620569',
    RESERVED_PRODUCT_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/4609862937241',
    CANNOT_ISSUE_RECEIPT_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/5758853820313',
    TROUBLE_SHOOTING_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/4410106209049',
    WAITING_TIME_FOR_REFUNDING_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/900000896643',
    ASK_TO_PRODUCER_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/360001683853',
    PAYMENT_METHOD_DETAIL_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/360033301614',
    CHARGE_FAILED_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/360009758073',
    INVOICE_ISSUE_HELP: 'https://poke-m.zendesk.com/hc/ja/articles/5758853820313',
    BEST_PRODUCER_PROMOTION: 'https://poke-m.zendesk.com/hc/ja/articles/4574724543129',
    SECURITY_AUTHENTICATION_FOR_PURCHASES: 'https://poke-m.zendesk.com/hc/ja/articles/8887875304857'
};
export const RAILS_PATH = {
    ABOUT: '/about',
    DASHBOARD: '/dashboard',
    MAIL_EDIT: '/dashboard/mail_suppressions/edit',
    ENTRY_PRODUCER: '/users/me/entry_producer',
    DELIVERY_RATES: '/delivery_rates'
};
export const RAILS_PARAM_PATH = {
    MESSAGE_INQUIRIES_NEW: (productId)=>`/message/inquiries/new?product_id=${productId}`,
    ORDER_INVOICE_RECEIPT: (orderId)=>`/orders/${orderId}/receipt`,
    ORDER_ISSUE_INVOICE: (orderId)=>`/orders/${orderId}/invoice`
};
export const FIXED_PAGE_PATH = {
    ONE_STOP: '/furusato/onestop',
    TAX_RETURN: '/furusato/tax_return',
    ABOUT: '/furusato/about'
};

import dayjs from 'dayjs';
import i18n from '~/locale';
import * as api from '~/services/api';
function formatDate(startDate, endDate) {
    if (!startDate || !endDate) {
        return null;
    }
    return `${i18n.format(dayjs(startDate).toDate(), 'YYYY年M月D日')}~${i18n.format(dayjs(endDate).toDate(), 'YYYY年M月D日')}`;
}
function resToEstimatedResult(res) {
    return {
        productPriceWithTaxAndConsumerShare: res.productPriceWithTax + res.deliveryRateConsumerShare,
        totalAmount: res.totalAmount,
        pointBalance: res.pointBalance,
        amountOfTaxRequired: res.amountOfTaxRequired,
        numberOfPointsRequired: res.numberOfPointsRequired,
        pocketPointName: res.pocketPointName,
        lessMinimumTax: res.lessMinimumTax,
        withinDeliveryArea: res.withinDeliveryArea,
        deliveryAreaByTemperatureControl: res.deliveryAreaByTemperatureControl,
        availableScheduleDates: res.availableScheduledDeliveryDates,
        availableTimesOfDay: res.availableTimesOfDay,
        deliveryDate: formatDate(res.deliveryStartDate, res.deliveryEndDate),
        shippingDate: formatDate(res.shippingStartDate, res.shippingEndDate)
    };
}
export const getEstimationPath = (estimationItems)=>{
    const { productId, productQuantityId, deliveryDestinationId, paymentMethod, pocketPointCode, customerId, timeOfDay } = estimationItems;
    const params = new URLSearchParams();
    params.append('delivery_destination_id', String(deliveryDestinationId));
    params.append('payment_type', paymentMethod);
    params.append('customer_id', String(customerId));
    params.append('product_quantity_id', String(productQuantityId));
    params.append('pocket_point_code', String(pocketPointCode));
    if (timeOfDay) {
        params.append('time_of_day', String(timeOfDay));
    }
    return `v1/furusato/products/${productId}/purchases/estimation?${params.toString()}`;
};
export async function fetchEstimatedResult(estimationItems) {
    const json = await api.get(getEstimationPath(estimationItems));
    const res = await api.deserializeJsonApi(json);
    return resToEstimatedResult(res);
}

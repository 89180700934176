import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { appConductor, auth, breadcrumb, category, common, constants, coupon, creditCard, errors, furusato, furusatoStatic, message, meta, myPage, myPageMenu, password, posts, producer, producerCommercialCode, product, purchase, purchaserAddress, search, specifiedCommercialLaw, toast, top, validation } from 'ec-locale/ja';
// eslint-disable-next-line no-restricted-imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
dayjs.extend(utc);
dayjs.extend(timezone);
export const defaultNS = 'translation';
export const resources = {
    ja: {
        translation: {
            errors
        },
        common,
        constants,
        meta,
        toast,
        breadcrumb,
        validation,
        appConductor,
        top,
        product,
        producer,
        coupon,
        search,
        purchase,
        category,
        posts,
        message,
        myPage,
        myPageMenu,
        purchaserAddress,
        creditCard,
        auth,
        password,
        specifiedCommercialLaw,
        producerCommercialCode,
        furusato,
        furusatoStatic
    }
};
i18n.use(initReactI18next).init({
    lng: 'ja',
    saveMissing: true,
    defaultNS,
    resources,
    interpolation: {
        format (value, format) {
            if (value instanceof Date) {
                dayjs.locale(i18n.language);
                return dayjs(value).tz().format(format);
            }
            if (format === 'currency' && i18n.language === 'ja') {
                return value.toLocaleString('ja-JP', {
                    style: 'currency',
                    currency: 'JPY'
                });
            }
            if (format === 'point') {
                return value.toLocaleString('ja-JP') + common.units.pointUnitJp;
            }
            if (format === 'pointShorthand') {
                return value.toLocaleString('ja-JP') + common.units.pointUnitAbbr;
            }
            return value;
        }
    }
});
// localeのキーが見つからない場合はエラーを出力する
if (process.env.ENVIRONMENT !== 'production') {
    i18n.on('missingKey', function(lngs, namespace, key, res) {
        console.error('missingKey', lngs, namespace, key, res);
    });
}
export default i18n;

import { SIGN_OUT } from '../common/actions';
import { RESET_PURCHASER_ADDRESSES, SET_PURCHASER_ADDRESSES, SET_SELECTED_PURCHASER_ADDRESS } from './actions';
const initialState = {
    selectedPurchaserAddress: null,
    purchaseraddresses: null
};
export default function(state = initialState, action) {
    switch(action.type){
        case SET_SELECTED_PURCHASER_ADDRESS:
            return {
                ...state,
                selectedPurchaserAddress: action.payload.selectedPurchaserAddress
            };
        case SET_PURCHASER_ADDRESSES:
            return {
                ...state,
                purchaseraddresses: action.payload.purchaserAddresses
            };
        case RESET_PURCHASER_ADDRESSES:
            return {
                ...state,
                ...initialState
            };
        case SIGN_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}

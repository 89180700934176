import store from 'store';
export const isSignedIn = ()=>{
    const user = store.get('user');
    return user && user.isSignedIn ? user.isSignedIn : false;
};
export const setIsSignedIn = (isSignedIn)=>{
    store.set('user', {
        isSignedIn
    });
};
export const userRegExp = ()=>{
    const registerEditMailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$$/;
    const mailRegExp = /^[^@\s]+@[^@\s]+$/;
    const passwordRegExp = /^.{8,100}$/i;
    return {
        mailRegExp,
        registerEditMailRegExp,
        passwordRegExp
    };
};
export const userTypeJudge = (userType)=>{
    // ユーザー権限のドキュメントについては下記を参照
    // https://poke-m.docbase.io/posts/395746
    const isConsumer = userType === 'Consumer';
    const isProducer = userType === 'Producer';
    const isAdministrator = userType === 'Administrator';
    const isOfficialUser = userType === 'OfficialUser';
    const isMember = userType === 'Member';
    return {
        isConsumer,
        isProducer,
        isAdministrator,
        isOfficialUser,
        isMember
    };
};
// users/confirmation_line_authからsign_upに渡されるURLパラメータキー
export const PARAM_KEY = {
    FRIENDSHIP_STATUS_CHANGED: 'friendship_status_changed',
    AUTHORIZED_PROVIDER: 'authorized_provider'
};
// 外部認証時で使用するlocalStorageキー
export const STORE_KEY = {
    LINK_AFTER_SIGN_UP: 'linkAfterSignUp',
    LINE_STATE: 'lineState',
    CODE_VERIFIER: 'codeVerifier'
};

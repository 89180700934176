import styled from 'styled-components';
import { color, size } from '~/styles/constants';
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;
export const Title = styled.h3`
  flex: 2;
  font-size: ${size.font.S};
`;
export const ErrorMessage = styled.div`
  margin: 5px 0;
  color: ${color.WARNING};
  font-size: ${size.font.S};
  font-weight: ${size.fontWeight.W3};
`;

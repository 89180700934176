import { useDispatch, useSelector } from 'react-redux';
import { paymentTaxActions, paymentTaxSelectors } from '~/state/furusato/ducks/paymentTax';
export function usePaymentTax() {
    const dispatch = useDispatch();
    const paymentTax = useSelector(paymentTaxSelectors.paymentTax);
    const setPaymentTax = (paymentTax)=>{
        dispatch(paymentTaxActions.setPaymentTax(paymentTax));
    };
    return {
        paymentTax,
        setPaymentTax
    };
}

export const SET_SELECTED_CREDIT_CARD = 'paymentMethod/SET_SELECTED_CREDIT_CARD';
export const setSelectedCreditCard = (selectedCreditCard)=>({
        type: SET_SELECTED_CREDIT_CARD,
        payload: {
            selectedCreditCard
        }
    });
export const SET_CREDIT_CARDS = 'paymentMethod/SET_CREDIT_CARDS';
export const setCreditCards = (creditCards)=>({
        type: SET_CREDIT_CARDS,
        payload: {
            creditCards
        }
    });
export const RESET_CREDIT_CARD = 'paymentMethod/RESET_CREDIT_CARD';
export const resetCreditCard = ()=>({
        type: RESET_CREDIT_CARD
    });
export default {
    setSelectedCreditCard,
    setCreditCards,
    resetCreditCard
};

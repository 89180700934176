/* eslint-disable @typescript-eslint/naming-convention */ import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';
// TODO 他の呼び出し部分もこちらの定数を呼び出すように修正する
const DEFAULT_DATA_LAYER_NAME = 'PageDataLayer';
const formatISOtoDate = (isoDateTime)=>{
    return dayjs(isoDateTime).format('YYYY-MM-DD');
};
/**
 * GTM(GA4)にユーザID等を送るイベント
 * 未ログインの場合は各値をnullとする
 * @param user ユーザ情報
 */ const sendUserProperties = ({ userId, registeredAt, type, prefecture, firstPurchaseDate })=>{
    const dataLayer = {
        event: 'update_user_info',
        user_id: userId ? userId.toString() : null,
        // 会員登録日
        registered_date: registeredAt ? formatISOtoDate(registeredAt) : null,
        // ユーザ種別 ('Consumer' など)
        user_type: type || null,
        // メイン住所の都道府県
        prefecture: prefecture || null,
        // 初購入日
        first_purchase_date: firstPurchaseDate ? formatISOtoDate(firstPurchaseDate) : null
    };
    TagManager.dataLayer({
        dataLayerName: DEFAULT_DATA_LAYER_NAME,
        dataLayer
    });
};
export default sendUserProperties;

import snakecaseKeys from 'snakecase-keys';
import * as api from '~/services/api';
function resToAllocatedPurchaseItem(res) {
    return {
        id: Number(res.id)
    };
}
export async function postAllocateStockForPurchase(productId, quantityId) {
    const json = await api.post(`v1/furusato/products/${productId}/product_quantities/${quantityId}/purchases`);
    const res = await api.deserializeJsonApi(json);
    return resToAllocatedPurchaseItem(res);
}
function resToPostPurchase(res) {
    return {
        id: Number(res.id),
        type: res.type,
        cancellationDeadlineDate: res.cancellationDeadlineDate,
        firstPurchase: res.firstPurchase,
        nextDeliveryDateRangeFrom: res.nextDeliveryDateRangeFrom,
        nextDeliveryDateRangeTo: res.nextDeliveryDateRangeTo,
        purchaseDate: res.purchaseDate,
        purchaseContract: {
            consumptionTax: res.purchaseContract.consumptionTax
        }
    };
}
export async function postPurchase(purchaseParams, productParams) {
    //Falsy値は不要な項目として取り除いて送信
    const filteredParams = Object.entries(purchaseParams).map(([key, value])=>({
            key,
            value
        })).filter((item)=>item.value === 0 || !!item.value).reduce((obj, data)=>({
            ...obj,
            [data.key]: data.value
        }), {});
    const json = await api.post(`v1/furusato/purchase/contracts`, {
        contract: snakecaseKeys(filteredParams),
        product: snakecaseKeys(productParams)
    });
    const res = await api.deserializeJsonApi(json);
    return resToPostPurchase(res);
}

import styled from 'styled-components';
import { color, pxToRem, size } from '~/styles/constants';
export const Inner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
`;
export const EyeCatch = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: ${size.borderRadius.CIRCLE};
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
export const Name = styled.div`
  flex: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${pxToRem(14)};
  font-weight: ${({ $selected })=>$selected ? size.fontWeight.W6 : size.fontWeight.W4};
  color: ${({ $selected })=>$selected ? color.DARK_BLUE : color.font.MAIN};
  line-height: 1.5;
`;

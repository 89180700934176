export const SET_DELIVERY_DESTINATION = 'delivery/FURUSATO_DELIVERY_DESTINATION';
export const setDeliveryDestination = (deliveryDestination)=>({
        type: SET_DELIVERY_DESTINATION,
        payload: {
            deliveryDestination
        }
    });
export const SET_DELIVERY_DATE = 'delivery/FURUSATO_SET_DELIVERY_DATE';
export const setDeliveryDate = (deliveryDate)=>({
        type: SET_DELIVERY_DATE,
        payload: {
            deliveryDate
        }
    });
export const SET_DELIVERY_TIME = 'delivery/FURUSATO_SET_DELIVERY_TIME';
export const setDeliveryTime = (deliveryTime)=>({
        type: SET_DELIVERY_TIME,
        payload: {
            deliveryTime
        }
    });
export const RESET_DELIVERY = 'delivery/FURUSATO_RESET_DELIVERY';
export const resetDelivery = ()=>({
        type: RESET_DELIVERY
    });
export default {
    setDeliveryDestination,
    setDeliveryDate,
    setDeliveryTime,
    resetDelivery
};

export const SET_IS_INITIALIZED = 'user/SET_IS_INITIALIZED';
export const setIsInitialized = (isInitialized)=>({
        type: SET_IS_INITIALIZED,
        payload: {
            isInitialized
        }
    });
export const SET_ME = 'user/SET_ME';
export const setMe = (me)=>({
        type: SET_ME,
        payload: {
            me
        }
    });
export const ADD_LIKE_PRODUCT = 'user/ADD_LIKE_PRODUCT';
export const addLikeProduct = (id)=>({
        type: ADD_LIKE_PRODUCT,
        payload: {
            id
        }
    });
export const REMOVE_LIKE_PRODUCT = 'user/REMOVE_LIKE_PRODUCT';
export const removeLikeProduct = (id)=>({
        type: REMOVE_LIKE_PRODUCT,
        payload: {
            id
        }
    });
export const SET_LIKE_PRODUCTS = 'user/SET_LIKE_PRODUCTS';
export const setLikeProducts = (ids)=>({
        type: SET_LIKE_PRODUCTS,
        payload: {
            ids
        }
    });
export const RESET_USER = 'user/RESET_USER';
export const resetUser = ()=>({
        type: RESET_USER
    });
export const SET_OAUTH = 'user/SET_OAUTH';
export const setOAuthUser = (oAuth)=>({
        type: SET_OAUTH,
        payload: {
            oAuth
        }
    });
export default {
    setIsInitialized,
    setMe,
    addLikeProduct,
    removeLikeProduct,
    setLikeProducts,
    resetUser,
    setOAuthUser
};
